import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import PageNav from '../Components/Common/PageNav'
import PopWalletInfo from '../Components/Wallet/PopWalletInfo'
import $ from 'jquery';
class CommissionStatement extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        page:1,
        clientId:this.props.location.state?this.props.location.state.userId:0,
        clientInfo:this.props.location.state?this.props.location.state.userInfo:false,
        pagination:false,
        transInfo:false
      }

      componentDidMount()
      {
        this.listTransactions();
      }


      static getDerivedStateFromProps(nextProps, prevState) {
        
        return {
          clientId: nextProps.location.state?nextProps.location.state.userId:0,
          clientInfo:nextProps.location.state?nextProps.location.state.userInfo:false,
        };
       }

       componentDidUpdate(nextProps, prevState) {
       
        if(nextProps.location.state!==undefined)
        {
         
          if(nextProps.location.state.userId!==this.state.clientId)
          {
            this.setState({
              page:1,
              transactionList:false,
              metaData:false
            }, ()=>{
              this.listTransactions();

            })

          }
        }
           
      }


      DocHeader=()=>{
        if(this.state.clientInfo)
        {
            
              return(
                <div>
                <h6 className="card-title mb-1">
                  Commission - {this.state.clientInfo.first_name + ' ' + this.state.clientInfo.last_name}
                </h6>
                <p className="text-muted card-sub-title">User commission flow</p>
              </div>
              )
          
        }else{
          return(
            <div>
            <h6 className="card-title mb-1">
            Commission
            </h6>
            <p className="text-muted card-sub-title">All users commission flow</p>
          </div>
          )
        }
      }



      listTransactions=async()=>{
        const server_response=await ajax.getGeneralCommissionStatement(this.state.clientId, this.state.page);
        if(server_response.status==="OK")
          {
            this.setState({
                  transactionList:server_response.details.list,
                  metaData:server_response.details.meta
            }, ()=>this.paginate())

          }else{
            this.setState({
              transactionList:"404",
              metaData:false
            })
          }
          
        }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


     
//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  onClickTransId=(info)=>{
    this.setState({
      transInfo:false
    }, ()=>{
      this.setState({
        transInfo:<PopWalletInfo transactionID={info.trans_id} origin="COMMISSION"/>
      }, ()=>{
        $("#mdl-wallet-info").modal('show');
      })
    })
  }
    
    
    render(){
        const list=this.state.transactionList;
          return (
            <div className="page">
              {this.state.transInfo}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Commission"
                     previousPageTitle="Statements"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                          <div className="card custom-card">
                            <div className="card-body">
                              <this.DocHeader/>
                              <div className="table-responsive">
                                <table className="table table-bordered text-nowrap mb-0 table-ellipsis">
                                <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>TransID</th>
                                      <th>Client/User</th>
                                      <th>Description</th>
                                      <th>Dr</th>
                                      <th>Cr</th>
                                      <th>Balance</th>
                                    </tr>
                                  </thead>
                                  {list && list!=="404" &&  <tbody>
                                    

                                          {list.map((item, key)=>
                                            <tr key={key} className={`${item.trans_ref_id*1===0 && 'warn-row'}`}>
                                            <td>{item.trans_date.long_date}</td>
                                            <td>
                                              <a className="link" onClick={()=>this.onClickTransId(item)}>
                                                {item.trans_id}
                                              </a>
                                            </td>
                                            <td>
                                                  {item.user_info.first_name + ' ' + item.user_info.last_name + ' - ' + item.user_info.phone}
                                            </td>
                                            <td>{item.description}</td>
                                              <td>{item.cash_out.amount*1>0 && item.cash_out.amount_c}</td>
                                              <td>{item.cash_in.amount*1>0 && item.cash_in.amount_c}</td>
                                              <td><b>{item.balance.amount_c}</b></td>
                                    
                                        </tr>
                                            )}	


                                              
                                    
                                  </tbody>}
                                </table>
                                      {this.state.pagination}
                                      {!this.state.transactionList && <ActivityLoader/>}

                              </div>
                            </div>
                          </div>
                      
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default CommissionStatement;
  
import React from 'react';
import ajax from '../../ajax';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ajaxUser from '../../remote/ajaxUser';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import InitialAvatar from '../Common/InitialAvatar';
import TextInput from '../Common/TextInput';

class PopUserLoginAttempt extends React.Component {

    state={
        userId:functions.sessionGuard(),
        info:'',
        loading:false,
        transactionList:false
    }

    componentDidMount() {
        this.getTransactions();
    }
    

   
    getTransactions =async()=>{
        const server_response = await ajaxUser.getLoginAttempts(this.props.user.username);
        if(server_response.status=="OK")
        {
            this.setState({
                transactionList:server_response.details
            })
        }else{
            this.setState({
                transactionList:"404",
                info:<AlertError message={server_response.message} type="light"/>
            })
        }
        
    }
  
   
   
  render(){
     const user=this.props.user;
     const transactionList = this.state.transactionList;
      return (
        <div className="modal" id="modal_login_security">
        <div className="modal-dialog modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">User Login Security</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">

                       
										<div className="row align-items-center justify-content-center">
											<div className="col-auto user-lock">
                                            {user.photo_name==="avatar.png" &&
												<InitialAvatar value={user.initials}/>}
												{user.photo_name!=="avatar.png" &&
												<img 
                                                    className="img-fluid rounded-circle"
													src={user.photo}
													className="pointer"
												/>}
											</div>
											<div className="col">
												<h5>{user.last_name + ' ' + user.first_name}</h5>
												<span>{user.role_name}</span>
											</div>
										</div>

                                        {!transactionList && <ActivityLoader/>}
                                        {this.state.info}
										{transactionList && transactionList!="404" && <ul className="task-list">
											{transactionList.map((item, key)=>
                                            <li key={key}>
                                            <i className="task-icon bg-danger"></i>
                                            <h6>{item.created_at.short_date}<span className="float-right text-muted fs-11">{item.created_at.time}</span></h6>
                                            <p className="text-muted fs-12">Attempt log: {item.security}</p>
                                            </li>
                                            )}
											
										</ul>}
									</div>
							
           {!this.state.loading && <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button" onClick={this.props.onConfirm}>Reset Login</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopUserLoginAttempt;
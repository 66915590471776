import React, { Component } from 'react';
import ajaxCashRound from '../../remote/ajaxCashRound';
import DashTile from '../Common/DashTile';

class CountCashround extends Component {

    state={
        total:"..."
    }

    componentDidMount() {
        this.getRecord();
    }
    

    getRecord=async()=>{
        const server_response = await ajaxCashRound.countCashrounds();
        if(server_response.status=="OK")
        {
            this.setState({
                total:server_response.details.total_c
            })
        }
    }

    render() {
        return (
            <DashTile title="Total Cash Rounds" icon="fe fe-arrow-down" value={this.state.total} percentage="5%" color="mb-0 text-danger fs-13 ml-1" caption="This month" />
        );
    }
}

export default CountCashround;
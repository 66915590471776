import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import OngoingFloatRequests from '../../Components/Float/OngoingFloatRequests'
import ReserveRequestCard from '../../Components/Cards/Reserve/ReserveRequestCard'
import ReserveBalanceCard from '../../Components/Cards/Reserve/ReserveBalanceCard'
import ReserveDailyDepositsCard from '../../Components/Cards/Reserve/ReserveDailyDepositsCard'
import ReserveDailyExpensesCard from '../../Components/Cards/Reserve/ReserveDailyExpensesCard'
import RecentReserveTransactions from '../../Components/Reserve/RecentReserveTransactions'
import OngoingReserveRequests from '../../Components/Reserve/OngoingReserveRequests'

class DashboardReserve extends React.Component {

    state={
      userId:functions.sessionGuard(),
    }

 
    buttons=()=>{
      return(
          <>
              <button className="btn ripple btn-success">
                  <i className="fas fa-hands-helping"></i> Business Loans
              </button>
              <button className="btn ripple btn-primary">
                  <i className="fas fa-hands-helping"></i> Personal Loans
              </button>
              
            </>
          )
    }

    customHeaderOptions=()=>{
      return(
          <>
           <a className="dropdown-item">
                  <i className="fas fa-unlock-alt mr-2"></i>Security Deposits
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Principal
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Fines
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Paid Extension
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Interest Earnings
              </a>
             
            </>
          )
    }

   

    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Company Reserve"
                    previousPageTitle="Finance"
                    back="/finance"
                    // options={{
                    //   "customOptions":<this.customHeaderOptions/>
                    // }}
                    // buttons={<this.buttons/>}
                  />
                  
                    <div className="row">
                      
                     <ReserveRequestCard/>
                     <ReserveBalanceCard/>
                     <ReserveDailyDepositsCard/>
                     <ReserveDailyExpensesCard/>

                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                          <RecentReserveTransactions/>
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                         <OngoingReserveRequests/>

                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardReserve
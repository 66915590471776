import React, { Component } from 'react'
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../../Components/Common/ActivityLoader';

class ChartArea extends Component {
    state = {
        user_id: functions.sessionGuard(),
        msg: 'media',
        reply: 'media flex-row-reverse',
        message: '',
        loading: false,
        ticket_info: false,
        info: '',
        ticket_id: false
    }

    async componentDidMount() {
        await this.viewTicketInfo();
        this.timerActual = setTimeout(
            (this.func1 = async () => {
                await this.viewTicketInfo();
                this.timerActual = setTimeout(this.func1, 2000); // (*)
            }),
            2000
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timerActual);
    }


    onchangeMsg = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    sendMessage = async () => {
        const { user_id, message, ticket_info } = this.state;

        const server_response = await ajax.addLog(user_id, ticket_info.ticket_id, message);

        this.setState({ message: '' })

    }

    viewTicketInfo = async () => {
        this.setState({ loading: true })
        const server_response = await ajax.viewTicketInfo(this.props.ticket_id);
        this.setState({ loading: false })

        if (server_response.status == 'OK') {
            this.setState({
                ticket_info: server_response.details,
            })
            return;
        }

        this.setState({
            ticket_info: '404',
            info: server_response.message
        })
    }

    updateTicketStatus = async (id) => {
        const server_response = await ajax.updateTicketStatus(id, '1');

        if (server_response.status == 'OK') {
            this.setState({ ticket_info: false }, () => this.viewTicketInfo(this.props.ticket_id))
        }
    }


    render() {
        const { msg, reply, ticket_info } = this.state;
        console.log(ticket_info)
        return (

            <div className="row">
                <div className="col-sm-12 col-md-4 col-xl-4">
                    <div className="card custom-card">
                        <div className="main-content-app pt-0">
                            <div className="main-content-left main-content-left-chat">
                                <div className="card-body">
                                    <h3>Ticket Information</h3>

                                    <div className="main-chat-list" style={{ overflow: 'scroll' }} id="ChatList">
                                        {ticket_info.ticket_id &&
                                            <>
                                                <table className="table border-bottom">
                                                    <tr>
                                                        <td>Title</td>
                                                        <td>{ticket_info.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>{ticket_info.status == '1' ? "Closed" : "Open"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Created By</td>
                                                        <td>{ticket_info.first_name + " " + ticket_info.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contact</td>
                                                        <td>{ticket_info.contact}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date</td>
                                                        <td>{ticket_info.date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Department</td>
                                                        <td>{ticket_info.department}</td>
                                                    </tr>
                                                </table>
                                                {ticket_info.status == '0' && <button className="btn btn-danger mt-4" onClick={() => {
                                                    if (window.confirm("Are you sure yo want to close this ticket ?")) {
                                                        this.updateTicketStatus(ticket_info.ticket_id)
                                                    }
                                                }} style={{ width: '100%' }}>Close Ticket</button>}



                                            </>
                                        }
                                        {!ticket_info.ticket_id && <ActivityLoader />}
                                    </div>{/* main-chat-list */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-8 col-xl-8">
                    <div className="card custom-card">
                        <div className="main-content-app pt-0">
                            <div className="main-content-body main-content-body-chat">
                                <div className="main-chat-header pt-3">
                                    <div className="main-img-user online"><img alt="avatar" src="https://api.quickpost.araknerd.com/img/avatar.png" /></div>
                                    <div className="main-chat-msg-name">
                                        <h6>{ticket_info && ticket_info.ticket_id && ticket_info.first_name + ' ' + ticket_info.last_name}</h6>
                                        <small className="mr-3">{ticket_info && ticket_info.ticket_id && ticket_info.contact}</small>
                                    </div>

                                </div>{/* main-chat-header */}
                                <div className="main-chat-body" style={{ overflow: 'scroll' }} id="ChatBody">
                                    <div className="content-inner">
                                        {!ticket_info.ticket_id && <ActivityLoader />}

                                        {ticket_info && ticket_info.ticket_id && ticket_info != '404' && ticket_info.logs.map((item, key) => <div key={key} className={item.last_name == ticket_info.last_name ? this.state.msg : this.state.reply}>
                                            <div className="main-img-user online"><img alt="avatar" src="https://api.quickpost.araknerd.com/img/avatar.png" /></div>
                                            <div className="media-body">
                                                <div>
                                                    <span>{item.first_name + " " + item.last_name}</span>
                                                </div>
                                                <div className="main-msg-wrapper">
                                                    {item.log}
                                                </div>
                                                <div>
                                                    <span>{item.date}</span>
                                                </div>
                                            </div>
                                        </div>)}

                                    </div>
                                </div>

                                <div className="main-chat-footer">

                                    {ticket_info.status == '0' ? <> <input className="form-control" placeholder="Type your message here..." value={this.state.message} type="text" onChange={this.onchangeMsg} />
                                        <a className="main-msg-send" onClick={this.sendMessage} href="#"><i className="far fa-paper-plane" /></a></> : <p className="text-danger text-center">{"Ticket closed"}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

        )
    }
}

export default ChartArea;
import React from 'react';
import ajax from '../ajax';
import AlertSuccess from './Common/AlertSuccess';
import AlertError from './Common/AlertError';
import ActivityLoader from './Common/ActivityLoader';



class DeleteChargesWithdraw extends React.Component {
  
    state={
        chargeId:this.props.record.charge_id
    }
    onSave=async()=>{
        this.setState({
            loading:true
        })
        
            const server_response=await ajax.deleteWithdrawCharges(this.state.chargeId);
            if(server_response.status==="OK")
            {
               this.setState({
                 info:<AlertSuccess message={server_response.details[0]}/>
               })
               this.props.onFinish()
            }else{
              this.setState({
                info:<AlertError message={server_response.details[0]}/>
              })
            }
    
        
      }

  render(){
    
      return (
        <div className="modal" id={this.props.mdl}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button> 
                    <i className="icon icon ion-ios-close-circle-outline tx-100 tx-danger lh-1 mg-t-20 d-inline-block"></i>
                    <h4 className="tx-danger mg-b-20">Delete Charges</h4>
                    <p className="mg-b-20 mg-x-20">{"You are about to delete a withdraw charges record #"+this.state.chargeId}</p>
                    
                        {!this.state.loading && <input className="btn ripple btn-danger pd-x-25" type="button" value="Continue" onClick={this.onSave}/>}
                        {this.state.info}
                        {this.state.loading && <ActivityLoader/>}
                
                </div>
            </div>
        </div>
    </div>
      )
    
 
  }
}

export default DeleteChargesWithdraw;
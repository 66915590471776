import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import WarnDelete from '../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../Components/Common/Toast'
import PopNewPayment from '../Components/Loan/PopNewPayment'

class ManageLoan extends React.Component {

    state={
        userId:functions.sessionGuard(),
        loanId:false,
        loanInfo:false,
        fineId:false,
        extensionId:false,
        mdlDelete:false,
        toast:false,
        mdlNewPayment:false,
        accessInfo:functions.readPermissions()
      }

      componentDidMount()
      {
        if(!this.props.location.state)
		{
			this.props.history.push('/all-loans');
		}else{
			this.setState({
				loanId:this.props.location.state.loan_id
			}, ()=>{
				this.loanInfo();
            })
        }
      }


      loanInfo=async()=>{
          const server_response=await ajax.loanInfo(this.state.loanId);
         // console.log(server_response.details)
          if(server_response.status==="OK")
          {
             this.setState({
                loanInfo:server_response.details
             })

          }else{
            this.setState({
                loanInfo:"404"
            })
          }
      }


    buttons=()=>{
        return(
            <>
            {functions.findInObject(this.state.accessInfo, "NEW-LOAN-1") &&
                <button className="btn ripple btn-success" onClick={this.onClickPayLoan}>
                    <i className="fas fa-credit-card"></i> New Payment
                </button>}
                
              </>
            )
      }

      customHeaderOptions=()=>{
        return(
            <>
                {functions.findInObject(this.state.accessInfo, "NEW-LOAN-1") &&
                <a className="dropdown-item" onClick={this.onClickPayLoan}>
                    <i className="fas fa-credit-card mr-2"></i>New Payment
                </a>}

                <a className="dropdown-item">
                    <i className="fe fe-plus-circle mr-2"></i>Extend Loan
                </a>
                <a className="dropdown-item">
                    <i className="fe fe-plus-circle mr-2"></i>Fine Loan
                </a>
                <a className="dropdown-item">
                    <i className="fe fe-trash-2 mr-2"></i>Delete Loan
                </a>
                
              </>
            )
      }


      onClickDeleteFine=(id)=>{
        this.setState({
            mdlDelete:false,
            fineId:id
        }, ()=>{
            

            this.setState({
                mdlDelete: <WarnDelete
                            title="Remove Fine?"
                            description="Are you sure you want to remove this fine record from this loan?"
                            onConfirm={()=>this.onConfirmDeleteFine()}
                            buttonLabel="Remove Fine"/>
            }, ()=>{
                $("#modal_delete").modal('show')
            })

        })
      }

      refreshToast=()=>{
          this.setState({
              toast:false
          })
      }

      onConfirmDeleteFine=async()=>{

        if(this.state.fineId)
        {
            $("#modal_delete").modal('hide');

            const server_response = await ajax.deleteLoanFine(this.state.fineId);
            if(server_response.status==="OK")
            {
                this.setState({
                    toast:<Toast message={server_response.details} onClose={this.refreshToast}/>
                }, ()=>{

                    //hide record
                    functions.hide('fine_'+this.state.fineId);
                    //Refresh list
                    this.loanInfo();
                })
            }else{
                this.setState({
                    toast:<Toast type={2} message={server_response.details} onClose={this.refreshToast}/>
                })
            }
            
        }

      }



      onClickPayLoan=()=>{
        this.setState({
            mdlNewPayment:false,
        }, ()=>{
            
            this.setState({
                mdlNewPayment: <PopNewPayment onConfirm={this.onConfirmPayment}/>
            }, ()=>{
                $("#modal_new_payment").modal('show')
            })

        })
      }


      onConfirmPayment=async(amount)=>
      {
        const server_response=await ajax.settleLoan(this.state.loanInfo.customer_info.user_id, amount);
        console.log(server_response.details)
        $("#modal_new_payment").modal('hide')
        if(server_response.status==="OK")
        {
            this.setState({
                toast:<Toast message={server_response.details} onClose={this.refreshToast}/>
            }, ()=>{

                this.loanInfo();
            })
        }else{
            this.setState({
                toast:<Toast type={2} message={server_response.details} onClose={this.refreshToast}/>
            })
        }
      }
    
    render(){
        const info=this.state.loanInfo;
        const currency="UGX. ";
          return (
			<div className="page">
             {this.state.mdlDelete}
             {this.state.mdlNewPayment}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Manage Loan"
                     previousPageTitle="Loans"
                     options={{
                        "customOptions":<this.customHeaderOptions/>
                    }}
                    buttons={<this.buttons/>}
                  />
                  
                  {this.state.toast}



                  {info &&  <div className="row sidemenu-height">
						<div className="col-lg-7">

                        <div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-0">Loan Fine Records</h6>
								</div>
								<div className="card-body">
									<div className="table-responsive text-nowrap">
										<table className="table table-bordered">
											<thead>
												<tr>
                                                    <th>No.</th>
													<th>Date</th>
													<th>Fine Amount</th>
													{functions.findInObject(this.state.accessInfo, "NEW-LOAN-FINE-4") &&
                                                    <th>Action</th>}
												</tr>
											</thead>
											<tbody>

                                            {info.fine_records.map((item, key)=>
                                            <tr key={key} id={"fine_"+item.record_id}>
                                            <td>
                                                <span className="font-weight-bold">{key+1}</span>
                                            </td>
                                            <td>
                                                <span>{item.date_added.long_date}</span>
                                            </td>
                                            
                                            <td>
                                                <span className="font-weight-bold">{item.amount.amount_c}</span>
                                            </td>
                                            
                                            {functions.findInObject(this.state.accessInfo, "NEW-LOAN-FINE-4") &&
                                            <td>
                                                <a onClick={()=>this.onClickDeleteFine(item.record_id)}
                                                    className="btn ripple btn-danger btn-sm text-white" 
                                                    data-toggle="tooltip" data-original-title="Remove">
                                                    <i className="fe fe-trash-2"></i>
                                                </a>
                                            </td>}
                                        </tr>
                                            
                                            )}	
												
											</tbody>
										</table>
									</div>
								
								</div>
							</div>
						


							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-0">Loan Extension Records</h6>
								</div>
								<div className="card-body">
									<div className="table-responsive text-nowrap">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Date</th>
													<th>Amount</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												
                                            {info.extension_records.map((item, key)=>
                                            <tr key={key}>
													<td>
														<span>{item.date_added.long_date}</span>
													</td>
													<td>
														<span className="font-weight-bold">{item.amount.amount_c}</span>
													</td>
													<td>
														<a className="btn ripple btn-danger btn-sm text-white" data-toggle="tooltip" data-original-title="Delete"><i className="fe fe-trash-2"></i></a>
													</td>
												</tr>)}
												
											</tbody>
										</table>
									</div>
								
								</div>
							</div>
						
                          

                        
                        </div>





						<div className="col-lg-5">
                            

                            <div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-1">About</h6>
								</div>
								

                                <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Loan ID</label> 
                                                <span className="tx-medium">{info.loan_code}</span>
                                            </div>
                                            <div>
                                                <label>Package</label> 
                                                <span className="tx-medium">
                                                {info.package_info.package_name + " (" + info.package_info.duration + ' ' + info.package_info.units + ")"}
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Loan Date</label> 
                                                <span className="tx-medium">{info.loan_date.long_date}</span>
                                            </div>
                                            <div>
                                                <label>Deadline</label> 
                                                <span className="tx-medium">{ info.deadline.long_date }</span>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <div className="media mb-0">
                                            <div className="media-body">
                                            <div>
                                                <label>Customer Name</label> 
                                                <span className="tx-medium">
                                                    {info.customer_info.first_name + ' ' + info.customer_info.last_name}
                                                </span>
                                            </div>
                                            <div>
                                                <label>Customer Contact</label> 
                                                <span className="tx-medium">
                                                {info.customer_info.phone}
                                                </span>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="media mb-0">
                                            <div className="media-body">
                                            <div>
                                                <label>Agent Name</label> 
                                                <span className="tx-medium">
                                                {info.agent_info.first_name + ' ' + info.agent_info.last_name}
                                                </span>
                                            </div>
                                            <div>
                                                <label>Agent Contact</label> 
                                                <span className="tx-medium">
                                                {info.agent_info.phone}
                                                </span>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="media mb-0">
                                            <div className="media-body">
                                            <div>
                                                <label>Extension Fee</label> 
                                                <span className="tx-medium">
                                                UGX.{info.extension_amount.amount_c}
                                                </span>
                                            </div>


                                          
                                            </div>
                                        </div>





                                       
               
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
						
                        

                            

							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<h6 className="card-title mb-1">Loan Summary</h6>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-bordered">
											<tbody>
												<tr>
													<td>Loan Principal</td>
													<td>{currency + info.loan_principal.amount_c}</td>
												</tr>
												<tr>
													<td><span>Loan Interest</span></td>
													<td>
                                                        {currency + info.loan_interest.amount_c}
                                                    </td>
												</tr>

                                                <tr>
													<td><span>Total Fine</span></td>
													<td>
                                                        {currency + info.loan_fine.amount_c}
                                                    </td>
												</tr>

                                                <tr>
													<td><span>Total Loan</span></td>
													<td>
                                                        {currency + info.total_loan.amount_c}
                                                    </td>
												</tr>

                                                <tr>
													<td><span>Total Paid</span></td>
													<td className="text-right">
                                                        {currency + info.total_paid.amount_c}
                                                    </td>
												</tr>

                                                <tr>
													<td><span>Loan Balance</span></td>
													<td className="text-right">{currency + info.loan_balance.amount_c}</td>
												</tr>
                                                
											</tbody>
										</table>
									</div>
									
								</div>
							</div>
						
                        
                        </div>
					</div>}
                    

                    {!info && <ActivityLoader/>}

                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default ManageLoan;
  
import React, { Component } from "react";
import { isFunctionExpression } from "typescript";
import ajax from "../../ajax";
import functions from "../../functions";
import ActivityLoader from "../Common/ActivityLoader";
import AlertSuccess from '../Common/AlertSuccess';
import AlertError from '../Common/AlertError';
import TextInput from "../Common/TextInput";

class CreateSupportTicket extends Component {
    state = {
        user_id: functions.sessionGuard(),
        departments: false,
        loading: false,
        info: '',
        department: '',
        title: '',
        log: ''
    }

    componentDidMount() {
        this.listDepartments();
    }

    listDepartments = async () => {
        this.setState({
            loading: true
        })
        const server_response = await ajax.listDepartments();
        this.setState({
            loading: false
        })

        if (server_response.status == 'OK') {
            this.setState({
                departments: server_response.details
            })
        } else {
            this.setState({
                departments: '404',
                message: server_response.message
            })
        }
    }

    onChangeDepartment = (e) => {
        this.setState({ department: e.target.value })
    }

    onChangeTitle = (e) => {
        this.setState({ title: e.target.value })
    }

    onChangeLog = (e) => {
        this.setState({ log: e.target.value })
    }

    createTicket = async () => {
        const { department, title, log } = this.state;
        if (!department || !title) {
            this.setState({ info: <AlertError message="please fill in all the fields" /> })
            return;
        }
        this.setState({ loading: true });
        const server_response = await ajax.createTicket(this.state.user_id, department, title, log);
        // console.log(server_response)
        this.setState({ loading: false, department: '', title: '', log: '' });

        if (server_response.status == 'OK') {
            this.setState({ info: <AlertSuccess message={server_response.message} /> })
            return;
        }

        this.setState({ info: <AlertError message={server_response.message} /> })
    }

    render() {
        const { departments } = this.state;
        return (
            <div className="modal" id="modaldemo3">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">Create Support Tickets</h6>
                            <button
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {this.state.loading && <ActivityLoader />}
                        {this.state.info && this.state.info}

                        <div className="container mt-3">

                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <TextInput
                                            label="Enter Title"
                                            type="text"
                                            onChange={() => this.onChangeTitle}
                                            value={this.state.title}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <div class="row row-xs align-items-center mg-b-20">
                                            <div class="col-md-3 text-left">
                                                <label class="mg-b-0">Select Department</label>
                                            </div>
                                            <div class="col-md-9 mg-t-5 mg-md-t-0">
                                                <select
                                                    className="form-control"
                                                    value={this.state.department}
                                                    onChange={this.onChangeDepartment}
                                                >
                                                    <option unselectable>----Select Department----</option>
                                                    {
                                                        departments && departments !== '404' &&
                                                        departments.map((item, key) =>
                                                            <option key={key} value={item.department_id}>{item.department}</option>
                                                        )
                                                    }
                                                </select>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <TextInput
                                            label="Ticket Description"
                                            type="textarea"
                                            onChange={() => this.onChangeLog}
                                            value={this.state.log}
                                        />
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn ripple btn-success"
                                type="button"
                                onClick={this.createTicket}
                            >
                                Create Ticket
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateSupportTicket;

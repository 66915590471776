import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';

class LoanFine extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        paid:'...',
        total:'...'
       
    }


    async componentDidMount()
    {
      await this.getPaidFine();
      await this.getCummBalance();
      this.timerPaid = setTimeout(this.func1=async()=> {
        await this.getPaidFine();
        this.timerPaid = setTimeout(this.func1, 2000); // (*)
      }, 2000);

      this.timerCumm = setTimeout(this.func2=async()=> {
        await this.getCummBalance();
        this.timerCumm = setTimeout(this.func2, 2000); // (*)
      }, 2000);
    }
  
    componentWillUnmount()
    {
      clearTimeout(this.timerPaid);
      clearTimeout(this.timerCumm);
    }
  
    getPaidFine=async()=>{
      const server_response=await ajax.totalPaidFine(this.state.userId);
      if(server_response.status==="OK")
      {
        this.setState({
          paid:server_response.details.amount_c
        })
      }
    }


    getCummBalance=async()=>{
        const server_response=await ajax.countLoanFine(this.state.userId);
        if(server_response.status==="OK")
        {
          this.setState({
            total:server_response.details.amount_c
          })
        }
      }
  

   

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }


   
  render(){
    
      return (
       
    <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6 className="card-title mb-1">Loan Fine</h6>
                    <p className="text-muted card-sub-title">Cash collected on defaulted loans</p>
                </div>
                <div className="row">
                    <div className="col-6 col-md-6 text-center">
                        
                        <p className="mb-1 tx-inverse">Cumm. Fine</p>
                        <h4 className="mb-1">{this.state.total}<span></span></h4>
                        
                    </div>
                    <div className="col-6 col-md-6 text-center">
                    
                        <p className="mb-1 tx-inverse">Paid Fine</p>
                        <h4 className="mb-1">{this.state.paid}<span></span></h4>
                    
                    </div>

                </div>

    
            </div>
        </div>

      )
    
 
  }
}

export default LoanFine;
import React, { Component } from 'react';
import ajaxCashRound from '../../remote/ajaxCashRound';
import ActivityLoader from '../Common/ActivityLoader';
import TableHeader from '../Common/TableHeader';

class RecentCashRoundTransactions extends Component {

    state={
        transactions:false,
        message:'',
        groupId:this.props.groupId || null
    }

    componentDidMount() {
        this.getTransactions();
    }
    

    getTransactions=async()=>{
        const server_response = await ajaxCashRound.getRecentGroupTransactions(this.state.groupId);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details,
                message:server_response.message
            })
        }else{
            this.setState({
                transactions:"404",
                message:server_response.message
            })
        }
    }

    render() {
        const list = this.state.transactions;
        return (
            <div className="col-sm-12 col-md-12 col-xl-8 col-lg-8">
            <div className="card custom-card">

                <div className="card-body">

                    <TableHeader
                        title="Recent transactions"
                        subtitle="Recent cash round transactions"
                       // view="View All"
                    />


                    <div className="table-responsive scroll-widget overflow-auto">
                        <table className="table text-nowrap mg-b-0 table-hover">
                            {/* main-table-reference */}
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Client Name</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Trans</th>
                                </tr>
                            </thead>
                            <tbody>

                                {list && list !== "404" &&

                                    list.map((item, key) =>
                                        <tr key={key}>
                                            <td>
                                                <div className="main-img-user">
                                                    <img alt="avatar" src={item.user_info.photo} />
                                                </div>
                                            </td>
                                            <td>
                                                <h6 className="mg-b-1">{item.user_info.last_name} {item.user_info.first_name}</h6>
                                                <small className="tx-12 tx-gray-500">@ {item.user_info.username}</small>
                                            </td>
                                            <td className="tx-align-center">
                                                {item.description}
                                            </td>
                                            <td>{item.cash_in.amount>0?item.cash_in.amount_c : item.cash_out.amount_c}</td>
                                            <td>
                                                {item.trans_date.short_date}<br />
                                                <small className="tx-12 tx-gray-500">{item.trans_date.time}</small>
                                            </td>
                                            <td>{item.cash_in.amount > 0  &&
                                                <div className="d-flex align-items-center">
                                                    <p className="fas fa-arrow-down m-0 text-success mr-1"></p>
                                                    <span className="text-muted tx-13 m-0">Con</span>
                                                </div>
                                            }
                                                {item.cash_out.amount > 0 &&
                                                    <div className="d-flex align-items-center">
                                                        <p className="fas fa-arrow-up m-0 text-danger mr-1"></p><span className="text-muted tx-13 m-0">Pay</span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )}

                            </tbody>
                        </table>
                        {!list && <ActivityLoader />}
                    </div>
                </div>
            </div>
        </div>



        );
    }
}

export default RecentCashRoundTransactions;
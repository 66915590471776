import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import InitialAvatar from '../Components/Common/InitialAvatar'
import Toast from '../Components/Common/Toast'
import PopChangeLimit from '../Components/Loan/PopChangeLimit'
import WarnDelete from '../Components/WarnDelete'
import $ from 'jquery';
import AgentProfileData from '../Components/User/AgentProfileData'
import AgentSummary from '../Components/User/AgentSummary'
import AgentWallet from '../Components/Wallet/AgentWallet'
import AgentLoans from '../Components/Loan/AgentLoans'
import AgentFloat from '../Components/Wallet/AgentFloat'
import UserControls from '../Components/User/UserControls'
import PopUserLocation from '../Components/User/PopUserLocation'
import PopImageViewer from '../Components/Common/PopImageViewer'
import PopVerifyCustomer from '../Components/User/PopVerifyCustomer'
import PopUserLoginAttempt from '../Components/Access/PopUserLoginAttempt'
import PopVerifyAction from '../Components/Common/PopVerifyAction'
import reserved from '../reserved'
import ClassifiedProfile from '../Components/Access/ClassifiedProfile'

class AgentProfile extends React.Component {

    state={
        userId:functions.sessionGuard(),
		agentInfo:false,
		agentId:false,
		walletStatement:false,
		walletBalance:'...',
		floatBalance:'...',
		loanHistory:false,
		filter:"ALL",
		metaData:false,
		page:1,
		menuOptions:'',
		currentOption:'PROFILE',
		toast:false,
		warnAction:false,
		changeLimit:false,
		accessInfo:functions.readPermissions(),
		locateUser:false,
		loanStatistics:false,
		loading:false,
		verification:false,
		imageViewer:false,
		securityFee:'...',
		commission:'...',
		mdlLoginSecurity:false

	  }


      componentDidMount()
      {
		if(!this.props.location.state)
		{
			this.props.history.push('/agent-records');
		}else{
			this.setState({
				agentId:this.props.location.state.agentId
			}, ()=>{
				this.getAgentProfile();
				this.getWalletStatement();
				this.listLoans();
				this.getSecurityBalance();

				this.timerWalletBalance = setTimeout(this.func1=async()=> {
					await this.getWalletBalance();
					this.timerWalletBalance = setTimeout(this.func1, 2000); // (*)
				  }, 2000);

				  
				this.timerFloatBalance = setTimeout(this.func2=async()=> {
					await this.getFloatBalance();
					this.timerFloatBalance = setTimeout(this.func2, 2000); // (*)
				  }, 2000);


			})

		}

		this.setState({
			menuOptions: {
				"profile": () => this.onClickProfile(),
				"history": () => this.onClickLoanHistory(),
				"bFloat": () => this.onClickWallet(),
				"cFloat": () => this.onClickFloat(),
				"pinReset": () => this.onClickResetPIN(),
				"changeLimit": () => this.onClickChangeLimit(),
				"userLocation": () => this.onClickLocateUser()

			}
		})

		
	  }


	  


	//   static getDerivedStateFromProps(nextProps, prevState) {
        
    //     return {
	// 		agentId:nextProps.location.state?nextProps.location.state.agentId:0        
	// 	};
    //    }

       componentDidUpdate() {
       
        if(this.props.location.state)
        {
         
          if(this.props.location.state.agentId!==this.state.agentId)
          {
				this.setState({
					loading:true,
					agentId:false,
					agentInfo:false
				}, ()=>{

					this.setState({
						loading:false,
						agentId:this.props.location.state.agentId
					}, ()=>{

						

							this.getAgentProfile();
							this.getWalletStatement();
							this.listLoans();
							this.getSecurityBalance();
	
							this.timerWalletBalance = setTimeout(this.func=async()=> {
								await this.getWalletBalance();
								this.timerWalletBalance = setTimeout(this.func, 2000); // (*)
							}, 2000);
	
							
							this.timerFloatBalance = setTimeout(this.func=async()=> {
								await this.getFloatBalance();
								this.timerFloatBalance = setTimeout(this.func, 2000); // (*)
							}, 2000);
	




						

					})

				})

				
          }
        }
           
      }

	  
	  componentWillUnmount()
	  {
		  clearTimeout(this.timerWalletBalance);
		  clearTimeout(this.timerFloatBalance);
	  }


      getAgentProfile=()=>{
		  this.setState({
			  agentInfo:false
		  }, async()=>{

				const server_response=await ajax.getAgentProfile(this.state.agentId);
				if(server_response.status==="OK")
				{
				this.setState({
					agentInfo:server_response.details
				}, ()=>{
					const agentInfo=this.state.agentInfo.agent;
					this.setState({
						commission:agentInfo.commission_balance.amount_c
					})
				})
	
				}else{
				this.setState({
					agentInfo:"404"
				})
				}


		  })
		 
	  }
	  
	  getWalletStatement=async()=>{
		const server_response=await ajax.getWalletStatement(this.state.agentId);
		if(server_response.status==="OK")
		{
		   this.setState({
			  walletStatement:server_response.details['records']
		   })

		}else{
		  this.setState({
			walletStatement:"404"
		  })
		}
	}

	getWalletBalance=async()=>{
        const server_response=await ajax.getWalletBalance(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
                walletBalance:server_response.details.amount_c
           })

        }
    }
	  
	
	getFloatBalance=async()=>{
		const server_response=await ajax.getFloatBalance(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
                floatBalance:server_response.details.amount_c
           })

        }
	}


	getSecurityBalance=async()=>{
        const server_response=await ajax.getUserSecurityBalance(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
                securityFee:server_response.details.amount_c
           })

        }
    }
	
	onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{agentId:user_id}
        })
      
	  }
	  

	  onClickAnalyse=()=>{
        this.props.history.push({
          pathname:'/performance-profile',
          state:{agentId:this.state.agentId}
        })
      
      }
      
	  
	  
	  listLoans=async()=>{
		const server_response=await ajax.getLoans(this.state.filter, this.state.page, this.state.agentId);
		if(server_response.status==="OK")
		{
		   this.setState({
			loanHistory:server_response.details.list,
			  metaData:server_response.details.meta
		   })

		}else{
		  this.setState({
			loanHistory:"404"
		  })
		}
	}


	

	onClickResetPIN=()=>{
		this.setState({
			warnAction:false
		}, ()=>{

				this.setState({
					warnAction:<WarnDelete
					title="Reset User PIN?"
					description="You are about to reset this user's transaction PIN! Make sure you inform this user immediately to avoid security bleach."
					buttonLabel="Reset PIN"
					onConfirm={()=>this.onResetPIN()}/>
				}, ()=>{
					$("#modal_delete").modal('show');
				})

		})
	  }

	  resetToast=()=>{
		  this.setState({
			  toast:false
		  })
	  }

	  onResetPIN=async()=>{
		
		const server_response=await ajax.resetTransactionKey(this.state.agentId);
		$("#modal_delete").modal('hide');
		if(server_response.status==="OK")
		{
		   this.setState({
			toast:<Toast 
				message={server_response.details[0]} 
				onClose={()=>this.resetToast()}/>
		   })

		}else{
			this.setState({
				toast:<Toast 
						message={server_response.details[0]} 
						type={3} onClose={()=>this.resetToast()}/>
			})
		}
	  }


	  
	  onClickProfile=()=>{
		this.setState({
			currentOption:"PROFILE"
		})
  		}

		onClickLoanHistory=()=>{
			this.setState({
				currentOption:"LOANS"
			})
		}

		onClickWallet=()=>{
			this.setState({
				currentOption:"WALLET"
			})
		}

		onClickFloat=()=>{
			this.setState({
				currentOption:"FLOAT"
			})
		}

		onClickChangeLimit=()=>{
			this.setState({
				changeLimit:false
			}, ()=>{
				this.setState({
					changeLimit:<PopChangeLimit onConfirm={this.onChangeLimit}/>
				}, ()=>{
					$("#modal_change_limit").modal('show');
				})
			})
			
		}
		
		onChangeLimit=async(amount)=>{


			const server_response=await ajax.updateAgentLimit(this.state.agentId, amount);
			$("#modal_change_limit").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={3} onClose={()=>this.resetToast()}/>
				}, ()=>{


					this.setState({
						customerInfo:false
					}, ()=>{
		
						this.getCustomerProfile();
						this.getWalletStatement();
						this.listLoans();
					})

				})


				
			}

			
		
				
		}

		onClickLocateUser=()=>{
			this.setState({
				locateUser:false
			}, ()=>{
				this.setState({
					locateUser:<PopUserLocation userId={this.state.agentId}/>
				}, ()=>{
					$("#pop-user-location").modal('show');
				})
			})
			
		}

		onClickImage=(url, title)=>{
			this.setState({
			  imageViewer:false
			}, ()=>{
			  this.setState({
				imageViewer:<PopImageViewer url={url} title={title}/>
			  }, ()=>functions.openPopup('modal_image_viewer'))
			})
		  }
		
				
		  onClickVerify=(userInfo)=>{
			this.setState({
			  verification:false
			}, ()=>{
		
			  this.setState({
				verification:<PopVerifyCustomer 
									data={userInfo} 
									onUpdate={this.onUpdateRecord}/>
			  }, ()=>functions.openPopup('modal_verify_customer'))
			})
		   
		  }

		  onUpdateRecord=()=>{
			  this.getAgentProfile();
			this.setState({
			  currentOption:false
			}, ()=>this.setState({currentOption:'PROFILE'}))
		  
		}


		buttons=()=>{

			return(
			  <>
				
					 {functions.findInObject(this.state.accessInfo, "AGENT-EDIT") && <button className="btn ripple btn-primary" onClick={()=>this.onClickVerify(this.state.agentInfo.user)}>
						<i className="fas fa-pencil-alt"></i> Edit Agent
					  </button>}
					 
	  
					  <button className="btn ripple btn-success" href="#" onClick={this.onClickNewReserveRequest}>
						<i className="fas fa-chart-pie"></i> Statistics
					  </button>
				  </>
			)
		  }


		  onClickAccess=()=>{
			  this.setState({
				  mdlLoginSecurity:false
			  }, ()=>{
				  this.setState({
					  mdlLoginSecurity:<PopUserLoginAttempt user={this.state.agentInfo.user} onConfirm={this.onClickLoginReset}/>
				  }, ()=>functions.openPopup("modal_login_security"))
			  })
		  }


		  onClickLoginReset=()=>{
			  functions.closePopup("modal_login_security");
			this.setState({
				verification:false
			}, ()=>{
		
				this.setState({
					verification:<PopVerifyAction
					  title="Reset User Login"
					  description="Enter your account password to reset user login attempts. This will also unlock the user account"
					  value={1}
					  requestId={this.state.agentInfo.user.username}
					  action={"VERIFY-LOGIN-RESET"}
					/>
				}, ()=>{
				  functions.openPopup('pop-verify-action');
			
				})
			})
		  }

    
    render(){
        const agent=this.state.agentInfo;
		const accessInfo=this.state.accessInfo;
		const incognatio=(reserved.profiles.includes(this.state.agentId*1) && !reserved.sudos.includes(this.state.userId*1))?true:false;

          return (
			<div className="page">
			{!this.state.loading && <>
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                
			{!incognatio && <div className="container-fluid">
                <PageHeader 
                     title="Agent Profile"
					 previousPageTitle="Agents"
					 back={"/agent-records"}
					 options={this.state.menuOptions}
					 buttons={<this.buttons/>}
                  />
				  {this.state.toast}
				  {this.state.warnAction}
				  {this.state.changeLimit}
				  {this.state.locateUser}
				  {this.state.verification}
				  {this.state.imageViewer}
				  {this.state.mdlLoginSecurity}
				  
				   {!agent && <ActivityLoader/>}


				   {agent && agent!=="404" && 
					<div className="row">

						<div className="col-lg-4 col-md-12">
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">
											{this.state.agentInfo.user.photo_name==="avatar.png" &&
												<InitialAvatar value={this.state.agentInfo.user.initials}/>}
												{this.state.agentInfo.user.photo_name!=="avatar.png" &&
												<img 
													alt="avatar"
													src={this.state.agentInfo.user.photo}
													className="pointer"
													onClick={()=>this.onClickImage(this.state.agentInfo.user.photo, 'Agent Passport Photo')}
												/>}
										</div>
									</div>
									<div className="item-user pro-user">
										<h4 className="pro-user-username text-dark mt-2 mb-0">
											{this.state.agentInfo.user.first_name + ' ' + this.state.agentInfo.user.last_name}
										</h4>
										<p className="pro-user-desc text-muted mb-1">Agent</p>
										<p className="user-info-rating">
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="far fa-star text-warning"> </i></a>
										</p>
									
										{/* <a style={{color:"#FFFFFF"}}
											onClick={()=>this.onClickVerify(this.state.agentInfo.user)} 
										 	className="btn ripple btn-primary btn-sm">
												 <i className="far fa-edit mr-1"></i>Edit</a> */}

											{functions.findInObject(accessInfo, "AGENT-LOGIN-RESET") && <button
												className="btn ripple btn-dark btn-sm"
												 onClick={this.onClickAccess}
												>
												<i className="fas fa-lock mr-1"
												></i>Access
											</button>}			
										{/* <Link 
											to={{
												pathname:'/performance-profile',
												state:{agentId:this.state.agentId}
											  }}
											onClick={this.onClickAnalyse} className="btn ripple btn-success btn-sm">
												<i className="fas fa-chart-line"></i>Analyse
											</Link> */}

									</div>
								</div>
								<div className="card-footer p-0">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
		  										<h5 className="description-header mb-1">{this.state.floatBalance}</h5>
												<span className="text-muted">Capital Float</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">{this.state.walletBalance}</h5>
												<span className="text-muted">Business Float</span>
											</div>
										</div>
									</div>




									<div className="row text-center">
										<div className="col-sm-6 border-right border-top">
											<div className="description-block">
		  										<h5 className="description-header mb-1">{this.state.securityFee}</h5>
												<span className="text-muted">Security Fee</span>
											</div>
										</div>
										<div className="col-sm-6 border-top">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">{this.state.commission}</h5>
												<span className="text-muted">Commission Balance</span>
											</div>
										</div>
									</div>



								</div>
							</div>


							{/* <div className="card custom-card">
								<div className="card-header custom-card-header">
									<div>
										<h6 className="card-title mb-0">Products Transactions</h6>
									</div>
								</div>
								<div className="card-footer p-2">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
		  										<h5 className="description-header mb-1">00</h5>
												<span className="text-muted">Today</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">00</h5>
												<span className="text-muted">This month</span>
											</div>
										</div>
									</div>

								</div>
							</div> */}
						
							<UserControls info={this.state.agentInfo.user}/>
                        
                        </div>
                  


                        <div className="col-lg-8 col-md-12">
						
						{this.state.currentOption==="LOADING" && <ActivityLoader/>}
						{this.state.currentOption==="PROFILE" && <AgentProfileData info={this.state.agentInfo}/>}
						{this.state.currentOption==="WALLET" && <AgentWallet clientId={this.state.agentInfo.user.user_id}/>}
						{this.state.currentOption==="LOANS" && <AgentLoans clientId={this.state.agentInfo.user.user_id}/>}
						{this.state.currentOption==="FLOAT" && <AgentFloat clientId={this.state.agentInfo.user.user_id}/>}

							{/* <div className="row mt-4">
								<AgentSummary agentId={this.state.agentId} agentInfo={this.state.agentInfo}/>
							</div> */}
						</div>




                    </div>}

				  

                  
                </div>}


				{incognatio && <div className="container-fluid">
					<PageHeader 
                     title="Agent Profile"
					 previousPageTitle="Agents"
					 back={"/agent-records"}
                  />
					<ClassifiedProfile/>
				</div>}

            </div>
            <RightSideBar/>
            <Footer/>
			</>}

			{this.state.loading && <ActivityLoader/>}
        </div>
    
            
          );  
   
    }
  }
  
  export default AgentProfile;
  
import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class AgentPerformance extends React.Component {

    state={
        userId:functions.sessionGuard(),
        agentList:false,
        metaData:false,
        page:1
       
      }

      componentDidMount()
      {
        this.listAgents();
      }


      listAgents=async()=>{
          const server_response=await ajax.getAgents(this.state.page);
          if(server_response.status==="OK")
          {
             this.setState({
                agentList:server_response.details.list,
                metaData:server_response.details.meta
             })

          }else{
            this.setState({
                agentList:"404"
            })
          }
      }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/performance-profile',
          state:{agentId:record.user.user_id}
        })
      

      }
      

      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            agentList:false

          }, ()=>{
            this.listAgents();
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            agentList:false

          }, ()=>{
            this.listAgents();
          })
        }

      }


    
    render(){
        const list=this.state.agentList
        const metaData=this.state.metaData;

          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Agent Performance"
                     previousPageTitle="Agents"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <div>
                              <h6 className="card-title mb-1">Agent Performance</h6>
                              <p className="text-muted card-sub-title">Agents Loan Performance</p>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>#No</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Total Loans</th>
                                    <th>Active</th>
                                    <th>Defaulted</th>
                                    <th>Pre-deadline</th>
                                    <th>Deadline</th>
                                    <th>Post Deadline</th>
                                      
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                                      <tr key={key}>
                                <td>{metaData.offset_count+key+1}</td>
                                <td>
                                <Link 
                                    to={{
                                      pathname:'/performance-profile',
                                      state:{agentId:item.user.user_id}
                                      }}
                                    onClick={()=>this.onClickProfile(item)}>
                                      {item.user.first_name + ' '+ item.user.last_name}
                                    </Link>
                                </td>
                                <td>{item.user.phone}</td>
                                <td>{item.agent.performance.loan_count.total_c}</td>
                                <td className="bg-primary text-white">{item.agent.performance.ACTIVE.total.percent + '% (' + item.agent.performance.ACTIVE.total.total_c + ')'}</td>
                                <td className="bg-danger text-white">{item.agent.performance.DEFAULTED.total.percent + '% (' + item.agent.performance.DEFAULTED.total.total_c +')'}</td>
                                <td className="bg-success text-white">{item.agent.performance.PREDEADLINE.total.percent + '% (' + item.agent.performance.PREDEADLINE.total.total_c +')'}</td>
                                <td className="bg-info text-white">{item.agent.performance.DEADLINE.total.percent + '% (' + item.agent.performance.DEADLINE.total.total_c + ')'}</td>
                                <td className="bg-secondary text-white">{item.agent.performance.POSTDEADLINE.total.percent + '% (' + item.agent.performance.POSTDEADLINE.total.total_c + ')'}</td>
                              </tr>
                                  )}	
                                  
                                </tbody>
                              </table>

                                    {!this.state.agentList && <ActivityLoader/>}

                                    {metaData && <nav className="float-right">
                                    <ul className="pagination">
                                    <li className="page-item">
                                    <Link className="page-link" to="#" onClick={this.onClickPrevious}>Previous</Link>
                                    </li>
                                    <li className="page-item"><a className="page-link">{metaData.current_page}</a></li>
                                    <li className="page-item"><a className="page-link">of</a></li>
                                    <li className="page-item"><a className="page-link">{metaData.total_pages}</a></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={this.onClickNext}>Next</Link>
                                        </li>
                                    </ul>
                                </nav>}

                            </div>
                          </div>
							        </div>
					

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default AgentPerformance;
  
import React from 'react';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import PopVerifyAction from '../Common/PopVerifyAction';
import TextInput from '../Common/TextInput';

class PopNewReserveRequest extends React.Component {

    state={
        userId:functions.sessionGuard(),
        amount:'',
        description:'',
        receiverAccount:'',
        info:'',
        loading:false,
        verification:''
    }

   

    onChangeAmount=(event)=>
    {
        this.setState({amount:event.target.value})
    }

    onChangeDescription=(event)=>
    {
        this.setState({description:event.target.value})
    }

    onChangeReceiver=(event)=>
    {
        this.setState({receiverAccount:event.target.value})
    }

    onConfirm=(event)=>{
        event.preventDefault();
        this.setState({
            info:''
        }, ()=>{

            const {amount, receiverAccount, description}=this.state

            if(amount.length>0 && receiverAccount.length>0 && description.length>0)
            {
               
                this.setState({
                    verification:<PopVerifyAction
                      title="Verify New Requisition"
                      description={"Enter your account password to verify the requisition of UGX."+ amount + ' to be deposited on QuickPost account ' + receiverAccount}
                      requestId={{"amount":amount, "account":receiverAccount, "description":description}}
                      action={"REQUEST-RESERVE"}
                    />
                }, ()=>{
                  functions.closePopup('modal_new_reserve_request');
                  functions.openPopup('pop-verify-action');
      
                })

            }else{
                this.setState({
                    info:<AlertError message={dictionary._completeFields}/>
                  })
                }

        })
        
    }

   
  render(){
    
      return (
        <>
        {this.state.verification}
        <div className="modal" id="modal_new_reserve_request">
        <div className="modal-dialog modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">New Requisition</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
           
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                        
               

                    <TextInput 
                        label="Enter Amount"
                        type="number"
                        onChange={()=>this.onChangeAmount}
                        value={this.state.amount}
                        />

                    <TextInput 
                        label="Receiver Account"
                        type="text"
                        onChange={()=>this.onChangeReceiver}
                        value={this.state.receiverAccount}
                        />

                    <TextInput 
                        label="Request Description"
                        type="textarea"
                        onChange={()=>this.onChangeDescription}
                        value={this.state.description}
                        />

                </form>

                {/* <p><i>Cash withdrawn fr.</i></p> */}

            
            </div>
           {!this.state.loading && <div className="modal-footer">
                <button className="btn ripple btn-success" type="button" onClick={this.onConfirm}>Request Funds</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
    </>
      )
    
 
  }
}

export default PopNewReserveRequest;
import React from 'react';


class SilentText extends React.Component {
  

  render(){
    
      return (
        <small className="tx-12 tx-gray-500">{this.props.text}</small>
      );
    
 
  }
}

export default SilentText;
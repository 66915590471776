import React from 'react';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';

class PopNewPayment extends React.Component {

    state={
        userId:functions.sessionGuard(),
        amount:'',
        info:'',
        loading:false
    }

   

    onChangeAmount=(event)=>
    {
        this.setState({amount:event.target.value})
    }

    onConfirm=(event)=>{
        event.preventDefault();
        this.setState({
            info:''
        }, ()=>{


            if(this.state.amount.length>0)
            {
                this.setState({
                    loading:true
                }, ()=>{
                    this.props.onConfirm(this.state.amount);
                })
                
            }else{
                this.setState({
                    info:<AlertError message={dictionary._completeFields}/>
                  })
                }

        })
        
    }

   
  render(){
    
      return (
        <div className="modal" id="modal_new_payment">
        <div className="modal-dialog modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">New Loan Payment</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                        
               

                    <TextInput 
                        label="Enter Amount"
                        type="number"
                        onChange={()=>this.onChangeAmount}
                        value={this.state.amount}
                        />


                </form>


            
            </div>
           {!this.state.loading && <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Payment</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopNewPayment;
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';


class RecentCustomers extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.getRecentCustomers(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details
               
            })
        }else{
            this.setState({
                list:"404"
               
            })
        }
    }
  
  
  render(){
      const list=this.state.list;
    
      return (
        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Recent Customers
                    <Link 
                        className="btn ripple btn-primary btn-sm see-all-btn" 
                        to="/customer-records">See All</Link>
                </h6>
                <p className="text-muted card-sub-title">Recently registered customers</p>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile Contact</th>
                            <th>Gender</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list!=="404" && 
                         list.map((item, key)=>
                         <tr key={key}>
                                <td>{item.since.when}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.phone}</td>
                                <td>{item.gender}</td>
                                <td>{item.address.address}</td>
                         </tr>
                         )}
                           
                  
                    </tbody>
                </table>

                {!list && <ActivityLoader/>}
               
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default RecentCustomers;
import React from 'react';

class Badge extends React.Component {

    state = {
        types: ['badge-success', 'badge-warning', 'badge-danger', 'badge-primary', 'badge-secondary', 'badge-dark', 'badge-light'],
        type: this.props.type || 0,
        title: this.props.title
    }



    render() {

        return (
            <span className={`badge ${this.state.types[this.state.type]}`}>
                {this.state.title}
            </span>
        );


    }
}

export default Badge;
import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import Dashboard from './Pages/Loans/Dashboard';
import Login from './Pages/Login';
import functions from './functions';
import DashboardCustomers from './Pages/Customer/DashboardCustomers';
import DashboardAgents from './Pages/DashboardAgents';
import DashboardFinance from './Pages/Finance/DashboardFinance';
import LoanPackages from './Pages/LoanPackages';
import LoansOutstanding from './Pages/LoansOutstanding';
import LoansAll from './Pages/LoansAll';
import LoanTodoList from './Pages/LoanTodoList';
import Agents from './Pages/Agents';
import Tokens from './Pages/Tokens';
import AgentPerformance from './Pages/AgentPerformance';
import Customers from './Pages/Customer/Customers';
import FloatTransactions from './Pages/FloatTransactions';
import BusinessFloatTransactions from './Pages/BusinessFloatTransactions';
import WalletTransactions from './Pages/WalletTransactions';
import ReserveTransactions from './Pages/ReserveTransactions';
import CommissionTransactions from './Pages/CommissionTransactions';
import FloatStatement from './Pages/FloatStatement';
import BusinessFloatStatement from './Pages/BusinessFloatStatement';
import WalletStatement from './Pages/WalletStatement';
import ReserveStatement from './Pages/ReserveStatement';
import CommissionStatement from './Pages/CommissionStatement';
import MdlSystemSearch from './Components/Common/MdlSystemSearch';
import CustomerProfile from './Pages/Customer/CustomerProfile';
import AgentProfile from './Pages/AgentProfile';
import LoanProfile from './Pages/LoanProfile';
import UserPerformanceProfile from './Pages/UserPerformanceProfile';
import Settings from './Pages/Settings';
import ManageLoan from './Pages/ManageLoan';
import SetPassword from './Pages/SetPassword';
import NoPermission from './Pages/NoPermission';
import DeviceLog from './Pages/Access/DeviceLog';
import EditCustomer from './Pages/User/EditCustomer';
import DashboardSecurityDeposit from './Pages/Loans/DashboardSecurityDeposit';
import DashboardCapitalFloat from './Pages/Loans/DashboardCapitalFloat';
import UserManagement from './Pages/Access/UserManagement';
import DashboardReserve from './Pages/Reserve/DashboardReserve';
import BadLoans from './Pages/Loans/BadLoans';
import ListCustomers from './Pages/Customer/ListCustomers';
import DashboardProducts from './Pages/Products/DashboardProducts';
import ListSupervisors from './Pages/Supervisor/ListSupervisors';
import DashboardWallet from './Pages/Finance/DashboardWallet';
import Activity from './Pages/Access/Activity';
import CashRoundDashboard from './Pages/CashRound/CashRoundDashboard';
import ListPurchasesPage from './Pages/Products/ListPurchasesPage';
import ListCashRoundsPage from './Pages/CashRound/ListCashRoundsPage';
import CashRoundProfile from './Pages/CashRound/CashRoundProfile';
import TicketsDashboard from "./Pages/Tickets/TicketsDashboard";
import TicketInfo from "./Pages/Tickets/TicketInfo";
import ClosedTickets from "./Pages/Tickets/ClosedTickets";

// import NewCard from './Pages/NewCard';
// import ListCards from './Pages/ListCards';



class App extends React.Component {

  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.readPermissions()
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    //const cookies = new Cookies();
    //if(!cookies.get('quickpost@user'))
    if (!window.localStorage.getItem("quickpost@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }

  dashboards = () => {
    if (functions.findInObject(this.state.accessInfo, "LOAN0000-2")) {
      return (
        <Dashboard />
      )
    } else if (functions.findInObject(this.state.accessInfo, "CUST000-2")) {
      return (
        <DashboardCustomers />
      )
    } else if (functions.findInObject(this.state.accessInfo, "AGENT000-2")) {
      return (
        <DashboardAgents />
      )
    } else if (functions.findInObject(this.state.accessInfo, "WALLET0001-2")) {
      return (
        <DashboardFinance />
      )
    } else if (functions.findInObject(this.state.accessInfo, "DASHBOARD-PRODUCTS")) {
      return (
        <DashboardProducts />
      )
    } else if (functions.findInObject(this.state.accessInfo, "DASHBOARD-CASHROUND")) {
      return <CashRoundDashboard />
    }

    else {
      return (
        <NoPermission />
      )
    }
  }

  render() {

    const secure = functions.checkSecureAccount();
    return (
      <div id="app_container" onClick={functions.closeHeaderDropdowns}>
        <Router forceRefresh={false}>
          <MdlSystemSearch />
          {!this.state.loggedIn && <Redirect to="/login" />}
          {secure === "0" && <Redirect to="/security/set-password" />}
          <Switch>
            {!this.state.loggedIn && <Route exact path="/" component={Login} />}
            {this.state.loggedIn && <Route exact path="/" component={Dashboard} />}
            <Route exact path="/login" component={Login} />
            <Route exact path="/dashboard" component={this.dashboards} />
            <Route exact path="/loans" component={Dashboard} />
            <Route exact path="/customers" component={DashboardCustomers} />
            <Route exact path="/agents" component={DashboardAgents} />
            <Route exact path="/finance" component={DashboardFinance} />
            <Route exact path="/loan-packages" component={LoanPackages} />
            <Route exact path="/outstanding-loans" component={LoansOutstanding} />
            <Route exact path="/all-loans" component={LoansAll} />
            <Route exact path="/dashboard/todolist" component={LoanTodoList} />
            <Route exact path="/agent-records" component={Agents} />
            <Route exact path="/agent-tokens" component={Tokens} />
            <Route exact path="/agent-performance" component={AgentPerformance} />
            <Route exact path="/customer-records" component={Customers} />
            <Route exact path="/float-transactions" component={FloatTransactions} />
            <Route exact path="/business-float-transactions" component={BusinessFloatTransactions} />
            <Route exact path="/wallet-transactions" component={WalletTransactions} />
            <Route exact path="/reserve-transactions" component={ReserveTransactions} />
            <Route exact path="/commission-transactions" component={CommissionTransactions} />
            <Route exact path="/float-statement" component={FloatStatement} />
            <Route exact path="/business-float-statement" component={BusinessFloatStatement} />
            <Route exact path="/wallet-statement" component={WalletStatement} />
            <Route exact path="/reserve-statement" component={ReserveStatement} />
            <Route exact path="/commission-statement" component={CommissionStatement} />
            <Route exact path="/customer-profile/:customerId" component={CustomerProfile} />
            <Route exact path="/agent-profile/:agentId" component={AgentProfile} />
            <Route exact path="/loan-profile" component={LoanProfile} />
            <Route exact path="/performance-profile" component={UserPerformanceProfile} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/manage-loan" component={ManageLoan} />
            <Route exact path="/security/set-password" component={SetPassword} />
            <Route exact path="/activity/device_logs" component={DeviceLog} />
            <Route exact path="/customer-profile/edit" component={EditCustomer} />
            <Route exact path="/loans/security_deposit" component={DashboardSecurityDeposit} />
            <Route exact path="/loans/capital_float" component={DashboardCapitalFloat} />
            <Route exact path="/users/user_management" component={UserManagement} />
            <Route exact path="/finance/reserve" component={DashboardReserve} />
            <Route exact path="/loans/classification" component={BadLoans} />
            <Route
              exact path="/loans/classification/:type"
              component={BadLoans}
              render={props => <BadLoans type={this.props.params.type} {...props} />}
            />
            <Route exact path="/customers/list_customers" component={ListCustomers} />
            <Route exact path="/products" component={DashboardProducts} />
            <Route exact path="/agents/supervisors" component={ListSupervisors} />
            <Route exact path="/finance/wallet" component={DashboardWallet} />
            <Route exact path="/activity" component={Activity} />
            {functions.findInObject(this.state.accessInfo, "DASHBOARD-CASHROUND") && <Route exact path="/cashround" component={CashRoundDashboard} />}
            <Route exact path="/products/transactions" component={ListPurchasesPage} />
            <Route exact path="/cashround/groups" component={ListCashRoundsPage} />
            <Route exact path="/cashround/profile/:cashRoundId" component={CashRoundProfile} />
            <Route exact path="/tickets" component={TicketsDashboard} />
            <Route exact path="/ticket/info" component={TicketInfo} />
            <Route exact path="/closed/tickets" component={ClosedTickets} />




          </Switch>

        </Router>
      </div>

    )

  }

}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from './../Common/ActivityLoader';
import MdlLoanStatement from './../MdlLoanStatement';
import $ from 'jquery';
import TableHeader from '../Common/TableHeader';


class RecentSecurityDeposits extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        statement:false,
        selectedLoanId:''
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.listRecentSecurityDepositTransactions();
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details
               
            })
        }else{
            this.setState({
                list:"404"
               
            })
        }
    }

    onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }

    onClickLoan=(record)=>{
        this.setState({
          statement:false
        }, ()=>{
          this.setState({
            statement:<MdlLoanStatement loan={record}/>
          }, ()=>{
            $("#modal_loan").modal('show');
          })
        })
      }


  
  render(){
      const list=this.state.list;
     
      return (
        <div className="card custom-card">
            {this.state.statement}
        <div className="card-body">
            <TableHeader
                title="Recent Transaction"
                subtitle="Recently deposited/refunded security fee"
                view="View All"
                //onClick={this.props.onClickViewAll}
            />
            <div className="table-responsive">
                <table className="table table-hover text-nowrap mg-b-0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Customer Name</th>
                            <th>Customer Mobile</th>
                            <th className="text-center tx-bold">Transaction</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list!=="404" && 
                         list.map((item, key)=>
                         <tr 
                            key={key}
                            className={`${this.state.selectedLoanId==item.loan_id?'flag-row':''}`}
                            //onClick={()=>this.onSelectRecord(item.loan_id)}
                            >
                            
                            <td>
                                {item.trans_date.short_date}<br/>
                                <small className="tx-12 tx-gray-500">{item.trans_date.time}</small>
                            </td>

                            <td>
                                {item.user_info.first_name}<br/>
                                <small className="tx-12 tx-gray-500">{item.user_info.last_name}</small>
                            </td>
                            <td>
                                {item.user_info.phone}
                            </td>
                            <td className="text-center tx-bold">
                                <span className={`badge ${item.cash_in*1>0?'badge-success':'badge-secondary'}`}>
                                    {item.transaction}
                                </span>
                            </td>
                            <td>{item.amount.amount_c}</td>
                         </tr>
                         )}
                           
                  
                    </tbody>
                </table>

                {!list && <ActivityLoader/>}
               
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default RecentSecurityDeposits;
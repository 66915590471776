import React from 'react';
import { Link } from 'react-router-dom';


class AddButton extends React.Component {
  

  render(){
    
      return (
        <div className="row">

        <div className="col-12 mb-2">
          <div class="float-right">  
               <Link to="#" data-target={"#"+this.props.mdl} data-toggle="modal"><i className="fe fe-plus"></i></Link> 
            </div>
             </div>
        </div>

      );
    
 
  }
}

export default AddButton;
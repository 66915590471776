import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PillTag from '../Common/PillTag';
import SilentText from '../Common/SilentText';
import PopTransactionDetails from './PopTransactionDetails';


class RecentSales extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        mdlTransaction:false
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.recentProductSales();
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details,
            
            })
        }else{
            this.setState({
                list:'404'
            
            })
        }
    }

    onClickTransaction=(record)=>{
        this.setState({
            mdlTransaction:false
        }, ()=>{
            this.setState({
                mdlTransaction:<PopTransactionDetails record={record}/>
            }, ()=>{
                functions.openPopup('mdl-transaction-details');
            })
        })
    }
  
  
  render(){
      const list=this.state.list;
    
      return (
        <>
        {this.state.mdlTransaction}
        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Recent transactions
                    <span className="float-right m-10">
                        <Link class="btn ripple btn-primary btn-sm" to="/products/transactions">See All</Link>
                    </span>
                </h6>
                <p className="text-muted card-sub-title">Most recent products/bills transactions</p>

            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
            <div className="table-responsive">
                <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Transaction Time</th>
                            <th>Details</th>
                            <th>Transactor Details</th>
                            <th>Amount</th>
                           
                            <th>trans</th>
                        </tr>
                    </thead>
                    <tbody>

                    {list && list!=="404" && list.map((item, key)=>
                            <tr 
                            className="hot-tr cursor-pointer"
                            key={key} title={item.description} style={{cursor:'default'}}
                            onClick={()=>this.onClickTransaction(item)}
                            >
                            <td>
                                {item.trans_date.short_date}
                                <br/><SilentText text={item.trans_date.time}/>

                            </td>
                            <td>
                                {item.product.name}
                                <br/><SilentText text={item.product.local_category + ' - ' + item.beneficiary}/>
                                
                            </td>
                            <td>
                                {item.user_info.username}
                                <br/><SilentText text={item.user_info.first_name + ' ' + item.user_info.last_name}/>
                                <SilentText text={ " - " + item.user_info.role_name}/>

                            </td>
                             <td>
                                 {item.amount.amount_c}
                                 <br/><SilentText text="UGX"/>
                             </td>
                            <td>
                             { item.status_id*1==3 && <PillTag title={item.status}/>}
                             { item.status_id*1==2 &&<PillTag type={2} title={item.status}/>}
                             { item.status_id*1==1 && <PillTag type={4} title={item.status}/>}
                             { item.status_id*1==0 && <PillTag type={5} title={item.status}/>}
                            </td>

                        </tr>
                            )}	
                        
                    </tbody>
                </table>
                <br/>
                    {!this.state.list && <ActivityLoader/>}

            </div>
            </div>
        </div>
    </div>
    </>
      )
    
 
  }
}

export default RecentSales;
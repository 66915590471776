import React from 'react';
import ajax from '../ajax';
import functions from '../functions';

class LoanSummary extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        list:false,
        loanBalance:'.',
        totalLoan:'',
        totalPaid:'',
        loanRecovery:0,
        loanRecoveryBarValue:0,
        totalPrincipal:''
    }

    async componentDidMount()
    {
        await this.countLoan();
        this.timerLoan = setTimeout(this.func4=async()=> {
            await this.countLoan();
            this.timerLoan = setTimeout(this.func4, 2000); // (*)
          }, 2000);
    }

    componentWillUnmount()
    {

    }

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }


    countLoan=async()=>
    {
        const server_reponse=await ajax.countLoans();
        if(server_reponse.status==="OK")
        {
            this.setState({
                loanBalance:server_reponse.details.balance.amount_c,
                totalPaid:server_reponse.details.total_paid.amount_c,
                totalLoan:server_reponse.details.total_loan.amount_c,
                totalPrincipal:server_reponse.details.total_principal.amount_c,
                loanRecovery:Math.round((server_reponse.details.total_paid.amount/server_reponse.details.total_loan.amount)*100)
            }, ()=>{
                this.setState({
                    loanRecoveryBarValue:functions.baseValue(this.state.loanRecovery)
                })
            })
        }
    }
  
  render(){
    
      return (
       
    <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6 className="card-title mb-1">Principal + Interest + Fine</h6>
                    <p className="text-muted card-sub-title">Summary of Paid and outstanding loans</p>
                </div>
                <div className="row">
                    <div className="col-6 col-md-6 text-center">
                        
                        <p className="mb-1 tx-inverse">Total Loans</p>
                        <h4 className="mb-1">{ this.state.totalLoan }<span>/=</span></h4>
                        
                    </div>
                    <div className="col-6 col-md-6 text-center">
                    
                        <p className="mb-1 tx-inverse">Loan Balances</p>
                        <h4 className="mb-1">{ this.state.loanBalance }<span>/=</span></h4>
                    
                    </div>

                </div>

                <div className="clearfix mb-3">
                    <div className="clearfix">
                        <span className="float-left text-muted">Loan Recovery</span>
                        <span className="float-right">{this.state.loanRecovery}%</span>
                    </div>
                    <div className="progress mt-1">
                        <div aria-valuemax="100" 
                            aria-valuemin="0" aria-valuenow={this.state.loanRecovery} 
                            className={`progress-bar progress-bar-xs wd-${this.state.loanRecoveryBarValue}p bg-success`} 
                            role="progressbar"></div>
                    </div>
                    <span className="text-muted fs-12"><i className="fas fa-caret-up mr-1 text-success"></i>
                                Paid UGX.{this.state.totalPaid}
                    </span>

                    <span className="text-muted fs-12"> &nbsp; &nbsp;<i className="fas fa-caret-up mr-1 text-primary"></i>
                        Princ. UGX. {this.state.totalPrincipal}
                    </span>

                </div>



            </div>
        </div>

      )
    
 
  }
}

export default LoanSummary;
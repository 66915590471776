import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AgentSummary from './AgentSummary';
import ListPurchases from '../Products/ListPurchases';

class AgentProfileData extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    agentInfo:this.props.info,
    loanStatistics:false
  }

  componentDidMount() {
    this.getLoanStatistics();
  }


  getLoanStatistics=async()=>{
		const server_response=await ajax.clientPeriodicalLoanStatistics(this.state.agentInfo.user.user_id);
		if(server_response.status==="OK")
		{
		   this.setState({
			loanStatistics:server_response.details
		   })

		}
	}


  basicInfo=()=>{

    const documents=this.state.agentInfo.user.documents;

    return  <div className="card custom-card">
    <div className="">
      <div className="main-content-body main-content-body-contacts">
          {/* <div className="">
                  <h6 className="card-title ml-3 mt-3">Profile</h6>
                  <p className=" card-sub-title ml-3">Agent profile info</p>
              </div> */}
        <div className="main-contact-info-body">
          
          <div className="media-list pb-lg-0">
            <div className="media">
              <div className="media-body">
                <div>
                  <label>Full Name</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.first_name + ' ' + this.state.agentInfo.user.last_name}</span>
                </div>
                <div>
                  <label>Gender</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.gender}</span>
                </div>
              </div>
            </div>
            <div className="media">
              <div className="media-body">
                <div>
                  <label>Birthday</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.dob.db=="0000-00-00"?"Not Provided":this.state.agentInfo.user.dob.picker}</span>
                </div>
                <div>
                  <label>Phone</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.phone}</span>
                </div>
              </div>
            </div>
            
            <div className="media mb-0">
              <div className="media-body">
                <div>
                  <label>Email</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.email}</span>
                </div>
                <div>
                  <label>Supervisor</label> 
                  <span className="tx-medium">
                      {this.state.agentInfo.agent.supervisor?this.state.agentInfo.agent.supervisor.last_name + " " + this.state.agentInfo.agent.supervisor.first_name + "(" + this.state.agentInfo.agent.supervisor.phone + ")": "N/A"}
                    </span>
                </div>
              </div>
            </div>

            <div className="media">
              <div className="media-body">
                <div>
                  <label>Current Address</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.address.address}</span>
                </div>
              </div>
            </div>



            <div className="media">
              <div className="media-body">
                <div>
                  <label>Reg. Date</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.reg_date}</span>
                </div>
                <div>
                  <label>Acc. Code</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.account_number}</span>
                </div>
              </div>
            </div>


            
            <div className="media">
              <div className="media-body">
                <div>
                  <label>National ID</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.NIN}</span>
                </div>
               
              </div>
            </div>


             
            <div className="media">
              <div className="media-body">
                <div>
                  <label>Current Device Token</label> 
                  <span className="tx-medium">{this.state.agentInfo.user.device}</span>
                </div>
               
              </div>
            </div>


          </div>

          <div className="main-content-label tx-13 mg-b-20 mt-3">
                Documents
              </div>
              <div className="row">
                {documents.map((item, key)=>
                  <div className="col-6 col-md-3" key={key}>
                  <img 
                    alt="Document" 
                    title={item.document_name}
                    className="img-thumbnail" 
                    src={item.file_path}/>
                </div>
                
                )}
              </div>





        </div>
      </div>
    </div>
  </div>


  }


  


  loanSummary=()=>{

    const statistics = this.state.loanStatistics;
    const title="Loan Meta Data"
    return <div className="card custom-card">
      <div className="">
      <div className="main-contact-info-body">

        <div className="row mt-4">
          <AgentSummary loanStatistics={statistics} agentInfo={this.state.agentInfo}/>
        </div>
   
        <div className="main-content-label tx-13 mg-b-20 mg-t-20">{title}</div>
          {statistics && <ul className="list-unstyled mb-0" style={{lineHeight:2.2}}>
              <li className="row">
                <div className="col-sm-3 ">Gross Loans</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_loan.amount_p}</div>
              </li>
              <li className=" row">
                <div className="col-sm-3 ">Gross Loan Repayment</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_paid.amount_p}</div>
              </li>
              <li className="p-b-20 row">
                <div className="col-sm-3 ">Gross Loan Balance</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.balance.amount_p}</div>
              </li>
              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Principal Loans</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_principal.amount_p}</div>
              </li>

              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Principal Paid</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_principal_paid.amount_p}</div>
              </li>
              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Principal Balance</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.principal_balance.amount_p}</div>
              </li>

              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Principal Defaulted</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_defaulted_principal.amount_p}</div>
              </li>
              
              <li className="p-b-20 row">
                <div className="col-sm-3 ">Performance</div>
                <div className="col-sm-3 text-bold">{statistics.performance}%</div>
              </li>

              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Fine On Loans</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_loan_fine.amount_p}</div>
              </li>

              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Interest On Loans</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_interest.amount_p}</div>
              </li>

              
              <li className="p-b-20 row">
                <div className="col-sm-3 ">Total Fine Paid</div>
                <div className="col-sm-3 text-bold">UGX. {statistics.total_paid_fine.amount_p}</div>
              </li>

            </ul>}

            {!statistics && <ActivityLoader/>}

          </div>
        </div>
    </div>

  }
    
  render(){

      return (
       
        <div className="border">
        <div className="bg-light">
          <nav className="nav nav-tabs">
            <a className="nav-link active" data-toggle="tab" href="#tab1">Basic Info</a>
            <a className="nav-link" data-toggle="tab" href="#tab2">Loan Summary</a>
            <a className="nav-link" data-toggle="tab" href="#tab3">Bill Payments</a>
            <a className="nav-link" data-toggle="tab" href="#tab4">Cash Round</a>

          </nav>
        </div>
        <div className="tab-content">
          <div className="tab-pane active show" id="tab1">
              <this.basicInfo/>
          </div>
          <div className="tab-pane" id="tab2">
            <this.loanSummary/>
          </div>
          <div className="tab-pane" id="tab3">
              <ListPurchases userId={this.state.agentInfo.user.user_id}/>
           
          </div>
        </div>
      </div>



      );
    
 
  }
}

export default AgentProfileData
import dictionary from "../dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('quickpost@user');

export default {
   
   
    async filterProductTransactions(page, categoryFilter, userId)
    {

        let data={
            "page":page,
            "category_filter":categoryFilter,
            "user_id":userId
        }
        try{
            let response= await fetch(apiHost + 'product/request/list',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async categoryProductListing()
    {

        let data={
            "source":"web"
        }
        try{
            let response= await fetch(apiHost + 'product/listing',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

}
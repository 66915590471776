import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../ajax';
import functions from '../functions';
import ActivityLoader from './Common/ActivityLoader';
import MdlLoanStatement from './MdlLoanStatement';
import $ from 'jquery';
import SilentText from './Common/SilentText';


class RecentLoans extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        statement:false,
        selectedLoanId:''
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.getRecentLoans(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details
               
            })
        }else{
            this.setState({
                list:"404"
               
            })
        }
    }

    onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }

    onClickLoan=(record)=>{
        this.setState({
          statement:false
        }, ()=>{
          this.setState({
            statement:<MdlLoanStatement loan={record}/>
          }, ()=>{
            $("#modal_loan").modal('show');
          })
        })
      }
  
  
  render(){
      const list=this.state.list;
     
      return (
        <div className="card custom-card ">
            {this.state.statement}
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Recent Loans
                <span className="float-right m-10">
                    <Link class="btn ripple btn-primary btn-sm" to="/all-loans">See All</Link>
                </span>
                </h6>
                <p className="text-muted card-sub-title">Loan principals given out recently</p>
               
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
            <div className="table-responsive">
                <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                        <th>Loan ID</th>
                        <th>Customer</th>
                        <th>Agent</th>
                        <th>Loan Date</th>
                        <th>Amount</th>
                        <th>Deadline/Next Pay Day</th>

                        </tr>
                    </thead>
                    <tbody>
                        {list && list!=="404" && 
                         list.map((item, key)=>
                         <tr 
                            key={key}
                            className={`${this.state.selectedLoanId==item.loan_id?'flag-row':''}`}
                            onClick={()=>this.onSelectRecord(item.loan_id)}>
                    



                                <td>
                                        <Link 
                                          className="orange" 
                                          to="#"
                                          onClick={()=>this.onClickLoan(item)}
                                          >
                                        {item.loan_code}
                                        </Link><br/>
                                        <SilentText text={item.package_info.loan_type.type_name}/>
                                    </td>
                                    <td>
                                        <Link className="orange" 
                                          to={
                                              {
                                                pathname:`/customer-profile/${item.customer_info.user_id}`,
                                                state:{customerId:item.customer_info.user_id}
                                              }
                                            }>
                                        {item.customer_info.first_name + " " + item.customer_info.last_name}</Link><br/>
                                        <SilentText text={item.customer_info.phone}/>
                                    </td>
  
                                    <td>
                                        <Link className="orange"
                                          to={
                                            {
                                              pathname:`/agent-profile/${item.agent_info.user_id}`,
                                              state:{agentId:item.agent_info.user_id}
                                            }}
                                          >
                                            {item.agent_info.first_name + ' ' + item.agent_info.last_name}
                                          </Link>
                                          <br/><SilentText text={item.agent_info.phone}/>
  
                                    </td>
                                    
                                  <td>
                                    {item.loan_date.short_date}
                                    <br/><SilentText text={item.loan_date.time}/>
  
                                  </td>
                                  <td>
                                    {item.total_loan.amount_c}
                                    <br/><SilentText text={item.package_info.package_name}/>
                                  </td>
                                  <td>
                                    {item.package_info.loan_type.type_id=="2"?item.particulars.next_payment_date.short_date:item.deadline.short_date}
                                    <br/><SilentText text={item.package_info.loan_type.type_id=="2"?"Next Pay Day at " + item.particulars.next_payment_date.time:"Actual Deadline at "+ item.deadline.time}/>
                                </td>
                                  
                            

                         </tr>
                         )}
                           
                  
                    </tbody>
                </table>

                {!list && <ActivityLoader/>}
               
            </div>
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default RecentLoans;
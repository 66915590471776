import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PopImageViewer from '../Common/PopImageViewer';
import SilentText from '../Common/SilentText';
import PopTopupApproval from './PopTopupApproval';


class TopupRequests extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        receipt:false,
        popApproval:false
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.listAllTopupRequests(1);
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details.list,
            
            })
        }else{
            this.setState({
                list:'404'
            
            })
        }
    }

    viewImage=(file)=>{
        this.setState({
            receipt:false
        }, ()=>{
            this.setState({
                receipt:<PopImageViewer title="Bank Deposit Receipt" url={file}/>
            }, ()=>functions.openPopup("modal_image_viewer"))
        })
    }


    onClickManageRequest=(record)=>
    {
            this.setState({
                popApproval:false
            }, ()=>{
                this.setState({
                    popApproval:<PopTopupApproval record={record}/>
                }, ()=>{
                    functions.openPopup('mdl-topup-approval')
                })
            })
    }
  
  
  render(){
      const list=this.state.list;
      return (
        <>
        {this.state.receipt}
        {this.state.popApproval}
        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Manual Topup Requests</h6>
                <p className="text-muted card-sub-title">Topup requests for wallet and business float</p>
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
            <div className="table-responsive">
                <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Request Date</th>
                            <th>Requested By</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Receipt</th>
                            <th>Reference Account</th>
                        </tr>
                    </thead>
                    <tbody>

                    {list && list!=="404" && list.map((item, key)=>
                            <tr 
                                className="hot-tr"
                                key={key}
                                style={{cursor:'default'}}>
                            <td>
                                {item.request_date.short_date}
                                <br/><SilentText text={item.request_date.time}/>

                            </td>
                            <td>
                                {item.user.username + " - " + item.user.role_name}
                                <br/><SilentText text={item.user.first_name + ' ' + item.user.last_name}/>

                            </td>
                            <td>
                                {item.amount.amount_p}
                                <br/><SilentText text="UGX"/>
                            </td>
                             <td>
                                 {item.status.status_name}
                                 <br/><Link to="#" onClick={()=>this.onClickManageRequest(item)}>Manage Request...</Link>
                             </td>
                             <td className="pointer">
                                    <div className="main-img-user">
                                        <img alt="Img" 
                                            onClick={() => this.viewImage(item.receipt.file_path)} 
                                            src={item.receipt.file_path} />
                                    </div>
                             </td>
                             <td>
                                 {item.current_account.account_number}
                                 <br/><SilentText text={item.current_account.account_name}/>
                             </td>
                          
                        </tr>
                            )}	
                        
                    </tbody>
                </table>
                <br/>
                    {!this.state.list && <ActivityLoader/>}

            </div>
            </div>
        </div>
    </div>
    </>
    )
    
 
  }
}

export default TopupRequests;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import BusinessFloat from '../../Components/Cards/BusinessFloat'
import CapitalFloat from '../../Components/Cards/CapitalFloat'
import WalletBalance from '../../Components/Cards/WalletBalance'
import Commission from '../../Components/Cards/Commission'
import RecentTransactions from '../../Components/RecentTransactions'
import Reserve from '../../Components/Cards/Reserve'
import PopWithdrawReserve from '../../Components/Reserve/PopWithdrawReserve'
import $ from 'jquery';
import PopTransferFloat from '../../Components/Float/PopTransferFloat'
import Toast from '../../Components/Common/Toast'
import ajax from '../../ajax'
import PopNewReserveRequest from '../../Components/Reserve/PopNewReserveRequest'
import WeeklyTransactionGraph from '../../Components/WeeklyTransactionGraph'
import PendingDeposits from '../../Components/Cards/Finance/PendingDeposits'
import TotalDailyDeposits from '../../Components/Cards/Finance/TotalDailyDeposits'
import TotalDailyWithdraws from '../../Components/Cards/Finance/TotalDailyWithdraws'
import ActualBalance from '../../Components/Cards/Finance/ActualBalance'
import TopupRequests from '../../Components/Wallet/TopupRequests'
import RecentMobileMoneyDeposits from '../../Components/Wallet/RecentMobileMoneyDeposits'

class DashboardWallet extends React.Component {

    state={
      userId:functions.sessionGuard(),
      withdrawReserve:false,
      transferFloat:false,
      toast:false,
      accessInfo:functions.readPermissions(),
      reserveRequest:false
    }

    onClickWithdrawReserve=()=>{
      this.setState({
        withdrawReserve:false
      }, ()=>{
        this.setState({
          withdrawReserve:<PopWithdrawReserve onConfirm={this.onWithdrawReserve}/>
        }, ()=>{
          $("#modal_withdraw_reserve").modal('show');
        })
      })
    }


    onClickTransferFloat=()=>{
      this.setState({
        transferFloat:false
      }, ()=>{
        this.setState({
          transferFloat:<PopTransferFloat onConfirm={this.onTransferFloat}/>
        }, ()=>{
          $("#modal_transfer_float").modal('show');
        })
      })
    }

    resetToast=()=>{
		  this.setState({
			  toast:false
		  })
	  }


    onWithdrawReserve=async(amount)=>{
      const server_response=await ajax.withdrawReserve(amount);
			$("#modal_withdraw_reserve").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={2} onClose={()=>this.resetToast()}/>
        })
      }
    }

    onTransferFloat=async(amount, phone)=>{
      const server_response=await ajax.purchaseFloatForAgent(phone, amount);
			$("#modal_transfer_float").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={2} onClose={()=>this.resetToast()}/>
        })
      }
    }

   
    buttons=()=>{
      return(
        <div className="btn btn-list">
          
               {functions.findInObject(this.state.accessInfo, "WALLET-TRANSFER-1") && <a className="btn ripple btn-success" href="#" onClick={this.onClickWithdrawReserve}>
                  <i className="fas fa-exchange-alt"></i> Wallet Transfer
                </a>}
                {/* {functions.findInObject(this.state.accessInfo, "RSV-TRANSFER-1") &&
                <a className="btn ripple btn-secondary" href="#" onClick={this.onClickTransferFloat}>
                  <i className="fe fe-external-link"></i> Transfer Float
                </a>} */}

              
			</div>
      )
    }


    onClickNewReserveRequest=()=>{
      this.setState({
        transferFloat:false
      }, ()=>{
        this.setState({
          transferFloat:<PopNewReserveRequest/>
        }, ()=>{
          $("#modal_new_reserve_request").modal('show');
        })
      })
    }

    
    render(){
     
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Wallet & Business Float"
                    previousPageTitle="Finance"
                    buttons={<this.buttons/>}
                    back="/finance"
                  />
                  {this.state.withdrawReserve}
                  {this.state.transferFloat}
                  {this.state.toast}
                  {this.state.reserveRequest}
                  
                    <div className="row">
                      <PendingDeposits/>
                      <TotalDailyDeposits/>
                      <TotalDailyWithdraws/>
                      <ActualBalance/>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                       <TopupRequests/>
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                        <RecentMobileMoneyDeposits/>
                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardWallet
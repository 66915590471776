import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';
import TitleValueCard from '../Common/TitleValueCard';


class CustomerSummary extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    customerInfo:this.props.info
  }

  
  // componentDidMount() {
  //   this.getLoanStatistics(); 
  // }
 
  // getLoanStatistics=async()=>{
	// 	const server_response=await ajax.clientPeriodicalLoanStatistics(this.state.customerInfo.user.user_id);
	// 	if(server_response.status==="OK")
	// 	{
	// 	   this.setState({
	// 		loanStatistics:server_response.details
	// 	   })

	// 	}
	// }

    
  render(){

    const loanInfo=this.state.customerInfo.loans;
    
      return (
        
    
            <>
              <TitleValueCard colSize={4} href="#" title="Loan Performance" value={loanInfo.performance + '%'} icon="fas fa-chart-line" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />

              {/* <TitleValueCard colSize={4} href="#" title="Total Loans" value={this.state.customerInfo.loans.total_loan.amount_c} icon="fas fa-university" color="icon-service rounded-circle bg-primary-transparent text-primary" />
              <TitleValueCard colSize={4} href="#" title="Total Loan Repayments" value={this.state.customerInfo.loans.total_paid.amount_c} icon="fas fa-university" color="icon-service rounded-circle bg-success-transparent text-success" />
              <TitleValueCard colSize={4} href="#" title="Loan Worth" value={this.state.customerInfo.loan_worth.amount_c} icon="fas fa-tint" color="icon-service rounded-circle bg-primary-transparent text-primary" /> */}

            </>
          
      );
    
 
  }
}

export default CustomerSummary;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import CustomerRanking from '../../Components/Cards/CustomerRanking'
import TotalCustomersAll from '../../Components/Cards/TotalCustomersAll'
import TotalCustomersToday from '../../Components/Cards/TotalCustomersToday'
import LoanedCustomers from '../../Components/Cards/LoanedCustomers'
import AgentCustomers from '../../Components/Cards/AgentCustomers'
import RecentCustomers from '../../Components/User/RecentCustomers'
import { Link } from 'react-router-dom'

class DashboardCustomers extends React.Component {

    state={
      userId:functions.sessionGuard(),
    }

   
    buttons=()=>{
      return(
        <div className="btn btn-list">
          
                <Link className="btn ripple btn-success" to="#">
                  <i className="fas fa-check"></i> Verify Customers
                </Link>
                {/* {functions.findInObject(this.state.accessInfo, "RSV-TRANSFER-1") &&
                <a className="btn ripple btn-secondary" href="#" onClick={this.onClickTransferFloat}>
                  <i className="fe fe-external-link"></i> Transfer Float
                </a>} */}

                <Link className="btn ripple btn-primary" to="/customers/list_customers">
                  <i className="fas fa-list"></i> View All Records
                </Link>
			</div>
      )
    }

    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Customers"
                    previousPageTitle="Dashboard"
                    buttons={<this.buttons/>}
                  />
                  
                    <div className="row">
                      <TotalCustomersAll/>
                      <TotalCustomersToday/>
                      <AgentCustomers/>
                      <LoanedCustomers/>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                        <RecentCustomers/>
                          
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                         <CustomerRanking/>
                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardCustomers
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import $ from 'jquery';
import PageNav from '../../Components/Common/PageNav'
import SilentText from '../../Components/Common/SilentText'
import PillTag from '../../Components/Common/PillTag'
import ajaxProduct from '../../remote/ajaxProduct'
import PopTransactionDetails from '../../Components/Products/PopTransactionDetails'
import { setState } from 'expect'

class ListPurchasesPage extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        filter:"ALL",
        page:1,
        title:"All Transctions",
        loanRecord:'',
        selectedLoanId:'',
        statement:false,
        pagination:false,
        mdlTransaction:false,
        productFilter:false,
        filterSelection:[]
      }

      componentDidMount()
      {
        this.listTransactions();
        this.getProductFilters();
      }


      getProductFilters=async()=>{
            const server_reponse = await ajaxProduct.categoryProductListing();
            if(server_reponse.status==="OK")
            {
                this.setState({
                    productFilter:server_reponse.details
                }, ()=>{
                    const selection=[];
                    
                        this.state.productFilter.map((cat, catKey)=>
                            selection.push(cat.category.id)
                        )
                    
                    this.setState({
                        filterSelection:selection
                    })
                })
            }
      }

      listTransactions=()=>{

        this.setState({
          transactionList:false,
          metaData:false
        }, async()=>{

          const server_response=await ajaxProduct.filterProductTransactions(this.state.page, this.state.filterSelection);
         
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta
             }, ()=>this.paginate())

          }else{
            this.setState({
                transactionList:"404"
            })
          }

        })
       
          
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }

      onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }


      onClickFilter=(filter, title)=>{
        this.setState({
          filter:filter,
          transactionList:false,
          metaData:false,
          pagination:false,
          page:1,
          title:title
        }, ()=>this.listTransactions())
      }


      onCheckFilter=(value)=>{
          const currentSelection =this.state.filterSelection;
          for(var i=0; i<currentSelection.length; i++)
          {
                if(currentSelection[i]===value)
                {
                    //remove value and return
                    currentSelection.splice(i, 1);
                    this.setState({filterSelection:currentSelection})
                    return true;
                }
          }

          //value not found
          currentSelection.push(value);
          this.setState({filterSelection:currentSelection})
          return true;

      }

      

//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  onClickTransaction=(record)=>{
    this.setState({
        mdlTransaction:false
    }, ()=>{
        this.setState({
            mdlTransaction:<PopTransactionDetails record={record}/>
        }, ()=>{
            functions.openPopup('mdl-transaction-details');
        })
    })
}



RenderFilter=()=>{
    const productFilter = this.state.productFilter;
    const selection=this.state.filterSelection;
    return <div className="card custom-card">
    <div className="card-header custom-card-header">
        <h6 className="card-title mb-0">Categories & Fliters</h6>
    </div>
    <div className="card-body h-100">
        {!productFilter && <ActivityLoader/>}
        {productFilter && productFilter!=="404" && <>
            {productFilter.map((item, key)=>
            <div key={key} className="custom-checkbox custom-control mb-2">
                <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id={"checkbox-"+key+1} onClick={()=>this.onCheckFilter(item.category.id)} checked={selection.includes(item.category.id)?true:false}/>
                <label for={"checkbox-"+key+1} className="custom-control-label">{item.category.local_category + " (" + item.category.category_name + ")"}</label>
            </div>
           
            )}
          
          
          <Link to="#" class="btn ripple btn-light mt-3" onClick={this.listTransactions}>Apply Filter</Link>

        </>}

</div>
</div>
}


    
    render(){
        const list=this.state.transactionList
       
          return (
            <div className="page">
              {this.state.mdlTransaction}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="All Products"
                     previousPageTitle="Products"
                     back="/products"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-md-09 col-xl-9 col-lg-9">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <div>
                              <h6 className="card-title mb-1">{this.state.title}</h6>
                              <p className="text-muted card-sub-title">All products/bills transactions - in order of the most current</p>
                            </div>


                              
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">
                                   {/* <FilterAllLoans onClick={this.onClickFilter}/>
                                   <FilterBusinessLoans onClick={this.onClickFilter}/>
                                    <FilterPersonalLoans onClick={this.onClickFilter}/>
                                    <FilterDefaultedLoans onClick={this.onClickFilter}/>
                                    <FilterOutstandingLoans onClick={this.onClickFilter}/> */}

                                </div>
                            </div>
                            </div>


                                <div className="table-responsive overflow-auto h-standard">
                                    <table className="table table-bordered text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>Transaction Time</th>
                                                <th>Details</th>
                                                <th>Transactor Details</th>
                                                <th>Amount</th>
                                            
                                                <th>trans</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {list && list!=="404" && list.map((item, key)=>
                                                <tr 
                                                className="hot-tr cursor-pointer"
                                                key={key} title={item.description} style={{cursor:'default'}}
                                                onClick={()=>this.onClickTransaction(item)}
                                                >
                                                <td>
                                                    {item.trans_date.short_date}
                                                    <br/><SilentText text={item.trans_date.time}/>

                                                </td>
                                                <td>
                                                    {item.product.name}
                                                    <br/><SilentText text={item.product.local_category + ' - ' + item.beneficiary}/>
                                                    
                                                </td>
                                                <td>
                                                    {item.user_info.username}
                                                    <br/><SilentText text={item.user_info.first_name + ' ' + item.user_info.last_name}/>
                                                    <SilentText text={ " - " + item.user_info.role_name}/>

                                                </td>
                                                <td>
                                                    {item.amount.amount_c}
                                                    <br/><SilentText text="UGX"/>
                                                </td>
                                                <td>
                                                { item.status_id*1==3 && <PillTag title={item.status}/>}
                                                { item.status_id*1==2 &&<PillTag type={2} title={item.status}/>}
                                                { item.status_id*1==1 && <PillTag type={4} title={item.status}/>}
                                                { item.status_id*1==0 && <PillTag type={5} title={item.status}/>}
                                                </td>

                                            </tr>
                                                )}	
                                            
                                        </tbody>
                                    </table>
                                    {this.state.metaData && this.state.pagination}
                                    <br/>
                                    {!list && <ActivityLoader/>}

                                </div>
                          
                          
                          </div>
                        </div>
					

                          
                      </div>

                      <div className="col-lg-3 col-md-12">
							<this.RenderFilter/>
                        </div>

                      
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default ListPurchasesPage;
  
import React from 'react';
import ajax from '../ajax';
import functions from '../functions';
import { Link } from 'react-router-dom';


class UpcomingLoans extends React.Component {
  
    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        percentUnpaid:0,
        totalUnpaid:0,
        totalLoans:0,

        totalTodoList:0,
        percentTodoList:0,
        totalUnpaidTodoList:0,

        totalPaidToday:0,
        percentPaidToday:0,
        totalUnpaidToday:0,

        totalPaidWeekly:0,
        percentPaidWeekly:0,
        totalUnpaidWeekly:0
        
       
    }

    componentDidMount()
    {
        this.readUser();
        this.timerUnpaid = setTimeout(this.func=async()=> {
            await this.countUnpaidLoans();
            this.timerUnpaid = setTimeout(this.func, 2000); // (*)
          }, 2000);

          this.timerTodoList = setTimeout(this.func2=async()=> {
            await this.countTodoListLoans();
            this.timerTodoList = setTimeout(this.func2, 2000); // (*)
          }, 2000);


          this.timerPaidToday = setTimeout(this.func3=async()=> {
            await this.countPaidLoansToday();
            this.timerPaidToday = setTimeout(this.func3, 2000); // (*)
          }, 2000);

          this.timerPaidWeekly = setTimeout(this.func4=async()=> {
            await this.countPaidLoansWeekly();
            this.timerPaidWeekly = setTimeout(this.func4, 2000); // (*)
          }, 2000);


    }

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }

    componentWillUnmount()
    {
        clearTimeout(this.timerUnpaid);
        clearTimeout(this.timerTodoList);
        clearTimeout(this.timerPaidToday);
        clearTimeout(this.timerPaidWeekly);
    }

    countUnpaidLoans=async()=>
    {
        const server_reponse=await ajax.getLoanStatisticsUnpaid();
        if(server_reponse.status==="OK")
        {
            this.setState({
                percentUnpaid:server_reponse.details.total.percent,
                totalUnpaid:server_reponse.details.total.total_unpaid,
                totalLoans:server_reponse.details.total.total_loans
            })
        }
    }

    countTodoListLoans=async()=>
    {
        const server_reponse=await ajax.getLoanStatisticsTodoList();
        if(server_reponse.status==="OK")
        {
            this.setState({
                percentTodoList:server_reponse.details.total.percent,
                totalTodoList:server_reponse.details.total.total_unpaid,
                totalUnpaidTodoList:server_reponse.details.total.total_loans
            })
        }
    }

    countPaidLoansToday=async()=>
    {
        const server_reponse=await ajax.getLoanStatisticsPaidToday();
        if(server_reponse.status==="OK")
        {
            this.setState({
                percentPaidToday:server_reponse.details.total.percent,
                totalPaidToday:server_reponse.details.total.total_paid,
                totalUnpaidToday:server_reponse.details.total.total_loans
            })
        }
    }


    countPaidLoansWeekly=async()=>
    {
        const server_reponse=await ajax.getLoanStatisticsPaidWeekly();
        if(server_reponse.status==="OK")
        {
            this.setState({
                percentPaidWeekly:server_reponse.details.total.percent,
                totalPaidWeekly:server_reponse.details.total.total_paid,
                totalUnpaidWeekly:server_reponse.details.total.total_loans
            })
        }
    }

  render(){

    const accessInfo=this.state.accessInfo
    
      return (

        <div className="row">
       {accessInfo &&  <div className="col-md-12">
       {functions.findInObject(accessInfo, "LOAN0003-2") &&<div className="card custom-card">
                <div className="row">

                   <div className="col-xl-4 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                   <div className="card-body text-center">
                            <h6 className="mb-0">Total Defaulted Loans</h6>
                            <h2 className="mb-1 mt-2 number-font"><span className="counter">{this.state.percentUnpaid}</span>%</h2>
                            <p className="mb-0 text-muted">
                                <Link to="/outstanding-loans">
                                <span className="mb-0 text-danger fs-13 ml-1">
                                    <i className="fe fe-arrow-down"></i>{this.state.totalUnpaid}
                                </span> unpaid of {this.state.totalLoans} loans
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                    <div className="card-body text-center">
                            <h6 className="mb-0">Todo List Loans</h6>
                            <h2 className="mb-1 mt-2 number-font"><span className="counter">{this.state.percentTodoList}</span>%</h2>
                            <p className="mb-0 text-muted">
                                <Link to="/dashboard/todolist">
                                <span className="mb-0 text-danger fs-13 ml-1">
                                <i className="fe fe-arrow-down"></i> {this.state.totalTodoList} </span> 
                                    filtered out of {this.state.totalUnpaidTodoList} loans
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                            <h6 className="mb-0">Daily Loan Recovery</h6>
                            <h2 className="mb-1 mt-2 number-font"><span className="counter">{this.state.percentPaidToday}</span>%</h2>
                            <p className="mb-0 text-muted"><span className="mb-0 text-success fs-13 ml-1"><i className="fe fe-arrow-up"></i> {this.state.totalPaidToday}</span> recovered of {this.state.totalUnpaidToday} loans</p>
                        </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0">
                    <div className="card-body text-center">
                            <h6 className="mb-0">Weekly Loan Recovery</h6>
                            <h2 className="mb-1 mt-2 number-font"><span className="counter">{this.state.percentPaidWeekly}</span>%</h2>
                            <p className="mb-0 text-muted"><span className="mb-0 text-success fs-13 ml-1"><i className="fe fe-arrow-up"></i> {this.state.totalPaidWeekly}</span> recovered of {this.state.totalUnpaidWeekly} loans</p>
                        </div>
                    </div> */}
                </div>
            </div>}
        </div>}
    </div>


       
      );
    
 
  }
}

export default UpcomingLoans;
import React, { Component } from "react";
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import ClosedTicketsTable from '../../Components/tickets/ClosedTicketsTable'


class ClosedTickets extends Component {
    render() {
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar back={() => this.props.history.goBack()} />
                    <div className="container-fluid">
                        <PageHeader
                            title="Closed Tickets"
                            previousPageTitle="Dashboard"
                        />

                        <div className="row">
                            <ClosedTicketsTable />
                        </div>


                    </div>
                </div>
                <RightSideBar />
                <Footer />
            </div>
        )
    }
}

export default ClosedTickets;

import React from 'react'
import { Link } from 'react-router-dom'
import ajax from '../../ajax';
import functions from '../../functions';

class LeftSideBar extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    sideBarInitiated: true,
    activeLocationPath: window.location.pathname,
    activeRoot: ''
  }

  componentDidMount() {

    const pathSections = this.state.activeLocationPath.split("/");
    this.setState({
      activeRoot: "/" + pathSections[1]
    })
  }




  toggleMenu = (e) => {
    e.preventDefault();
    if (e.target.parentElement.nodeName === "A") {
      //console.log(e.target.parentElement.offsetParent)

      functions.removeClassFromElementSiblings(e.target.parentElement.offsetParent, 'show')
      e.target.parentElement.offsetParent.classList.toggle('show');
    } else {
      functions.removeClassFromElementSiblings(e.target.parentElement, 'show')
      e.target.parentElement.classList.toggle('show');
    }

  }




  toggleSideBar = () => {
    let element = document.getElementsByTagName('body');
    if (window.matchMedia('(min-width: 768px)').matches) {
      element[0].classList.toggle('main-sidebar-hide');
    } else {
      element[0].classList.toggle('main-sidebar-show');
    }
  }

  openSideBar = () => {

    const controlElement = document.getElementsByClassName('main-sidebar-hide');
    if (controlElement.length > 0) {

      let element = document.getElementsByTagName('body');
      element[0].classList.add('main-sidebar-open')
      this.setState({
        sideBarInitiated: true
      })
    }

  }


  closeSideBar = () => {

    const controlElement = document.getElementsByClassName('main-sidebar-hide');
    if (controlElement.length > 0) {
      let element = document.getElementsByTagName('body');
      element[0].classList.remove('main-sidebar-open')
      this.setState({
        sideBarInitiated: false
      })

    }

  }

  logos = () => {
    return (
      <a replace className="main-logo" to="#">
        <img src={process.env.PUBLIC_URL + "../../images/logo.png"} className="header-brand-img desktop-logo" alt="logo" />
        <img src={process.env.PUBLIC_URL + "../../images/qp_b.png"} className="header-brand-img icon-logo" alt="logo" />
        <img src={process.env.PUBLIC_URL + "../../images/logo.png"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
        <img src={process.env.PUBLIC_URL + "../../images/qp_b.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
      </a>
    )
  }


                  




  render() {
    const accessInfo = functions.readPermissions();
    const activeRoot = this.state.activeRoot;
    const path = this.state.activeLocationPath;

    return (


      <div className="main-sidebar main-sidebar-sticky side-menu"
        onMouseEnter={this.openSideBar}
        onMouseLeave={this.closeSideBar}
      >
        <div className="sidemenu-logo">
          <div className="touch-close-menu">
            <i className="fas fa-align-left custom-menu-icon" onClick={this.toggleSideBar}></i>
          </div>
          <this.logos />
        </div>

        <div className="main-sidebar-body">
          <ul className="nav">
            <li className="nav-label">Dashboard</li>
            {functions.findInObject(accessInfo, "LOAN0000-2") &&
              <li
                className={`nav-item show ${(activeRoot === '/dashboard') && 'active'}`}
              >
                <Link className="nav-link" to="/dashboard"><i className="fas fa-hands-helping"></i><span className="sidemenu-label">Loans</span></Link>
              </li>}
            {functions.findInObject(accessInfo, "CUST000-2") &&
              <li
                className={`nav-item show ${(activeRoot === '/customers') && 'active'}`}
              >
                <Link className="nav-link" to="/customers"><i className="fas fa-users"></i><span className="sidemenu-label">Customers</span></Link>
              </li>}
            {functions.findInObject(accessInfo, "AGENT000-2") &&
              <li
                className={`nav-item show ${(activeRoot === '/agents') && 'active'}`}
              >
                <Link className="nav-link" to="/agents"><i className="fas fa-globe"></i><span className="sidemenu-label">Agents</span></Link>
              </li>}
            {functions.findInObject(accessInfo, "WALLET0001-2") &&
              <li
                className={`nav-item show ${(activeRoot === '/finance') && 'active'}`}
              >
                <Link className="nav-link" to="/finance"><i className="fas fa-chart-bar"></i><span className="sidemenu-label">Finance</span></Link>
              </li>}



            {functions.findInObject(accessInfo, "DASHBOARD-PRODUCTS") &&
              <li
                className={`nav-item show ${(activeRoot === '/products') && 'active'}`}
              >
                <Link className="nav-link" to="/products"><i className="fas fa-gem"></i><span className="sidemenu-label">Products</span></Link>
              </li>}


            {functions.findInObject(accessInfo, "DASHBOARD-CASHROUND") &&
              <li
                className={`nav-item show ${(activeRoot === '/cashround') && 'active'}`}
              >
                <Link className="nav-link" to="/cashround"><i className="fe fe-aperture"></i><span className="sidemenu-label">Cash Rounds</span></Link>
              </li>}


          
              <li
                className={`nav-item show ${activeRoot === "/tickets" && "active"
                  }`}
              >
                <Link className="nav-link" to="/tickets">
                  <i className="fa fa-wrench"></i>
                  <span className="sidemenu-label">Tickets</span>
                </Link>
              </li>
            



            <li className="nav-label">Applications</li>


            {functions.findInObject(accessInfo, "LOAN0000-2") &&
              <li 
                className={`nav-item ${activeRoot === '/dashboard' && 'active show'}`}
                onClick={this.toggleMenu}>
                <a className="nav-link with-sub" to="#">
                  <i className="fas fa-hands-helping"></i>
                  <span className="sidemenu-label">Loans</span>
                  <i className="angle fe fe-chevron-right"></i>
                </a>
                <ul className="nav-sub">
                  {functions.findInObject(accessInfo, "LOAN-PG-0001-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/loan-packages">Loan Packages</Link>
                  </li>}

                  {/* {functions.findInObject(accessInfo, "LOAN-PG-0002-2") && <li className="nav-sub-item">
                                <Link replace className="nav-sub-link" to="/outstanding-loans">Outstanding Loans</Link>
                            </li>} */}
                  {functions.findInObject(accessInfo, "LOAN-PG-0003-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/all-loans">Loan History</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "LOAN-PG-0004-2") &&
                    <li
                      className={`nav-sub-item ${path === '/dashboard/todolist' && 'active'}`}>
                      <Link replace className={`nav-sub-link `} to="/dashboard/todolist">Todo List Loans</Link>
                    </li>}
                </ul>

              </li>}



            {functions.findInObject(accessInfo, "AGENT000-2") &&
              <li className="nav-item" onClick={this.toggleMenu}>
                <a className="nav-link with-sub" to="#">
                  <i className="fas fa-globe"></i>
                  <span className="sidemenu-label">Agents</span><i className="angle fe fe-chevron-right"></i>
                </a>
                <ul className="nav-sub">
                  {functions.findInObject(accessInfo, "AGENT-PG-0001-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/agent-records">Agent Records</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "AGENT-PG-0002-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/agent-tokens">Activation Tokens</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "AGENT-PG-0003-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/agent-performance">Agent Performance</Link>
                  </li>}


                </ul>
              </li>}



            {functions.findInObject(accessInfo, "TRANS0000-2") &&
              <li className="nav-item" onClick={this.toggleMenu}>
                <a className="nav-link with-sub" to="#">
                  <i className="fas fa-history"></i>
                  <span className="sidemenu-label">Transactions</span><i className="angle fe fe-chevron-right"></i>
                </a>
                <ul className="nav-sub">
                  {functions.findInObject(accessInfo, "TRANS0001-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/float-transactions">Capital Float</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "TRANS0002-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/business-float-transactions">Business Float</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "TRANS0003-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/wallet-transactions">Wallet</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "TRANS0004-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/reserve-transactions">Company Reserve</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "TRANS0005-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/commission-transactions">Commission</Link>
                  </li>}

                </ul>
              </li>}


            {functions.findInObject(accessInfo, "STT0000-2") &&
              <li className="nav-item" onClick={this.toggleMenu}>
                <a className="nav-link with-sub" to="#">
                  <i className="fas fa-exchange-alt"></i>
                  <span className="sidemenu-label">Statements</span><i className="angle fe fe-chevron-right"></i>
                </a>
                <ul className="nav-sub">
                  {functions.findInObject(accessInfo, "STT0001-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/float-statement">Capital Float</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "STT0002-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/business-float-statement">Business Float</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "STT0003-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/wallet-statement">Wallet</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "STT0004-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/reserve-statement">Company Reserve</Link>
                  </li>}
                  {functions.findInObject(accessInfo, "STT0005-2") && <li className="nav-sub-item">
                    <Link replace className="nav-sub-link" to="/commission-statement">Commission</Link>
                  </li>}
                </ul>
              </li>}



          </ul>
        </div>
      </div>


    )
  }

}

export default LeftSideBar
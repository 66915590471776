import React from 'react';
import $ from 'jquery';
import PINLocation from '../Common/PINLocation';
import ajax from '../../ajax';
import ActivityLoader from '../Common/ActivityLoader';
import Toast from '../Common/Toast';


class PopUserLocation extends React.Component {
  state={
    userId:this.props.userId,
    position:false,
    center:false,
    toast:false
  }

  componentDidMount()
  {
    this.getLocation();
  }


  getLocation=async()=>{

    const server_response=await ajax.getUserLastLocation(this.state.userId);
    if(server_response.status==="OK")
    {
      this.setState({
        position:[{
          latitude:server_response.details.latitude,
          longitude:server_response.details.longitude,
          description:server_response.details.first_name + ' ' + server_response.details.last_name
        }],
        center:{
          latitude:server_response.details.latitude,
          longitude:server_response.details.longitude,
        }
      })
    }else{

      this.setState({
        toast:<Toast 
            message={server_response.details[0]} 
            type={3} onClose={()=>this.resetToast()}/>
      })

    }

  }
  
  resetToast=()=>{
    this.setState({
      toast:false
    })
  }
 
 
  render(){
    
      return (
        <div className="modal" id="pop-user-location">
           {this.state.toast}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
                <div className="modal-header">
                        <h6 className="modal-title">User Location</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-bod">
                       {this.state.position && <PINLocation coords={this.state.position} center={this.state.center}/> || <ActivityLoader/>}
                       
                    </div>
            </div>
        </div>
    </div>
      )}
}

export default PopUserLocation;

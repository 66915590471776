import React, { Component } from 'react'
import ajax from '../../ajax';

class TotalTickets extends Component {
    state = {
        statistics: false
    }

    async componentDidMount() {
        await this.getTicketStatistics();
        this.timer = setTimeout(this.func = async () => {
            await this.getTicketStatistics();
            this.timer = setTimeout(this.func, 2000); // (*)
        }, 2000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    getTicketStatistics = async () => {
        const server_response = await ajax.ticketStatistics();
        if (server_response.status == 'OK') {
            this.setState({ statistics: server_response.details })
            return;
        }

        this.setState({ statistics: '404' })

    }

    render() {
        const { statistics } = this.state;
        return (
            <div className="row">
                <div className="col-sm-6 col-md-6 col-xl-3">
                    <div className="card custom-card">
                        <div className="card-body text-center">
                            <div className="icon-service bg-info-transparent rounded-circle text-primary">
                                <i className="fe fe-map"></i>
                            </div>
                            <p className="mb-1 text-muted">Total Tickets Today</p>
                            <h3 className="mb-0">{statistics && statistics != '404' ? statistics.today.total_c : "...."}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-xl-3">
                    <div className="card custom-card">
                        <div className="card-body text-center">
                            <div className="icon-service bg-info-transparent rounded-circle text-secondary">
                                <i className="fe fe-map"></i>
                            </div>
                            <p className="mb-1 text-muted">Total Open Tickets</p>
                            <h3 className="mb-0">{statistics && statistics != '404' ? statistics.open.total_c : "...."}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-xl-3">
                    <div className="card custom-card">
                        <div className="card-body text-center">
                            <div className="icon-service bg-info-transparent rounded-circle text-info">
                                <i className="fe fe-map"></i>
                            </div>
                            <p className="mb-1 text-muted">Total Closed Tickets</p>
                            <h3 className="mb-0">{statistics && statistics != '404' ? statistics.closed.total_c : "...."}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-xl-3">
                    <div className="card custom-card">
                        <div className="card-body text-center">
                            <div className="icon-service bg-info-transparent rounded-circle text-success">
                                <i className="fe fe-command"></i>
                            </div>
                            <p className="mb-1 text-muted">Total Departments</p>
                            <h3 className="mb-0">{statistics && statistics != '404' ? statistics.departments.total_c : "...."}</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TotalTickets;

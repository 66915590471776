import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';

class AgentRanking extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        ranking:false
       
    }

  async componentDidMount()
  {
    await this.requestRecord();
    this.timer = setTimeout(this.func=async()=> {
      await this.requestRecord();
      this.timer = setTimeout(this.func, 10000); // (*)
    }, 10000);
  }

  componentWillUnmount()
  {
    clearTimeout(this.timer)
  }


   

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }


  requestRecord=async()=>{
    const server_response=await ajax.topAgentRanking(this.state.userId);
    if(server_response.status==="OK")
    {
      this.setState({
        ranking:server_response.details
      })
    }else{
        this.setState({
            ranking:'404'
          })
    }
  }


   
  render(){

    const list=this.state.ranking;
    
      return (
        <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6 className="card-title mb-1">Agent Performance</h6>
                    <p className="text-muted mb-0 card-sub-title">Top ranking Agents.</p>
                </div>
            </div>
            <div className="user-manager scroll-widget border-top">
                <div className="table-responsive">
                    <table className="table mg-b-0">
                        <tbody>
                           {list && list!=="404" && list.map((item, key)=>
                            <tr key={key}>
                                <td className="bd-t-0">
                                    <div className="main-img-user">
                                        <img alt="avatar" src={item.user_info.photo}/>
                                    </div>
                                </td>
                                <td className="bd-t-0">
                                    <h6 className="mg-b-0">{item.user_info.first_name + ' ' + item.user_info.last_name}</h6>
                                    <small className="tx-11 tx-gray-500">{item.user_info.phone}</small>
                                </td>
                                <td className="bd-t-0">
                                    <h6 className="mg-b-0 font-weight-bold">{item.total_points.total_c}</h6>
                                    <small className="tx-11 tx-gray-500">Loan Points</small>
                                </td>
                            </tr>)}
                           
                        </tbody>
                    </table>
                
                
                {!this.state.ranking && <ActivityLoader/>}
                
                </div>
            </div>
        </div>

      )
    
 
  }
}

export default AgentRanking;
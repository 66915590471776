import React, { Component } from "react";
import ajax from "../../ajax";
import { Link } from "react-router-dom";
import ActivityLoader from "../Common/ActivityLoader";

class OpenTicketsTable extends Component {
  state = {
    loading: false,
    open: false,
    message: false,
  };

  async componentDidMount() {
    await this.listOpenTickets();
    this.timer = setTimeout(this.func = async () => {
      await this.listOpenTickets();
      this.timer = setTimeout(this.func, 2000); // (*)
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  listOpenTickets = async () => {

    const server_response = await ajax.listOpenTickets();
    if (server_response.status == 'OK') {
      this.setState({ open: server_response.details });
      return;
    }

    this.setState({ open: '404' });


  };

  updateTicketStatus = async (id) => {
    const server_response = await ajax.updateTicketStatus(id, '1');

    if (server_response.status == 'OK') {
      this.setState({ open: false }, () => this.listOpenTickets())
    }
  }

  render() {
    const { open, message } = this.state;
    // console.log(open)
    return (
      <div className="col-sm-12 col-xl-12 col-lg-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-1">Open support tickets</h6>
              <p className="text-muted card-sub-title">
                The support tickets that still needs attendance
              </p>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>#No</th>
                    <th>Title</th>
                    <th>Department</th>
                    <th>Customer</th>

                    <th>status</th>

                    <th>Date Created</th>
                    <th>Close</th>
                  </tr>
                </thead>
                <tbody>
                  {open &&
                    open !== "404" &&
                    open.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <Link to={
                            {
                              pathname: '/ticket/info',
                              state: { ticket_id: item.ticket_id }
                            }
                          } >{item.title}</Link>
                        </td>
                        <td>{item.department}</td>
                        <td>
                          {item.first_name + " " + item.last_name}
                          <br />
                          {item.contact}
                        </td>

                        <td>
                          <span className="badge badge-primary">
                            {item.status == "0" ? "open" : "closed"}
                          </span>
                        </td>
                        <td>{item.date}</td>
                        <td onClick={() => {
                          if (window.confirm("Are you sure yo want to close this ticket ?")) {
                            this.updateTicketStatus(item.ticket_id)
                          }
                        }}>
                          <span className="btn btn-sm btn-danger">
                            {"Close Ticket"}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {open && open !== "404" && message}

              {!this.state.open && <ActivityLoader />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OpenTicketsTable;

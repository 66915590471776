import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PopVerifyAction from '../Common/PopVerifyAction';
import SelectSearch from '../Common/SelectSearch';
import Toast from '../Common/Toast';


class PopReserveApproval extends React.Component {
  state={
      loading:false,
      transactionRecord:this.props.record,
      verification:false,
      collectAccounts:false,
      accountId:'',
      toast:false
  }


  componentDidMount()
  {
      this.getCollectionAccounts()
  }
 

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  
  onClickCancel=()=>{
          this.setState({
              verification:<PopVerifyAction
                title="Account Authentication"
                description="Enter your account password to cancel this requisition"
                requestId={this.state.transactionRecord.request_id}
                action={"CANCEL-RESERVE-REQUEST"}
              />
          }, ()=>{
            functions.closePopup('mdl-reserve-approval');
            functions.openPopup('pop-verify-action');

          })
  }

  onClickApprove=()=>{
    this.setState({
        verification:<PopVerifyAction
          title="Account Authentication"
          description="Enter your account password to approve requisition"
          requestId={this.state.transactionRecord.request_id}
          action={"APPROVE-RESERVE-REQUEST"}
        />
    }, ()=>{
      functions.closePopup('mdl-reserve-approval');
      functions.openPopup('pop-verify-action');

    })
}

onClickProcess=()=>{
    const {accountId}=this.state
    if(accountId.length>0)
    {
        this.setState({
            verification:<PopVerifyAction
            title="Account Authentication"
            description="Enter your account password to process requisition"
            requestId={this.state.transactionRecord.request_id}
            action={"PROCESS-RESERVE-REQUEST"}
            accountId={this.state.accountId}
            />
        }, ()=>{
        functions.closePopup('mdl-reserve-approval');
        functions.openPopup('pop-verify-action');

        })
    }else{
        this.setState({
            toast:<Toast 
                    message={"Select expense account and try again"} 
                    type={2} onClose={()=>this.resetToast()}/>
        })
    }
}


getCollectionAccounts=async()=>{
    const server_response=await ajax.getCollectionAccounts();
    if(server_response.status==="OK")
    {
        this.setState({
            collectAccounts:server_response.details
        })
    }
}

onChangeAccount = (selectedOption) => {
    this.setState({
        accountId: selectedOption.value
    })
  }

  resetToast=()=>{
    this.setState({
        toast:false
    })
}


  render(){
    const transactionList=this.state.transactionRecord;
    const accountList=this.state.collectAccounts;
    const optionsAccount=[];
    if(accountList && accountList!=="404")
    {
        accountList.map((item, key)=>
        optionsAccount.push({label:item.account_name, value:item.account_id})
        )
    }
      return (
      <>
        {this.state.verification}
        
        <div className="modal" id="mdl-reserve-approval">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Requisition Approval</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    {this.state.toast}
                </div>
                <div className="modal-body">



                            {transactionList && transactionList!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                       
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Amount</label> 
                                                <span className="tx-medium">{transactionList.amount.amount_c}</span>
                                            </div>
                                            <div>
                                                <label>Status</label> 
                                                <span className="tx-medium">{transactionList.status.status_name}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Request Date</label> 
                                                <span className="tx-medium">{transactionList.request_date.long_date}</span>
                                            </div>
                                           
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Beneficiary Account</label> 
                                                <span className="tx-medium">{transactionList.receiver.first_name + ' ' + transactionList.receiver.last_name + ' - '+ transactionList.receiver.phone}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                        

                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Requested By</label> 
                                                <span className="tx-medium"> 
                                                {transactionList.requested_by.first_name + ' ' + transactionList.requested_by.last_name + ' - '+ transactionList.requested_by.username}
                                                </span>
                                            </div>

                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Reason</label> 
                                                <span className="tx-medium"> 
                                                {transactionList.reason}
                                                </span>
                                            </div>

                                            </div>
                                        </div>



                                        <div className="media">
                                            <div className="media-body">

                                            <div>
                                                <label>Approved By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.approved_by?transactionList.approved_by.first_name + ' ' + transactionList.approved_by.last_name:'Not Approved'}
                                                </span>
                                            </div>

                                            <div>
                                                <label>Processed By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.processed_by?transactionList.processed_by.first_name + ' ' + transactionList.processed_by.last_name:'Not Processed'}
                                                </span>
                                            </div>
                                    
                                            </div>
                                        </div>


                                        {transactionList.status.status_id*1===3 && <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Expense Account</label> 
                                                
                                                <SelectSearch
                                                    label={false} 
                                                    onChange={()=>this.onChangeAccount}
                                                    options={optionsAccount} 
                                                    placeholder={"Select Expense account..."}
                                                    />   

                                            </div>

                                            </div>
                                        </div>}

                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}


                              
                            
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-danger" type="button" value="Cancel Request" onClick={this.onClickCancel}/>
                    {transactionList.status.status_id*1===1 &&
                    <input 
                        className="btn ripple btn-primary" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickApprove}/>}

                    {transactionList.status.status_id*1===3 &&
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickProcess}/>}

                   
                </div>
            </div>
        </div>
    </div>
    </>
     )
    
 
  }
}

export default PopReserveApproval;
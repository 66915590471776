import React, { Component } from "react";
import ajax from "../../ajax";
import { Link } from "react-router-dom";
import ActivityLoader from "../Common/ActivityLoader";

class ClosedTicketsTable extends Component {
  state = {
    loading: false,
    closed: false,
    message: false,
  };
  componentDidMount() {
    this.listClosedTickets();
  }

  listClosedTickets = async () => {
    this.setState({ loading: true });

    const server_response = await ajax.listClosedTickets();
    this.setState({ loading: false });

    if (server_response.status == "OK") {
      this.setState({
        closed: server_response.details,
      });
    } else {
      this.setState({
        closed: "404",
        message: server_response.message,
      });
    }
  };

  updateTicketStatus = async (id) => {
    const server_response = await ajax.updateTicketStatus(id, '0');

    if (server_response.status == 'OK') {
      this.setState({ closed: false }, () => this.listClosedTickets())
    }
  }

  render() {
    const { closed, message } = this.state;
    return (
      <div className="col-sm-12 col-xl-12 col-lg-12">
        <div className="card custom-card">
          <div className="card-body">

            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>#No</th>
                    <th>Title</th>
                    <th>Department</th>
                    <th>Customer</th>

                    <th>status</th>

                    <th>Date Created</th>
                    {/* <th>Close</th> */}
                  </tr>
                </thead>
                <tbody>
                  {closed &&
                    closed !== "404" &&
                    closed.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <Link to={
                            {
                              pathname: '/ticket/info',
                              state: { ticket_id: item.ticket_id }
                            }
                          } >{item.title}</Link>
                        </td>
                        <td>{item.department}</td>
                        <td>
                          {item.first_name + " " + item.last_name}
                          <br />
                          {item.contact}
                        </td>

                        <td>
                          <span className="badge badge-primary">
                            {item.status == "0" ? "open" : "closed"}
                          </span>
                        </td>
                        <td>{item.date}</td>
                        {/* <td onClick={() => this.updateTicketStatus(item.ticket_id)}>
                          <span className="btn btn-sm btn-success">
                            {"ReOpen Ticket"}
                          </span>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              {closed && closed == "404" && message}

              {!this.state.closed && <ActivityLoader />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClosedTicketsTable;
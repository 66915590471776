import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import PageNav from '../Components/Common/PageNav'
import SilentText from '../Components/Common/SilentText'
import PillTag from '../Components/Common/PillTag'
import MdlLoanStatement from '../Components/MdlLoanStatement'
import FilterBar from '../Components/Common/FilterBar'
class LoanTodoList extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        page:1,
        pagination:false,
        loanRecord:'',
        selectedLoanId:'',
        statement:false,
        loanTypes:false,
        filter:false
      }

      componentDidMount()
      {
        this.listTransactions();
        this.getLoanTypes();
      }


      listTransactions=async()=>{

        if(this.state.filter)
        {
          const filter=this.state.filter;
          var server_response=await ajax.filterTodoListLoans(
            this.state.page,
            filter.dateFrom,
            filter.dateTo,
            filter.selectedValue,
            filter.keyword
          );
        }else{
          var server_response = await ajax.listTodoListLoans(this.state.page);

        }
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta
             }, ()=>this.paginate())

          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


    
      refresh=()=>{
        this.setState({
          page:1,
          transactionList:false

        }, ()=>{
          this.listTransactions();
        })
      
      }


      onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }

        onClickLoan=(record)=>{
          this.setState({
            statement:false
          }, ()=>{
            this.setState({
              statement:<MdlLoanStatement loan={record}/>
            }, ()=>{
               functions.openPopup('modal_loan')
            })
          })
        }
  
  
      
      
//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


  getLoanTypes=async()=>{
      const server_response=await ajax.getLoanTypes();
      console.log(server_response)
      if(server_response.status==="OK")
      {
        this.setState({
          loanTypes:server_response.details
        })
      }
  }


    onFilter=async(filter)=>{
        this.setState({filter:filter, metaData:false, transactionList:false}, ()=>this.listTransactions())
    }
    resetFilter=()=>{
      this.setState({
        filter:false,
        metaData:false,
        transactionList:false
      }, ()=>this.listTransactions())
    }


    buttons=()=>{
      return(
          <>
    
              <Link to="#" className="btn ripple btn-light" onClick={this.onClickAddPayment}>
                  <i className="fas fa-file-excel"></i> Export
              </Link>
                  <a href="#" className="btn ripple btn-primary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                  </a>
            
            </>
          )
    }
  


    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;
        
        const loanTypeOptions=[];
        this.state.loanTypes && this.state.loanTypes.map((item, key)=>
          loanTypeOptions.push({title:item.type_name, value:item.type_id})
        );

          return (
            <div className="page">
              {this.state.statement}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                    title="Todo List Loans" 
                     previousPageTitle="Loans"
                     back="/dashboard"
                     buttons={<this.buttons/>} 
                  />


                <FilterBar 
                  selection={{title:'Loan Type', options:loanTypeOptions}}
                  onReset={this.resetFilter}
                  onConfirm={this.onFilter}
                  />

                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-1">Todo List Loans</h6>
                                <p className="text-muted card-sub-title">All quickpost loans approaching expiry and defaulted loan records</p>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>#No</th>
                                      <th>Loan ID</th>
                                      <th>Customer</th>
                                      <th>Agent</th>
                                      <th>Loan Date</th>
                                      <th>Deadline/Next Pay Day</th>
                                      <th>Princ(UGX)</th>
                                      <th>Interest(UGX)</th>
                                      <th>Balance(UGX)</th>
                                      <th>Expiry(Days)</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                  {list && list!=="404" && list.map((item, key)=>
                                  <tr key={key}
                                    className={`${this.state.selectedLoanId==item.loan_info.loan_id?'flag-row':''}`}
                                    onClick={()=>this.onSelectRecord(item.loan_info.loan_id)}>
                        
                                  <td>{metaData.offset_count + key+1}</td>
                                  <td>
                                      <Link 
                                        className="orange" 
                                        to="#"
                                        onClick={()=>this.onClickLoan(item.loan_info)}
                                        >
                                      {item.loan_info.loan_code}
                                      </Link><br/>
                                      <SilentText text={item.loan_info.package_info.loan_type.type_name}/>
                                  </td>
                                  <td>
                                      <Link className="orange" 
                                        to={
                                            {
                                              pathname:'/customer-profile',
                                              state:{customerId:item.loan_info.customer_info.user_id}
                                            }
                                          }>
                                      {item.loan_info.customer_info.first_name + " " + item.loan_info.customer_info.last_name}</Link><br/>
                                      <SilentText text={item.loan_info.customer_info.phone}/>
                                  </td>

                                  <td>
                                      <Link className="orange"
                                        to={
                                          {
                                            pathname:'/agent-profile',
                                            state:{agentId:item.loan_info.agent_info.user_id}
                                          }}
                                        >
                                          {item.loan_info.agent_info.first_name + ' ' + item.loan_info.agent_info.last_name}
                                        </Link>
                                        <br/><SilentText text={item.loan_info.agent_info.phone}/>

                                  </td>
                                 
                                <td>
                                  {item.loan_info.loan_date.short_date}
                                  <br/><SilentText text={item.loan_info.loan_date.time}/>

                                </td>
                                <td>
                                  {item.loan_info.package_info.loan_type.type_id=="2"?item.loan_info.particulars.next_payment_date.short_date:item.loan_info.deadline.short_date}
                                  <br/><SilentText text={item.loan_info.package_info.loan_type.type_id=="2"?"Next Pay Day at " + item.loan_info.particulars.next_payment_date.time:"Actual Deadline at "+ item.loan_info.deadline.time}/>
                                 </td>
                                <td>
                                  {item.loan_info.loan_principal.amount_c}
                                  <br/><SilentText text={item.loan_info.package_info.package_name}/>
                                </td>
                                <td>
                                    {item.loan_info.loan_interest.amount_c}
                                    <br/><SilentText text={item.loan_info.package_info.interest_rate + '% - ' + item.loan_info.package_info.duration + ' ' + item.loan_info.package_info.units}/>
                                  </td>
                                <td>
                                  {item.loan_info.loan_balance.amount_c}
                                  <br/><SilentText text={item.loan_info.package_info.loan_type.type_id=="2"?"Remit: " + item.loan_info.particulars.remittance.amount_c :'Unpaid'}/>
                                </td>
                                <td>
                                  {item.loan_info.timer_in_days*1 < 0 && <PillTag title={"Within " + (item.loan_info.timer_in_days*-1)}/>}
                                  {item.loan_info.timer_in_days*1 == 0 && <PillTag type={1} title={"Exp Today" }/>}
                                  {item.loan_info.timer_in_days*1>0 && <PillTag type={2} title={"Over by " + item.loan_info.timer_in_days}/>}

                                </td>
                                 
                              </tr>
                              )}	
                                    
                                  </tbody>
                                </table>
                                {this.state.metaData && this.state.pagination}
                                  {!this.state.transactionList && <ActivityLoader/>}

                                  

                              </div>
                            </div>
                          </div>
                      

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default LoanTodoList;
  
import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import {Line} from 'react-chartjs-2';


class WeeklySalesGraph extends React.Component {

    state={
        userId:functions.sessionGuard(),
        chartData:false,
        salesData:false,
        dataSections:false,
        borderColors:['rgba(113, 76, 190, 0.9)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.6)', 'rgba(228, 37, 40, 0.6)', 'rgba(228, 224, 37, 0.6)'],
        backgroundColors:['rgba(113, 76, 190, 0.5)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.8)', 'rgba(228, 37, 40, 0.8)', 'rgba(228, 224, 37, 0.8)']
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerGraph = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerGraph = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }

    componentWillUnmount() {
        clearTimeout(this.timerGraph)
    }
    

    fetchData=async()=>
    {
        const server_reponse=await ajax.analyseWeeklyProductSales();
        if(server_reponse.status==="OK")
        {
            this.setState({
                salesData:server_reponse.details,
                dataSections:server_reponse.details.sections
            }, ()=>{

                const days=[this.state.salesData.days[7]];//start with sunday
                for(var i=1; i<7; i++)
                {  
                    days.push(this.state.salesData.days[i]);
                }
                const chartData={labels:days, datasets:[]};


                for(var t=0; t<this.state.dataSections.length; t++)
                {

                    const sectionLabel=this.state.dataSections[t];
                    const rowData=[this.state.salesData[sectionLabel][7]['total']];//start with sunday
            
                    for(var i=1; i<7; i++)
                    {
                        rowData.push(this.state.salesData[sectionLabel][i]['total']);
                    }
                   
                    chartData.datasets.push({
                        label:sectionLabel,
                        data:rowData,
                        fill: true,
                        lineTension: 0.5,
                        borderWidth: 2,
                        backgroundColor:this.state.backgroundColors[t],
                        borderColor:this.state.borderColors[t]
                    })

                }//Ends for-data section
                
                this.setState({chartData:chartData})
            })
        }
    }
  
  
  render(){
    
      return (
        <div className="card custom-card">
        <div className="card-body">

        <div className="card-option d-flex">
            <div>
                <h6 className="card-title mb-1">Weekly Purchases</h6>
                <p className="text-muted card-sub-title">Overview of this week's product sales according to category</p>
            </div>
            <div className="card-option-title ml-auto">
                <div className="btn-group p-0">
                    <button 
                        className="btn btn-light btn-sm" 
                        type="button"
                        onClick={()=>this.props.onChangeView('WEEK')}
                        >Weekly</button>
                    <button 
                        className="btn btn-outline-light btn-sm" 
                        type="button"
                        onClick={()=>this.props.onChangeView('MONTH')}
                        >Monthly</button>
                </div>
            </div>
        </div>
        <div>


                    {this.state.chartData && this.state.chartData!=="404" && <Line
                        data={this.state.chartData }
                        height={90}
                        options={{
                            title:{
                            display:false,
                            //text:'Graph title',
                            fontSize:20
                            },
                            legend:{
                            display:true,
                            position:'top'
                            }
                            
                        }}
                        />}

                        {!this.state.chartData && <ActivityLoader/>}


        </div>
            

        </div>
    </div>

      )
    
 
  }
}

export default WeeklySalesGraph;
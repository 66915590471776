import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import TitleValueCard from '../Common/TitleValueCard';


class AgentSummary extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    agentId:this.props.agentId,
    loanStatistics:false
  }


  componentDidMount() {
    //this.getLoanStatistics(); 
  }
  


  // getLoanStatistics=async()=>{
	// 	const server_response=await ajax.clientPeriodicalLoanStatistics(this.state.agentId);
	// 	if(server_response.status==="OK")
	// 	{
	// 	   this.setState({
	// 		loanStatistics:server_response.details
	// 	   })

	// 	}
	// }

    
  render(){
    const loanInfo=this.props.loanStatistics;
    const agent=this.props.agentInfo.agent;
      return (
        
        // <div className="text-wrap">
        // <div className="example bg-white">
        //     <div className="btn-list">
        //         <button type="button" className="btn ripple btn-primary btn-sm">
        //             Loan Limit 
        //             <span className="badge badge-light ml-2">{"UGX." + agent.loan_limit.amount_c}</span>
        //         </button>
               
                
        //     </div>
        // </div>
        // </div>
        <>
          {!loanInfo && <ActivityLoader/>}
         {loanInfo && <>
            {/* <TitleValueCard colSize={4} href="#" title="Total Loans (UGX)" value={loanInfo.total_loan.amount_c} icon="fas fa-university" color="icon-service rounded-circle bg-primary-transparent text-primary" /> */}
            {/* <TitleValueCard colSize={4} href="#" title="Loan Repayments (UGX)" value={loanInfo.total_paid.amount_c} icon="fas fa-university" color="icon-service rounded-circle bg-success-transparent text-success" /> */}
            <TitleValueCard colSize={4} href="#" title="Loan Limit" value={agent.loan_limit.amount_c} icon="fas fa-tint" color="icon-service rounded-circle bg-primary-transparent text-primary" />
            <TitleValueCard colSize={4} href={
														{
															pathname:'/customer-records',
															state:{agent_id:this.props.agentInfo.user.user_id}
														}} title="Total Customers" value={agent.total_customers.total_c} icon="fa fa-users" color="icon-service rounded-circle bg-success-transparent text-success" />
            {/* <TitleValueCard colSize={4} href="#" title="Loan Balances (UGX)" value={loanInfo.balance.amount_c} icon="fas fa-university" color="icon-service rounded-circle bg-danger-transparent text-danger" /> */}
            <TitleValueCard colSize={4} href="#" title="Loan Performance" value={loanInfo.performance + '%'} icon="fas fa-chart-line" color="icon-service rounded-circle bg-secondary-transparent text-secondary" />

          </>}
        </>
      
          
      );
    
 
  }
}

export default AgentSummary;
import React from 'react';
import TextInput from './Common/TextInput';
import ajax from '../ajax';
import AlertSuccess from './Common/AlertSuccess';
import AlertError from './Common/AlertError';
import dictionary from '../dictionary';


class MdlNewChargeWithdraw extends React.Component {
  state={
      info:'',
      minAmount:'',
      maxAmount:'',
      charges:''
  }


  onChangeMin = (event) => {
    this.setState({minAmount: event.target.value});
  }

  onChangeMax = (event) => {
    this.setState({maxAmount: event.target.value});
  }

  onChangeCharge = (event) => {
    this.setState({charges: event.target.value});
  }

  onSave=async(event)=>{
    
    event.preventDefault();

    const {minAmount, maxAmount, charges}=this.state
   
    if(minAmount.length>0 && 
      maxAmount.length>0 && 
      charges.length>0)
    {

        const server_response=await ajax.addWithdrawCharges(minAmount, maxAmount, charges);
        if(server_response.status==="OK")
        {
           this.setState({
             info:<AlertSuccess message={server_response.details[0]}/>,
             minAmount:'',
             maxAmount:'',
             charges:''
           })
           this.props.onFinish()
        }else{
          this.setState({
            info:<AlertError message={server_response.details[0]}/>
          })
        }

    }else{
      this.setState({
        info:<AlertError message={dictionary._completeFields}/>
      })
    }
  }

  render(){
    
      return (
        <div className="modal" id="mdl-new-withdraw-charge">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Define Withdraw Charges</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onSave}>
										
                                       
                                          <TextInput 
                                            label="Min amount"
                                            type="number"
                                            onChange={()=>this.onChangeMin}
                                            value={this.state.minAmount}
                                            />
    
                                            <TextInput 
                                                label="Max Amount"
                                                type="number"
                                                onChange={()=>this.onChangeMax}
                                                value={this.state.maxAmount}
                                                />

                                            <TextInput 
                                                label="Charges"
                                                type="number"
                                                onChange={()=>this.onChangeCharge}
                                                value={this.state.charges}
                                                />
                                    </form>
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-primary" type="button" value="Effect Charges" onClick={this.onSave}/>
                    <input className="btn ripple btn-secondary" data-dismiss="modal" type="button" value="Close"/>
                </div>
            </div>
        </div>
    </div>
  )}
      
}

export default MdlNewChargeWithdraw;
import dictionary from "../dictionary";

 const apiHost=dictionary.apiHost;
const ACCESS_TOKEN = localStorage.getItem('quickpost@user');

export default {
   
    async loginUser(username, password)
    {

        let data={
            "username":username,
            "password":password
        }
        try{
            let response= await fetch(apiHost + 'admin/login',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async getLoginAttempts(username)
    {

        let data={
            "username":username
        }
        try{
            let response= await fetch(apiHost + 'user/activity/login/attempts',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },
    async resetUserLoginAttempts(username, password)
    {

        let data={
            "username":username,
            "password":password
        }
        try{
            let response= await fetch(apiHost + 'user/activity/login/reset',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                },
                body:JSON.stringify(data),
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },


}
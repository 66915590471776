import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PopVerifyAction from '../Common/PopVerifyAction';
import SelectSearch from '../Common/SelectSearch';
import Toast from '../Common/Toast';


class PopTopupApproval extends React.Component {
  state={
      loading:false,
      transactionRecord:this.props.record,
      verification:false,
      collectAccounts:false,
      accountId:'',
      toast:false
  }


 
  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  
  onClickCancel=()=>{
          this.setState({
              verification:<PopVerifyAction
                title="Account Authentication"
                description="Enter your account password to cancel this topup request"
                requestId={this.state.transactionRecord.request_id}
                action={"CANCEL-TOPUP-REQUEST"}
              />
          }, ()=>{
            functions.closePopup('mdl-topup-approval');
            functions.openPopup('pop-verify-action');

          })
  }

  onClickApprove=()=>{
    this.setState({
        verification:<PopVerifyAction
          title="Account Authentication"
          description="Enter your account password to approve topup request"
          requestId={this.state.transactionRecord.request_id}
          action={"APPROVE-TOPUP-REQUEST"}
        />
    }, ()=>{
      functions.closePopup('mdl-topup-approval');
      functions.openPopup('pop-verify-action');

    })
}

onClickProcess=()=>{
    const {accountId}=this.state
  
        this.setState({
            verification:<PopVerifyAction
            title="Account Authentication"
            description="Enter your account password to process topup request"
            requestId={this.state.transactionRecord.request_id}
            action={"PROCESS-TOPUP-REQUEST"}
            accountId={this.state.accountId}
            />
        }, ()=>{
        functions.closePopup('mdl-topup-approval');
        functions.openPopup('pop-verify-action');

        })
   
}



  resetToast=()=>{
    this.setState({
        toast:false
    })
}


  render(){
    const transactionList=this.state.transactionRecord;
   
      return (
      <>
        {this.state.verification}
        
        <div className="modal" id="mdl-topup-approval">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Manage Topup Request</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    {this.state.toast}
                </div>
                <div className="modal-body">



                            {transactionList && transactionList!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                       
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Amount</label> 
                                                <span className="tx-medium">{transactionList.amount.amount_c}</span>
                                            </div>
                                            <div>
                                                <label>Status</label> 
                                                <span className="tx-medium">{transactionList.status.status_name}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Request Date</label> 
                                                <span className="tx-medium">{transactionList.request_date.long_date}</span>
                                            </div>
                                           
                                            <div>
                                                <label>Transaction Date</label> 
                                                <span className="tx-medium">{transactionList.transaction_date}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Beneficiary Account</label> 
                                                <span className="tx-medium">{transactionList.user.first_name + ' ' + transactionList.user.last_name + ' - '+ transactionList.user.username}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                        


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Receipt Reference</label> 
                                                <span className="tx-medium">{transactionList.receipt_ref}</span>
                                            </div>
                                            <div>
                                                <label>Company A/C Number</label> 
                                                <span className="tx-medium">{transactionList.current_account.account_number}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Last updated on</label> 
                                                <span className="tx-medium">{transactionList.last_update.short_date + ' ' + transactionList.last_update.time}</span>
                                            </div>
                                            <div>
                                                <label>Account Description</label> 
                                                <span className="tx-medium">{transactionList.current_account.account_name}</span>
                                            </div>
                                            </div>
                                        </div>



                                        <div className="media">
                                            <div className="media-body">

                                            <div>
                                                <label>Approved By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.approved_by?transactionList.approved_by.first_name + ' ' + transactionList.approved_by.last_name:'Not Approved'}
                                                </span>
                                            </div>

                                            <div>
                                                <label>Processed By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.processed_by?transactionList.processed_by.first_name + ' ' + transactionList.processed_by.last_name:'Not Processed'}
                                                </span>
                                            </div>
                                    
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">

                                            {transactionList.cancelled_by && <div>
                                                <label>Cancelled By</label> 
                                                <span className="tx-medium">
                                                    {transactionList.cancelled_by.first_name + ' ' + transactionList.cancelled_by.last_name}
                                                </span>
                                            </div>}

                                          
                                    
                                            </div>
                                        </div>
                                      
                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}


                              
                            
                                
                </div>
                <div className="modal-footer">
                {transactionList.status.status_id*1!==2 && transactionList.status.status_id*1!==4 && 
                <input 
                        className="btn ripple btn-danger" 
                        type="button" 
                        value="Cancel Request" 
                        onClick={this.onClickCancel}/>}
                    {transactionList.status.status_id*1===1 &&
                    <input 
                        className="btn ripple btn-primary" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickApprove}/>}

                    {transactionList.status.status_id*1===3 &&
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickProcess}/>}

                   
                </div>
            </div>
        </div>
    </div>
    </>
     )
    
 
  }
}

export default PopTopupApproval;
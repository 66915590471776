import React from 'react';
class TextInputWrap extends React.Component {
  

  render(){

    const inputType=this.props.type || "text"
    
      return (

        <div class="form-group">
            <p class="mg-b-10">{this.props.label}</p>
            
            {inputType!=="textarea" &&
              <input 
                type={ inputType || "text" } 
                className="form-control" 
                placeholder={this.props.placeholder || this.props.label}
                onChange={this.props.onChange()}
                onClick={this.props.onClick}
                value={this.props.value}
                autoFocus={this.props.autoFocus}
                readOnly={this.props.readOnly || false}/>}

                {inputType==="textarea" &&
                  <textarea 
                    type={ inputType } 
                    className="form-control" 
                    placeholder={this.props.label}
                    onChange={this.props.onChange()}
                    value={this.props.value}
                    onClick={this.props.onClick}
                    readOnly={this.props.readOnly || false}/>}
            
            
            </div>
      );
    
 
  }
}

export default TextInputWrap;
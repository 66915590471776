import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
class Tokens extends React.Component {

    state={
        userId:functions.sessionGuard(),
        tokenList:false
       
      }

      componentDidMount()
      {
        this.listTokens();
      }


      listTokens=async()=>{
          const server_response=await ajax.getTokens();
          console.log(server_response)
          if(server_response.status==="OK")
          {
             this.setState({
                tokenList:server_response.details
             })

          }else{
            this.setState({
                tokenList:"404"
            })
          }
      }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:record.user.user_id}
        })
      

      }
      

    
    render(){
        const list=this.state.tokenList
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Activation Tokens"
                     previousPageTitle="Agents"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
							          	<div className="card-body">
                            <div>
                              <h6 className="card-title mb-1">Activation Tokens</h6>
                              <p className="text-muted card-sub-title">Activation tokens for users</p>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>#No</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Token</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                                              <tr key={key}>
                                    <td>{key+1}</td>
                                    <td>
                                        <Link to="#" onClick={()=>this.onClickProfile(item)}>{item.user.first_name}</Link>
                                    </td>
                                    <td>
                                        <Link to="#" onClick={()=>this.onClickProfile(item)}>{item.user.last_name}</Link>
                                    </td>
                                    <td>{item.user.phone}</td>
                                    <td>{item.token_number}</td>
                                    <td>{item.amount.amount_c}</td>
                                      <td>{item.status_name}</td>
                                  </tr>
                                  )}	
                                  
                                </tbody>
                              </table>

                                    {!this.state.tokenList && <ActivityLoader/>}
                                    

                            </div>
                          </div>
							        </div>
						

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default Tokens;
  
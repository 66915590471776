import React, { Component } from 'react';

class ClassifiedProfile extends Component {
    render() {
        return (
            <div className="row sidemenu-height">
						<div className="col-md-12">
							<div className="container error-image text-center">
								<div className="display-1 mb-2 mt-5">
									Classified!
								</div>
								<div className="h2 mb-6">Permission Error: Request could not be completed</div>
								<h6 className="tx-inverse">This information has been restricted, you need elevated permission to access it.</h6>
								<button className="btn ripple btn-primary mt-4 mb-4"> I have Permission </button>
							</div>
						</div>
					</div>
        );
    }
}

export default ClassifiedProfile;
import React from 'react';

class PillTag extends React.Component {

    state = {
        types: ['tag-success', 'tag-warning', 'tag-danger', 'tag-primary', 'tag-secondary', 'tag-dark'],
        type: this.props.type || 0,
        title: this.props.title
    }



    render() {

        return (
            <span className={`tag ${this.state.types[this.state.type]} tag-pill mt-1 mb-0`}>
                {this.state.title}
            </span>
        );


    }
}

export default PillTag;
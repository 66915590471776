import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxCashRound from '../../remote/ajaxCashRound';
import ActivityLoader from '../Common/ActivityLoader';
import TableHeader from '../Common/TableHeader';

class RecentCashRounds extends Component {

    state={
        transactions:false,
        message:''
    }

    componentDidMount() {
        this.getTransactions();
    }
    

    getTransactions=async()=>{
        const server_response = await ajaxCashRound.getRecentCashGroups();
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details,
                message:server_response.message
            })
        }else{
            this.setState({
                transactions:"404",
                message:server_response.message
            })
        }
    }

    render() {
        const list = this.state.transactions;
        return (
            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                <div className="card custom-card">
                    <div className="card-body">
                        <TableHeader
                            title="Recent Cash Rounds"
                            subtitle="Recently added cash rounds."
                            link="/cashround/groups"
                        />

                        <div className="role-body">
                            <div className="activity-block scroll-widget overflow-auto">
                                <ul className="task-list">
                                    {list && list !== "404" &&
                                        list.map((item, key) =>
                                             <li key={key}>
												<i className={`task-icon ${item.current_collection.amount>0?'bg-success':'bg-secondary'}`}></i>
												<h6>
                                                    <Link to={
                                                            {
                                                                pathname:`/cashround/profile/${item.group_id}`,
                                                                state:{groupId:item.group_id}
                                                            }
                                                        }>{item.group_name}</Link>
                                                    <small className="float-right text-muted tx-11">UGX. {item.membership_fee.amount_p}</small></h6>
												<span className="text-muted tx-12">
                                                    {item.admin_info.last_name + ' ' + item.admin_info.first_name + ' (' + item.admin_info.phone + ')'}
                                                </span>
											</li> 

                                    )}
                                    
                                </ul>
                                {!list && <ActivityLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default RecentCashRounds;
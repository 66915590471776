import React from 'react';
import Loader from 'react-loader-spinner';

class ActivityLoader extends React.Component {
  

  render(){
    
      return (
        <div className="col-xl-12 col-lg-12 col-12 text-center">
             <Loader
                type="Rings"
                color="#00BFFF"
                height={80}
                width={80}
            />
        </div>

      );
    
 
  }
}

export default ActivityLoader
import React from 'react';
import ajax from '../../../ajax';
import functions from '../../../functions';
import DashCard from '../../Common/DashCard';


class ActualBalance extends React.Component {
  

  state={
    userId:functions.sessionGuard(),
    accessInfo:false,
    balance:'...',
    total:'...'
   
  }


    async componentDidMount()
    {
      await this.getActualBalance();
      this.timerActual = setTimeout(this.func1=async()=> {
        await this.getActualBalance();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }, 2000);

    
    }

    componentWillUnmount()
    {
      clearTimeout(this.timerActual);
    }

    getActualBalance=async()=>{
      const server_response=await ajax.getUsersAccountBalance();
      if(server_response.status==="OK")
      {
        this.setState({
          balance:server_response.details.amount_c
        })
      }
    }


   


  render(){
      return (
        <DashCard 
            title="Actual Balance"
            value={this.state.balance}
            caption={"Wallet & Business Float Balances"}
            icon={"fas fa-chart-bar text-primary"}
            progressbar={"bg-primary"}
            />

      );
    
 
  }
}

export default ActualBalance;
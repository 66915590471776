import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import SilentText from '../../Components/Common/SilentText'
import PillTag from '../../Components/Common/PillTag'
import PageNav from '../../Components/Common/PageNav'
import FilterBar from '../../Components/Common/FilterBar'
import PopVerifyCustomer from '../../Components/User/PopVerifyCustomer'
import PopImageViewer from '../../Components/Common/PopImageViewer'

class ListCustomers extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        page:1,
        agentId:0,
        signingAgent:false,
        metaData:false,
        page:1,
        pagination:false,
        filter:false,
        verification:false,
        selectedRecordId:false,
        imageViewer:false
      }


      componentDidMount()
      {
        if(!this.props.location.state)
        {
          this.listTransactions();
        }else{
          this.setState({
            agentId:this.props.location.state.agent_id
          }, 
          ()=>{
                this.listTransactions();
                })
            }
        
      }



      listTransactions=async()=>{

        if(this.state.filter)
        {
          const filter=this.state.filter;
          var server_response=await ajax.filterCustomers(
            this.state.page,
            filter.dateFrom,
            filter.dateTo,
            filter.selectedValue,
            filter.keyword
          );
        }else{
          var server_response = await ajax.getCustomers(this.state.page, this.state.agentId);

        }
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta,
                signingAgent:server_response.details.signing_agent

             }, ()=>this.paginate())

          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }



      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:record.user.user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


      onUpdateRecord=()=>{
        this.setState({
          transactionList:false,
          metaData:false
        }, ()=>this.listTransactions())
      }
     

    docHeader=()=>{
      if(this.state.signingAgent)
      {
        return(
          <div>
            <h6 className="card-title mb-1">Agent's Customer Records</h6>
        <p className="text-muted card-sub-title">List of all Quickpost customers under {this.state.signingAgent.first_name + ' ' + this.state.signingAgent.last_name}</p>
          </div>
        )
      }else{
        return(
          <div>
              <h6 className="card-title mb-1">Customer Records</h6>
              <p className="text-muted card-sub-title">List of all Quickpost customers</p>
					</div>
        )
      }
    }

    onClickImage=(url, title)=>{
      this.setState({
        imageViewer:false
      }, ()=>{
        this.setState({
          imageViewer:<PopImageViewer url={url} title={title}/>
        }, ()=>functions.openPopup('modal_image_viewer'))
      })
    }


    //==============NAVIGATION CONTROLS START===========================
      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            transactionList:false

          }, ()=>{
            this.listTransactions();
          })
        }

      }

      onClickPage=(page)=>{
        this.setState({
            page:page,
            transactionList:false
        }, ()=>this.listTransactions())
    }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            transactionList:false

          }, ()=>{
            this.listTransactions();
          })
        }

      }

      paginate=()=>{
        if(this.state.metaData)
        {
          this.setState({
            pagination:false
          }, ()=>{

            this.setState({
              pagination:<PageNav
              totalPages={this.state.metaData.total_pages}
              pageList={this.state.metaData.list_of_pages}
              currentPage={this.state.page}
              onClickNext={this.onClickNext}
              onClickPage={this.onClickPage}
              onClickPrevious={this.onClickPrevious}
              />
            })
            
          })
          
        }
      }

  //==============NAVIGATION CONTROLS END===========================


  onFilter=async(filter)=>{
    this.setState({filter:filter, metaData:false, transactionList:false}, ()=>this.listTransactions())
  }
  resetFilter=()=>{
    this.setState({
      filter:false,
      metaData:false,
      transactionList:false
    }, ()=>this.listTransactions())
  }


  buttons=()=>{
    return(
        <>

            <Link to="#" className="btn ripple btn-light" onClick={this.onClickAddPayment}>
                <i className="fas fa-file-excel"></i> Export
            </Link>
                <a href="#" className="btn ripple btn-primary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fe fe-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                </a>
          
          </>
        )
  }


  onClickVerify=(userInfo)=>{
    this.setState({
      verification:false
    }, ()=>{

      this.setState({
        verification:<PopVerifyCustomer data={userInfo} onUpdate={this.onUpdateRecord}/>
      }, ()=>functions.openPopup('modal_verify_customer'))
    })
   
  }

  onSelectRecord=(selectedRecordId)=>{
    this.setState({
      selectedRecordId})
    }

    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;
        const customerTypeOptions=[{title:"Agent Customers", value:"1"}, {title:"Non Agent Customers", value:"2"}]
          return (
            <div className="page">
              {this.state.verification}
              {this.state.imageViewer}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Customer Records"
                     previousPageTitle="Customers"
                     back="/customers"
                     buttons={<this.buttons/>} 
                  />

                  
                <FilterBar 
                  selection={{title:'Customer Type', options:customerTypeOptions}}
                  onReset={this.resetFilter}
                  onConfirm={this.onFilter}
                  />

                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <this.docHeader/>
                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>#No</th>
                                    <th>Reg. Date</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Cridentials</th>
                                    <th>Gender</th>
                                    <th>Birth Date</th>
                                    <th>NIN</th>
                                    <th>Address</th>
                                    <th>Verification</th>
                                    <th>Next Of Kin</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                    <tr 
                                      key={key}
                                      className={`${this.state.selectedRecordId==item.user.user_id?'flag-row':''}`}
                                      onClick={()=>this.onSelectRecord(item.user.user_id)}  
                                      >
                                    <td>{metaData.offset_count+key+1}</td>
                                    <td>
                                      {item.user.since.long_date}
                                      <br/><SilentText text={item.user.since.time}/>
                                    </td>
                                    <td>
                                        
                                        <div className="main-img-user">
                                          <Link to="#" onClick={()=>this.onClickImage(item.user.photo, 'Customer Passport Photo')}>
                                            <img alt="wait..." src={item.user.photo} />
                                          </Link>
                                          </div>
                                    </td>

                                    <td>
                                        {item.user.first_name}
                                        <br/><SilentText text={item.user.last_name}/>
                                    </td>

                                    <td>
                                      <Link 
                                          className="orange" 
                                          to={
                                            {
                                              pathname:'/customer-profile',
                                              state:{customerId:item.user.user_id}
                                            }}
                                          >{item.user.phone}</Link>
                                            <br/><SilentText text={"@" + item.user.username}/>
                                      </td>
                                    <td>{item.user.gender}</td>
                                    <td>{item.user.dob.picker}</td>
                                    <td>
                                      {item.user.NIN}
                                      <br/>
                                      <Link to="#" onClick={()=>this.onClickImage(item.user.national_id, 'National ID')}>
                                        <SilentText text="View ID"/>
                                      </Link>
                                    </td>
                                    <td>{item.user.address.address || "N/A"}</td>
                                    <td>
                                      <a onClick={()=>this.onClickVerify(item.user)}>
                                        {item.user.verified*1 == 1 && <PillTag title={"Verified "}/>}
                                        {item.user.verified*1 == 0 && <PillTag type={2} title={"UnVerified "}/>}
                                      </a>
          
                                    </td>
                                    <td>
                                        {item.user.next_of_kin && <span>
                                          {item.user.next_of_kin.first_name + ' ' + item.user.next_of_kin.last_name}
                                          <br/><SilentText text={item.user.next_of_kin.contact}/>
                                        </span> || "N/A"}
                                    </td>
                                  </tr>
                                      )}	
                                  
                                </tbody>
                              </table>

                              {this.state.metaData && this.state.pagination}

                              {!this.state.transactionList && <ActivityLoader/>}


                                 
                            </div>
                          </div>
                        </div>
						

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default ListCustomers;
  
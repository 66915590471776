import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import TotalLoansToday from '../../Components/Cards/TotalLoansToday'
//import TotalMonthlyLoans from '../Components/Cards/TotalMonthlyLoans'
import TotalPaidLoans from '../../Components/Cards/TotalPaidLoans'
import DefaultedLoans from '../../Components/Cards/DefaultedLoans'
import RecentLoans from '../../Components/RecentLoans'
import LoanSummary from '../../Components/LoanSummary'
import SecurityDeposit from '../../Components/Cards/SecurityDeposit'
import LoanFine from '../../Components/Cards/LoanFine'
import UpcomingLoans from '../../Components/UpcomingLoans'
import TotalPrincipalLoans from '../../Components/Cards/TotalPrincipalLoans'
import { Link } from 'react-router-dom'
import BadLoanCountCards from '../../Components/Loan/BadLoanCountCards'

class Dashboard extends React.Component {

    state={
      userId:functions.sessionGuard(),
    }


    buttons=()=>{
      return(
          <>
              <button className="btn ripple btn-success">
                  <i className="fas fa-hands-helping"></i> Business Loans
              </button>
              <button className="btn ripple btn-primary">
                  <i className="fas fa-hands-helping"></i> Personal Loans
              </button>
              
            </>
          )
    }

    customHeaderOptions=()=>{
      return(
          <>
               <Link to="/loans/capital_float" className="dropdown-item">
                  <i className="fas fa-briefcase mr-2"></i>Capital Float
              </Link>
              <Link to="/loans/security_deposit" className="dropdown-item">
                  <i className="fas fa-unlock-alt mr-2"></i>Security Deposits
              </Link>

              <Link to="/loans/classification" className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Classification & Provisioning
              </Link>
              {/* <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Fines
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Paid Extension
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Interest Earnings
              </a> */}
             
            </>
          )
    }

   

    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Loans"
                    previousPageTitle="Dashboard"
                    options={{
                      "customOptions":<this.customHeaderOptions/>
                    }}
                    buttons={<this.buttons/>}
                  />
                  
                    <div className="row">
                      
                      <TotalLoansToday/>
                      {/* <TotalMonthlyLoans/> */}
                      <TotalPrincipalLoans/>
                      <TotalPaidLoans/>
                      <DefaultedLoans/>

                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                          <RecentLoans/>
                          {/* <UpcomingLoans/> */}
                          <BadLoanCountCards/>
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                          <LoanSummary/>
                          <SecurityDeposit/>
                          <LoanFine/>

                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default Dashboard
import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import TextInputWrap from './TextInputWrap';
import AlertError from './AlertError';
import ajax from '../../ajax';
import AlertSuccess from './AlertSuccess';
import ActivityLoader from './ActivityLoader';
import ajaxUser from '../../remote/ajaxUser';


class PopVerifyAction extends React.Component {
  state={
      buttonLabel:this.props.buttonLabel || "Confirm Delete",
      loading:false,
      password:'',
      info:<p className="mg-b-20 mg-x-20">{this.props.description}</p>,
      action:this.props.action,
      requestId:this.props.requestId
  }

  
  closeDialogue=()=>{
    $("#pop_success").modal('hide');
  }

  onChangePassword=(event)=>{
    this.setState({
        password:event.target.value
    })
  }

  onConfirmAction=async(event)=>{
      event.preventDefault();
      const {password, action, requestId}=this.state;
      this.setState({
          info:<p className="mg-b-20 mg-x-20">{this.props.description}</p>
      })
      if(password.length>0)
      {  
        var server_response;
        this.setState({
            loading:true
        })
        switch(action)
        {
            case "CANCEL-SECURITY-REQUEST":
                server_response=await ajax.cancelSecurityDepositRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;
            case "APPROVE-SECURITY-REQUEST":
                server_response=await ajax.approveSecurityDepositRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;


            case "PROCESS-SECURITY-REQUEST":
                server_response=await ajax.processSecurityDepositRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;

            case "CANCEL-FLOAT-REQUEST":
                server_response=await ajax.cancelFloatRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;

            case "APPROVE-FLOAT-REQUEST":
                server_response=await ajax.approveFloatRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;

            case "PROCESS-FLOAT-REQUEST":
                server_response=await ajax.processFloatRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;

            case "VERIFY-LOGOUT-CONTROL":

                server_response=await ajax.updateLogoutButton(requestId, this.props.value, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }


            break;


            case "REQUIRE-DOCUMENTS-CONTROL":

                server_response=await ajax.updateDocumentVerification(requestId, this.props.value, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        this.props.onUpdate(this.props.value==="0"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }


            break;

            case "LOAN-ACTIVITY-CONTROL":

                server_response=await ajax.updateLoanActivity(requestId, this.props.value, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;

            case "WALLET-ACTIVITY-CONTROL":

                server_response=await ajax.updateWalletActivity(requestId, this.props.value, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;
            case "WALLET-WITHDRAW-CONTROL":

                server_response=await ajax.updateWalletWithdraws(requestId, this.props.value, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;
            case "REQUEST-RESERVE":

                server_response=await ajax.requestReserve(requestId.amount, requestId.description, requestId.account, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
                

            break;
            case "CANCEL-RESERVE-REQUEST":

                server_response=await ajax.cancelReserveRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;
            case "APPROVE-RESERVE-REQUEST":
                server_response=await ajax.approveReserveRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;
            case "PROCESS-RESERVE-REQUEST":

                server_response=await ajax.processReserveRequest(requestId, this.props.accountId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;
           
            case "CANCEL-TOPUP-REQUEST":

                server_response=await ajax.cancelTopupRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;
            case "APPROVE-TOPUP-REQUEST":
                server_response=await ajax.approveTopupRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }
            break;
            case "PROCESS-TOPUP-REQUEST":
                server_response=await ajax.processTopupRequest(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.details[0]}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.details[0]}/>
                    })
                }

            break;
            case "VERIFY-LOGIN-RESET":
                server_response=await ajaxUser.resetUserLoginAttempts(requestId, password);
                this.setState({
                    loading:false
                })
                if(server_response.status==="OK")
                {
                    this.setState({
                        info:<AlertSuccess message={server_response.message}/>
                    }, ()=>{
                        $("#pop-verify-action").modal('hide');
                        //this.props.onUpdate(this.props.value==="1"?true:false)
                    })
                }else{
                    this.setState({
                        info:<AlertError message={server_response.message}/>
                    })
                }
            break;
            default:
                this.setState({
                    loading:false
                })
                this.setState({
                    info:<AlertError message="Unknown request, could not process request"/>
                })
            break;
        }

      }else{
        this.setState({
            info:<AlertError message="Enter password to verify action"/>
        })
      }
  }

  render(){
    
      return (
        <div className="modal" id="pop-verify-action">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button> 
                    <i className="icon fas fa-user-circle tx-100 tx-dark lh-1 mg-t-20 d-inline-block"></i>
                    <h4 className="tx-dark tx-semibold mg-b-20">{this.props.title}</h4>
                    
                    {this.state.info}
                    <form method="post" onSubmit={this.onConfirmAction}>
                        <TextInputWrap 
                            type="password" 
                            onChange={()=>this.onChangePassword}
                            placeholder={"Enter your account password"}
                            autoFocus={true}
                            />
                        {!this.state.loading && 
                        <input 
                            type="button" 
                            value="Continue"
                            className="btn ripple btn-success pd-x-25" 
                            onClick={this.onConfirmAction}/>}

                        {this.state.loading && <ActivityLoader/>}
                    </form>

                </div>
            </div>
        </div>
    </div>
      )
    
 
  }
}

export default PopVerifyAction;

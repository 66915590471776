import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';


class FilterBadLoans extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    statistics:false,
    types:['btn-success', 'btn-primary', 'btn-secondary', 'btn-info'],
    badgeType:['badge-danger', 'badge-light', 'badge-dark', 'badge-danger']

  }
  async componentDidMount()
  {
    await this.requestRecord();
    this.timer = setTimeout(this.func=async()=> {
      await this.requestRecord();
      this.timer = setTimeout(this.func, 2000); // (*)
    }, 2000);
  }

  componentWillUnmount()
  {
    clearTimeout(this.timer)
  }

  requestRecord=async()=>{
    const server_reponse=await ajax.countGroupedBadLoans();
    if(server_reponse.status==="OK")
    {
        this.setState({
           statistics:server_reponse.details
        })
    }
  }

  render(){
    const list=this.state.statistics || []

      return (
        <div className="text-wrap">
        <div className="example">
            <div className="btn-list">
                {list.map((item, key)=>
                
                    <Link 
                        to={
                            {
                                pathname:"/loans/classification/" + item.group_details.group_name,
                                state:{groupId:item.group_details.group_id}
                            }
                        }
                        
                        type="button" key={key} 
                        className={`btn ripple ${this.state.types[key]} btn-sm`}>
                            {item.group_details.group_name} 
                            <span className="badge badge-light ml-2">{item.count.total_c}</span>
                        </Link>
                )}
            </div>
        </div>
        </div>
      );
    
 
  }
}

export default FilterBadLoans;



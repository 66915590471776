import React from 'react';


class DimHeader extends React.Component {
  

  render(){
    
      return (
       <span className="m-5">
           <h2 className="m-5 tx-gray-500">{this.props.title}</h2>
       </span>

      );
    
 
  }
}

export default DimHeader;
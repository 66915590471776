import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PopVerifyAction from '../Common/PopVerifyAction';


class PopSecurityDepositApproval extends React.Component {
  state={
      loading:false,
      transactionRecord:this.props.record,
      verification:false
  }


 

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  
  onClickCancel=()=>{
          this.setState({
              verification:<PopVerifyAction
                title="Verify Action"
                description="Enter your account password to verify action"
                requestId={this.state.transactionRecord.request_id}
                action={"CANCEL-SECURITY-REQUEST"}
              />
          }, ()=>{
            functions.closePopup('mdl-security-approval');
            functions.openPopup('pop-verify-action');

          })
  }

  onClickApprove=()=>{
    this.setState({
        verification:<PopVerifyAction
          title="Verify Action"
          description="Enter your account password to verify action"
          requestId={this.state.transactionRecord.request_id}
          action={"APPROVE-SECURITY-REQUEST"}
        />
    }, ()=>{
      functions.closePopup('mdl-security-approval');
      functions.openPopup('pop-verify-action');

    })
}

onClickProcess=()=>{
    this.setState({
        verification:<PopVerifyAction
          title="Verify Action"
          description="Enter your account password to verify action"
          requestId={this.state.transactionRecord.request_id}
          action={"PROCESS-SECURITY-REQUEST"}
        />
    }, ()=>{
      functions.closePopup('mdl-security-approval');
      functions.openPopup('pop-verify-action');

    })
}


  render(){
    const transactionList=this.state.transactionRecord;
      return (
      <>
        {this.state.verification}
        <div className="modal" id="mdl-security-approval">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Security Request Approval</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">



                            {transactionList && transactionList!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                       
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Amount</label> 
                                                <span className="tx-medium">{transactionList.amount.amount_c}</span>
                                            </div>
                                            <div>
                                                <label>Status</label> 
                                                <span className="tx-medium">{transactionList.status.status_name}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Request Date</label> 
                                                <span className="tx-medium">{transactionList.request_date.long_date}</span>
                                            </div>
                                           
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Customer Name</label> 
                                                <span className="tx-medium">{transactionList.user.first_name + ' ' + transactionList.user.last_name}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                        

                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Last Updated By</label> 
                                                <span className="tx-medium"> 
                                                {transactionList.updated_by?transactionList.updated_by.username:"Customer"}
                                                </span>
                                            </div>

                                            <div>
                                                <label>Updated On</label> 
                                                <span className="tx-medium">{transactionList.last_update.long_date}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}
                            
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-danger" type="button" value="Cancel Request" onClick={this.onClickCancel}/>
                    {transactionList.status.status_id*1===1 &&
                    <input 
                        className="btn ripple btn-primary" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickApprove}/>}

                    {transactionList.status.status_id*1===3 &&
                    <input 
                        className="btn ripple btn-success" 
                        type="button" value={transactionList.next_status} 
                        onClick={this.onClickProcess}/>}

                   
                </div>
            </div>
        </div>
    </div>
    </>
     )
    
 
  }
}

export default PopSecurityDepositApproval;
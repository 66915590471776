import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import PageNav from '../../Components/Common/PageNav'

class ListSupervisors extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        pagination:false,
        page:1
       
      }

      componentDidMount()
      {
        this.listTransactions();
      }


      listTransactions=async()=>{
          const server_response=await ajax.listSupervisors(this.state.page);
          if(server_response.status==="OK")
          {
            this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta
             }, ()=>this.paginate())


          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }


         
//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


    
    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;

          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Agent Supervisors"
                     previousPageTitle="Agents"
                     back="/agents"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                     
                          <div className="card custom-card">
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-1">Supervisor Records</h6>
                                <p className="text-muted card-sub-title">List of all Quickpost agent supervisors</p>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>#No</th>
                                      <th>First Name</th>
                                      <th>Last Name</th>
                                      <th>Gender</th>
                                      <th>Phone</th>
                                      <th>Agents</th>
                                      <th>M. Target</th>
                                      <th>Zone</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {list && list!='404' && list.map((item, key)=>
                                    <tr key={key}>
                                        <td>{key+1}</td>
                                        <td>{item.user_info.user.first_name}</td>
                                        <td>{item.user_info.user.last_name}</td>
                                        <td>{item.user_info.user.gender}</td>
                                        <td>
                                          <Link to="#" className="tx-bold">
                                            {item.user_info.user.phone}
                                            </Link>
                                        </td>
                                        <td className="tx-bold">
                                            <Link to="#">{item.total_agents.total_p}</Link>
                                        </td>
                                        <td className="tx-bold">
                                            <Link to="#">{"100"}</Link>
                                        </td>
                                        <td className="tx-bold">
                                          <Link to="#">
                                          {item.user_info.agent.zone?item.user_info.agent.zone.area_name:" - Not Provided -"}
                                          </Link>
                                        </td>
                                    </tr>
                                    )}
                                  </tbody>
                                </table>
                                    {this.state.metaData && this.state.pagination}
                                      {!this.state.transactionList && <ActivityLoader/>}

                              </div>
                            </div>
                          </div>
                        
                      </div>






                    <div className="col-sm-12 col-xl-4 col-lg-4">
                     
                     <div className="card custom-card">
                       <div className="card-body">
                         <div>
                           <h6 className="card-title mb-1">Supervisors target archievement</h6>
                           <p className="text-muted card-sub-title">Supervisor's target sales achievement</p>
                         </div>

                        </div>
                    </div>
                    </div>







                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default ListSupervisors;
  
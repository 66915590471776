import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';

class RequestAlerts extends React.Component {

    state={
        totalUnapproved:0,
        totalUnprocessed:0
    }

   
    componentDidMount()
    {
        this.timerUnprocessed = setTimeout(this.func1=async()=> {
            await this.countUnprocessedRequisitions();
            this.timerUnprocessed = setTimeout(this.func1, 2000); // (*)
          }, 2000);

          this.timerUnapproved = setTimeout(this.func2=async()=> {
            await this.countUnapprovedRequisitions();
            this.timerUnapproved = setTimeout(this.func2, 2000); // (*)
          }, 2000);
    }


    componentWillUnmount()
    {
        clearTimeout(this.timerUnapproved);
        clearTimeout(this.timerUnprocessed)
    }


    
    countUnapprovedRequisitions=async()=>
    {
        const server_reponse=await ajax.countUnapprovedReserveRequests();
        if(server_reponse.status==="OK")
        {
            this.setState({
                totalUnapproved:server_reponse.details.total_c
            })
        }
    }

    countUnprocessedRequisitions=async()=>
    {
        const server_reponse=await ajax.countUnprocessedReserveRequests();
        if(server_reponse.status==="OK")
        {
            this.setState({
                totalUnprocessed:server_reponse.details.total_c
            })
        }
    }
  

   
  render(){
    
      return (
       <>
        {(this.state.totalUnapproved*1>0 || this.state.totalUnprocessed*1>0) && <span className="m-0">
                {this.state.totalUnapproved*1>0 && 
                     <span className={`tag tag-danger tag-pill mt-1 mb-0`}>
                     {"Pend. Approval (" + this.state.totalUnapproved + ")"}
                 </span>}
                &nbsp;&nbsp;

                {this.state.totalUnprocessed*1>0 && 
                 <span className={`tag tag-success tag-pill mt-1 mb-0`}>
                 {"Pend. Process ("+ this.state.totalUnprocessed  +")"}
                </span>
                }
        </span> }  
       </>
      )
    
 
  }
}

export default RequestAlerts;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ajaxCashRound from '../../remote/ajaxCashRound';
import ActivityLoader from '../Common/ActivityLoader';
import TableHeader from '../Common/TableHeader';

class GroupMembers extends Component {

    state={
        transactions:false,
        message:'',
        groupId:this.props.groupId
    }

    componentDidMount() {
        this.getTransactions();
    }
    

    getTransactions=async()=>{
        const server_response = await ajaxCashRound.getGroupMembers(this.state.groupId);
        if(server_response.status==="OK")
        {
            this.setState({
                transactions:server_response.details.members,
                message:server_response.message
            })
        }else{
            this.setState({
                transactions:"404",
                message:server_response.message
            })
        }
    }

    render() {
        const list = this.state.transactions;
        return (
            <div className="col-sm-12 col-md-12 col-xl-4 col-lg-4">
                <div className="card custom-card">
                    <div className="card-body">
                        <TableHeader
                            title="Group Members"
                            subtitle="Members of this cash round."
                        />

                        <div className="role-body">
                            <div className="activity-block scroll-widget overflow-auto">
                                <ul className="task-list">
                                    {list && list !== "404" &&
                                        list.map((item, key) =>
                                             <li 
                                                className={`${this.props.targetMember===item.user.user_id?'flag-row-green':''}`}
                                                key={key}>
												<i className={`task-icon ${item.current_contribution.amount>0?'bg-success':'bg-secondary'}`}></i>
												<h6>{item.user.first_name + ' ' + item.user.last_name}
                                                    <small className="float-right text-mute tx-12">UGX. {item.balance.amount_c}</small>
                                                    <br/><small className="float-right text-muted tx-11">Interval Balance</small>
                                                    </h6>
												<span className="text-muted tx-12">
                                                    {item.user.username}
                                                </span>
											</li> 

                                    )}
                                    
                                </ul>
                                {!list && <ActivityLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default GroupMembers;
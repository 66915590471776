import React from 'react';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';

class PopImageViewer extends React.Component {

    state={
        userId:functions.sessionGuard(),
        loading:false
    }

    componentDidMount()
    {
    }

   

  render(){
     return (
        <div className="modal" id="modal_image_viewer">
        <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
            
        <div className="modal-content shadow">
            <div className="modal-header">
                <h6 className="modal-title">{this.props.title}</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
               
                <div className="row">
						<div className="col-lg-12 col-md-12">
								
                                <img src={this.props.url} alt="Connecting...please wait"/>
									
						</div>



				</div>
					
            
            </div>
           {!this.state.loading && 
           <div className="modal-footer">

                {/* <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button> */}
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopImageViewer;
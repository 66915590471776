import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

import MdlNewChargeWithdraw from '../Components/MdlNewChargeWithdraw'
import MdlNewChargeSend from '../Components/MdlNewChargeSend'
import MdlEditChargeSend from '../Components/MdlEditChargeSend'
import MdlEditChargeWithdraw from '../Components/MdlEditChargeWithdraw'
import $ from 'jquery';
import DeleteChargesWithdraw from '../Components/DeleteChargesWithdraw'
import DeleteChargesSend from '../Components/DeleteChargesSend'
import AddButton from '../Components/Common/AddButton'

class Settings extends React.Component {

    state={
        //userId:this.props.location.state.userId
        userId:functions.sessionGuard(),
        sendChargesList:false,
        withdrawChargesList:false,
        sendingChargeList:false
    }

    componentDidMount()
    {
        //console.log(this.state.userId)
        this.listWithdrawCharges();
        this.listSendingCharges();
    }

    listWithdrawCharges=async()=>{
        const server_response=await ajax.getWithdrawCharges();
        if(server_response.status==="OK")
        {
           this.setState({
            withdrawChargesList:server_response.details
           })

        }else{
          this.setState({
            withdrawChargesList:"404"
          })
        }
    }

    listSendingCharges=async()=>{
        const server_response=await ajax.getSendCharges();
        if(server_response.status==="OK")
        {
           this.setState({
            sendChargesList:server_response.details
           })

        }else{
          this.setState({
            sendChargesList:"404"
          })
        }
    }

    onSetWithdraw=()=>{
       
       this.listWithdrawCharges()
        /*
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        */
    }

    onSetSendCharge=()=>{
       this.listSendingCharges()
        /*
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        */
    }

    onDeleteSendCharge=()=>{
        this.listSendingCharges()
         
         $('.modal-backdrop').remove()
         $(document.body).removeClass("modal-open");
         
     }

     onDeleteWithdraw=()=>{
        this.listWithdrawCharges()
         $('.modal-backdrop').remove()
         $(document.body).removeClass("modal-open");
     }

    
    render(){
       
        const withdrawList=this.state.withdrawChargesList;
        const sendList=this.state.sendChargesList;

          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Settings"
                     previousPageTitle="System"
                  />

                        <MdlNewChargeWithdraw onFinish={this.onSetWithdraw}/>
                      <MdlNewChargeSend onFinish={this.onSetSendCharge}/>

                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">Settings & Preferences</h6>
										<p className="text-muted  card-sub-title">Adjust system charges, payouts intervals, etc.</p>
									</div>
									<div aria-multiselectable="true" className="accordion" id="accordion" role="tablist">
										<div className="card">
											<div className="card-header" id="headingOne" role="tab">
												<a aria-controls="collapseOne" aria-expanded="false" data-toggle="collapse" href="#collapseOne">Withdraw Charges</a>
											</div>
											<div aria-labelledby="headingOne" className="collapse" data-parent="#accordion" id="collapseOne" role="tabpanel">
												<div className="card-body">

                                            <AddButton mdl="mdl-new-withdraw-charge"/>

                                        <div className="table-responsive">
										<table className="table table-striped mg-b-0">
											<thead>
												<tr>
													<th>No.</th>
													<th>Min Amount (UGX)</th>
													<th>Max Amount (UGX)</th>
													<th>Charges (UGX)</th>
                                                    <th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
											{withdrawList && withdrawList!=="404" &&
                                                withdrawList.map((item, key)=><tr>
													<th scope="row">{key+1}</th>
													<td>{item.min.amount_c}</td>
													<td>{item.max.amount_c}</td>
													<td>{item.charge.amount_c}</td>
                                                    <td>
                                                    <Link to="#"><i className="fa fa-edit" title="Edit" 
                                                        data-target={"#mdl-edit-withdraw-charge"+item.charge_id} 
                                                        data-toggle="modal"></i>
                                                    </Link>
                                                    &nbsp; &nbsp;
                                                    <Link to="#"
                                                     data-target={"#mdl-delete-withdraw-charges"+item.charge_id} 
                                                     data-toggle="modal">
                                                        <i className="fa fa-trash" title="Delete"></i>
                                                    </Link>
                                                    </td>

                                                        <MdlEditChargeWithdraw id={item.charge_id} record={item} onFinish={this.onSetWithdraw}/>
                                                        <DeleteChargesWithdraw mdl={"mdl-delete-withdraw-charges"+item.charge_id} record={item} onFinish={this.onDeleteWithdraw}/>


												</tr>)}
												
											</tbody>
										</table>
                                        {!withdrawList && <ActivityLoader/>}
									</div>


                                                </div>
											</div>
										</div>
										<div className="card">
											<div className="card-header" id="headingTwo" role="tab">
												<a aria-controls="collapseTwo" aria-expanded="false" className="collapsed" data-toggle="collapse" href="#collapseTwo">Sending Charges</a>
											</div>
											<div aria-labelledby="headingTwo" className="collapse" data-parent="#accordion" id="collapseTwo" role="tabpanel">
												<div className="card-body">
                                                        
                                                <AddButton mdl="mdl-new-send-charge"/>

                                                        <div className="table-responsive">
                                                        <table className="table table-striped mg-b-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Min Amount (UGX)</th>
                                                                    <th>Max Amount (UGX)</th>
                                                                    <th>Charges (UGX)</th>
                                                                    <th>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {sendList && sendList!=="404" &&
                                                                sendList.map((item, key)=><tr>
                                                                    <th scope="row">{key+1}</th>
                                                                    <td>{item.min.amount_c}</td>
                                                                    <td>{item.max.amount_c}</td>
                                                                    <td>{item.charge.amount_c}</td>
                                                                    <td>
                                                                        <Link to="#"><i className="fa fa-edit" title="Edit" data-target={"#mdl-edit-send-charge"+item.charge_id} data-toggle="modal"></i></Link>
                                                                        &nbsp; &nbsp;
                                                                            <Link to="#"
                                                                                data-target={"#mdl-delete-send-charges"+item.charge_id} 
                                                                                data-toggle="modal">
                                                                                <i className="fa fa-trash" title="Delete"></i>
                                                                            </Link>
                                                                        </td>

                                                                            <MdlEditChargeSend id={item.charge_id} record={item} onFinish={this.onSetSendCharge}/>
                                                                            <DeleteChargesSend mdl={"mdl-delete-send-charges"+item.charge_id} record={item} onFinish={this.onDeleteSendCharge}/>

                                                                </tr>)}
                                                                
                                                            </tbody>
                                                        </table>
                                                        {!sendList && <ActivityLoader/>}
                                                        </div>

    											</div>
											</div>
										</div>
										<div className="card">
											<div className="card-header" id="headingThree" role="tab">
												<a aria-controls="collapseThree" aria-expanded="false" className="collapsed" data-toggle="collapse" href="#collapseThree">Agent Loan Limit</a>
											</div>
											<div aria-labelledby="headingThree" className="collapse" data-parent="#accordion" id="collapseThree" role="tabpanel">
												<div className="card-body">
                                                    Coming soon
												</div>
											</div>
										</div>
										<div className="card">
											<div className="card-header" id="headingfour" role="tab">
												<a aria-controls="collapsefour" aria-expanded="false" className="collapsed" data-toggle="collapse" href="#collapsefour">Commission Payout</a>
											</div>
											<div aria-labelledby="headingfour" className="collapse" data-parent="#accordion" id="collapsefour" role="tabpanel">
												<div className="card-body">
                                                    Coming soon
												</div>
											</div>
										</div>
										<div className="card">
											<div className="card-header" id="headingfive" role="tab">
												<a aria-controls="collapsefive" aria-expanded="false" className="collapsed" data-toggle="collapse" href="#collapsefive">Other Settings</a>
											</div>
											<div aria-labelledby="headingfive" className="collapse" data-parent="#accordion" id="collapsefive" role="tabpanel">
												<div className="card-body">
                                                    Not set
                                                </div>
											</div>
										</div>
										<div className="card">
											<div className="card-header" id="headingsix" role="tab">
												<a aria-controls="collapsesix" aria-expanded="false" className="collapsed" data-toggle="collapse" href="#collapsesix">Other Settings</a>
											</div>
											<div aria-labelledby="headingsix" className="collapse" data-parent="#accordion" id="collapsesix" role="tabpanel">
												<div className="card-body">
                                                        Not Set
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default Settings;
  
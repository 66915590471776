const dictionary={
    "_completeFields":"Complete all fields and try again",
    "_toastTimeOut":5000,
    "_places_key":"AIzaSyA6QSc8USmBvolg2HQBdQKoSm1hhHDU_yQ",
    "apiHost":"https://api.quickpost.araknerd.com/",
    //"apiHost":"http://127.0.0.1/api/api/quickpost/",
    //apiHost:"https://qptest.araknerd.com/"
    //  apiHost:"http://192.168.100.4/api/api/quickpost/"
    // apiHost:"http://10.10.10.71/api/api/quickpost/"
    // apiHost:"http://localhost/api/quickpost/"

    
}

export default dictionary;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import RightSideBar from '../../Components/Common/RightSideBar'
import DashTile from '../../Components/Common/DashTile'
import functions from '../../functions'
import CountPendInvitations from '../../Components/CashRound/CountPendInvitations'
import RecentCashRoundTransactions from '../../Components/CashRound/RecentCashRoundTransactions'
import RecentCashRounds from '../../Components/CashRound/RecentCashRounds'
import ajaxCashRound from '../../remote/ajaxCashRound'
import GroupMembers from '../../Components/CashRound/GroupMembers'

class CashRoundProfile extends React.Component {

    state = {
        userId: functions.sessionGuard(),
    
       
        groupId:false,
        groupInfo:false,
        groupName:"...",
        roundCount:"...",
        totalMembers:"...",
        membershipFee:"...",
        createdOn:"...",
        currentCollection:"...",
        payoutDate:"...",
        intervalStartDate:"",
        beneficiaryUserId:""

    }

    componentDidMount() {

        if(!this.props.location.state)
		{
			this.props.history.push('/cashround');
		}else{
			this.setState({
				groupId:this.props.location.state.groupId
			}, ()=>{
				this.getGroupInfo();
			})
        }
       
    }

    


    getGroupInfo=async()=>{
        const server_reponse = await ajaxCashRound.getGroupInfo(this.state.groupId);
        if(server_reponse.status==="OK")
        {
            this.setState({
                groupInfo:server_reponse.details,
            }, ()=>{
                const groupInfo=this.state.groupInfo;
                this.setState({
                    groupName:groupInfo.group_name,
                    roundCount:groupInfo.interval_count.count_p,
                    createdOn:groupInfo.date_created.short_date,
                    totalMembers:groupInfo.membership_count.total_p,
                    membershipFee:groupInfo.membership_fee.amount_c,
                    currentCollection:groupInfo.current_collection.amount_p,
                    payoutDate:groupInfo.interval_ends.short_date,
                    intervalStartDate:groupInfo.interval_starts.short_date,
                    beneficiaryUserId:groupInfo.next_beneficiary.user_id
                })
            })
        }else{
            this.setState({
                groupInfo:"404"
            })
        }
    }

   

    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success">
                    <i className="fas fa-circle mr-1"></i>Enable Collections
                </button>

                <button className="btn ripple btn-primary">
                    <i className="fas fa-tasks mr-1"></i> Payout Funds
                </button>
            </>
        )
    }

    render() {



        return (
            <div className="page">
              
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title={"Cash Round: " + this.state.groupName}
                            previousPageTitle="Cash Rounds"
                            options={this.state.menuOptions}
                            buttons={<this.buttons />}
                            back="/cashround"
                        />

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card custom-card">
                                    <div className="row">
                                        <DashTile title="Round Count" icon="fa fa-calendar" value={this.state.roundCount} color="mb-0 text-primary fs-13 ml-1" caption={"Since " + this.state.createdOn} />
                                        <DashTile title="Total Membership" icon="fa fa-users" value={this.state.totalMembers} color="mb-0 text-success fs-13 ml-1" caption={"Members "} />
                                        <DashTile title="Current Collection" icon="fa fa-university" value={this.state.currentCollection} color="mb-0 text-secondary fs-13 ml-1" caption={"@" + this.state.membershipFee +" UGX. Membership Fee "} />
                                        <DashTile title="Payout Date" icon="fa fa-calendar" value={this.state.payoutDate} color="mb-0 text-warning fs-13 ml-1" caption={"Since " + this.state.intervalStartDate} />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                           {this.state.groupId && <>
                            <RecentCashRoundTransactions groupId={this.state.groupId}/>
                            <GroupMembers groupId={this.state.groupId} targetMember={this.state.beneficiaryUserId}/>
                            </>}
                        </div>

                        
					{/* <div class="row row-sm">

                    <BarChartComponent />
						
					</div> */}

                    </div>
                </div>
                <RightSideBar />
                <Footer />
            </div>


        );

    }
}

export default CashRoundProfile

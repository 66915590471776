import React from 'react';
import ajax from '../ajax';
import functions from '../functions';
import ActivityLoader from './Common/ActivityLoader';
import SilentText from './Common/SilentText';


class RecentTransactions extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.getTransactions();
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details.records,
            
            })
        }else{
            this.setState({
                list:'404'
            
            })
        }
    }
  
  
  render(){
      const list=this.state.list;
    
      return (
        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">Recent transactions</h6>
                <p className="text-muted card-sub-title">Most recent wallet and business float transactions</p>
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
            <div className="table-responsive">
                <table className="table table-bordered text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Transaction Time</th>
                            <th>#TransID</th>
                            <th>Transactor Details</th>
                            <th>Amount</th>
                            {/*
                             <th>description</th>
                             <th>Account</th>
                            */}
                            <th>trans</th>
                        </tr>
                    </thead>
                    <tbody>

                    {list && list!=="404" && list.map((item, key)=>
                            <tr 
                            className="hot-tr"
                            key={key} title={item.description} style={{cursor:'default'}}>
                            <td>
                                {item.trans_date.short_date}
                                <br/><SilentText text={item.trans_date.time}/>

                            </td>
                            <td>
                                {item.trans_id}
                                <br/><SilentText text={item.user_info.role_id*1==2?"Business Float":"Wallet"}/>

                            </td>
                            <td>
                                {item.user_info.username}
                                <br/><SilentText text={item.user_info.first_name + ' ' + item.user_info.last_name}/>
                            </td>
                             <td>
                                 {item.amount.amount_c}
                                 <br/><SilentText text="UGX"/>
                             </td>

                            {/*
                            
                            <td>
                                <span className="d-inline-block text-truncate" style={{maxWidth:'200px'}}>
                                   {item.description}
                                </span>
                            </td>
                            <td>{item.account_name}</td>

                            */}
                        
                            <td>
                                {item.cash_in*1>0 && <i className="fa fa-arrow-down text-success mr-1"></i>}
                                {item.cash_out*1>0 && <i className="fa fa-arrow-up text-danger mr-1"></i>}
                                <br/><SilentText text={item.trans}/>

                            </td>

                        </tr>
                            )}	
                        
                    </tbody>
                </table>
                <br/>
                    {!this.state.list && <ActivityLoader/>}

            </div>
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default RecentTransactions;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import RightSideBar from '../../Components/Common/RightSideBar'
import DashTile from '../../Components/Common/DashTile'
import functions from '../../functions'
import CountCashround from '../../Components/CashRound/CountCashround'
import CountMembers from '../../Components/CashRound/CountMembers'
import CountPendInvitations from '../../Components/CashRound/CountPendInvitations'
import TotalGroupBalances from '../../Components/CashRound/TotalGroupBalances'
import RecentCashRoundTransactions from '../../Components/CashRound/RecentCashRoundTransactions'
import RecentCashRounds from '../../Components/CashRound/RecentCashRounds'

class CashRoundDashboard extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        userList: false,
        menuOptions: '',
        userRecord: '',
        selectedUserId: '',

        countUsers: '...',
        countRoles: '...',
        countPermissions: '...',
    }

    componentDidMount() {
       
    }

    componentWillUnmount() {
      
    }


   

    buttons = () => {
        return (
            <>
                <button className="btn ripple btn-success">
                    <i className="fas fa-circle mr-1"></i>Active Intervals
                </button>

                <button className="btn ripple btn-primary">
                    <i className="fas fa-tasks mr-1"></i> Defaulters
                </button>
            </>
        )
    }

    render() {

        return (
            <div className="page">
              
                <LeftSideBar />

                <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
                    <TopBar />
                    <div className="container-fluid">
                        <PageHeader
                            title="Cash Rounds"
                            previousPageTitle=""
                            options={this.state.menuOptions}
                            buttons={<this.buttons />}
                        />

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card custom-card">
                                    <div className="row">
                                        <CountCashround/>
                                        <CountMembers/>
                                        <TotalGroupBalances/>
                                        <CountPendInvitations/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <RecentCashRoundTransactions/>
 
                            <RecentCashRounds/>
                        </div>

                        
					{/* <div class="row row-sm">

                    <BarChartComponent />
						
					</div> */}

                    </div>
                </div>
                <RightSideBar />
                <Footer />
            </div>


        );

    }
}

export default CashRoundDashboard

//const apiHost='https://quickpostlab.araknerd.com/api@cashmoney/api/';

import dictionary from "./dictionary";

//const apiHost='https://api.quickpost.araknerd.com/';
const apiHost = dictionary.apiHost;

// const apiHost='https://qptest.araknerd.com/';
const ACCESS_TOKEN = localStorage.getItem('quickpost@user');

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'admin/login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanPackages() {


        try {
            let response = await fetch(apiHost + 'loan/packages',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getAgents(page) {

        let data = {
            "requester": "admin",
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'user/agents',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getWithdrawCharges() {

        let data = {
            "requester": "admin",
        }
        try {
            let response = await fetch(apiHost + 'charges/withdraw',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getSendCharges() {

        let data = {
            "requester": "admin",
        }
        try {
            let response = await fetch(apiHost + 'charges/sending',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async addSendCharges(lower_limit, upper_limit, amount) {

        let data = {
            "lower_limit": lower_limit,
            "upper_limit": upper_limit,
            "amount": amount
        }
        try {
            let response = await fetch(apiHost + 'charges/sending/create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async addWithdrawCharges(lower_limit, upper_limit, amount) {

        let data = {
            "lower_limit": lower_limit,
            "upper_limit": upper_limit,
            "amount": amount
        }
        try {
            let response = await fetch(apiHost + 'charges/withdraw/create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async deleteSendCharges(charge_id) {

        let data = {
            "charge_id": charge_id
        }
        try {
            let response = await fetch(apiHost + 'charges/sending/delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async deleteWithdrawCharges(charge_id) {

        let data = {
            "charge_id": charge_id
        }
        try {
            let response = await fetch(apiHost + 'charges/withdraw/delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async updateWithdrawCharges(charge_id, lower_limit, upper_limit, amount) {

        let data = {
            "lower_limit": lower_limit,
            "upper_limit": upper_limit,
            "amount": amount,
            "charge_id": charge_id
        }
        try {
            let response = await fetch(apiHost + 'charges/withdraw/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateSendCharges(charge_id, lower_limit, upper_limit, amount) {

        let data = {
            "lower_limit": lower_limit,
            "upper_limit": upper_limit,
            "amount": amount,
            "charge_id": charge_id
        }
        try {
            let response = await fetch(apiHost + 'charges/sending/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async getAgentProfile(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/agent/profile',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async getTokens() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'user/auth/tokens',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countAgents() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'user/agent/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countCustomers(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countWallet() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'wallet/general_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countLoans() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'loan/general_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getTransactions() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'wallet/transactions/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCustomers(page, agent_id) {

        let data = {
            "requester": "admin",
            "page": page,
            "agent_id": agent_id
        }
        try {
            let response = await fetch(apiHost + 'user/customers',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCustomerProfile(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/profile',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getWalletBalance(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'wallet/user_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanBalance(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/user_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoans(filter, page, user_id) {

        let data = {
            "filter": filter,
            "page": page,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/all_loans',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getWalletTransactions(page, user_id) {

        let data = {
            "page": page,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'wallet/transactions/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },

    async getWalletStatement(user_id) {

        let data = {
            'user_id': user_id
        }

        try {
            let response = await fetch(apiHost + 'wallet/statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },
    async getFloatStatement(user_id) {

        let data = {
            'user_id': user_id
        }

        try {
            let response = await fetch(apiHost + 'float/statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },
    async getCommissionStatement(user_id) {

        let data = {
            'user_id': user_id
        }

        try {
            let response = await fetch(apiHost + 'commission/statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },

    async getReserveTransactions(page) {

        let data = {
            'page': page
        }

        try {
            let response = await fetch(apiHost + 'reserve/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },

    async updateLoanPackage(package_id, package_name, duration, interest, points, max_amount) {

        let data = {
            'name': package_name,
            'package_id': package_id,
            'duration': duration,
            'interest': interest,
            'points': points,
            'package_amount': max_amount
        }

        try {
            let response = await fetch(apiHost + 'loan/packages/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },

    async getFloatTransactions(page, user_id) {

        let data = {
            'page': page,
            'user_id': user_id
        }

        try {
            let response = await fetch(apiHost + 'float/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Request could not be determined'] }
            return connError;
        }

    },

    async getFloatBalance(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'float/balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getGeneralFloatStatement(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'float/statement/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getGeneralWalletStatement(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'wallet/statement/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getGeneralReserveStatement(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'reserve/statement/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCommissionTransactions(page, user_id) {

        let data = {
            "page": page,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'commission/transaction',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getGeneralCommissionStatement(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'commission/statement/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async searchUser(hint, admin_id) {

        let data = {
            "hint": hint,
            "admin_id": admin_id
        }
        try {
            let response = await fetch(apiHost + 'user/search',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countCapitalFloat() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'float/balance/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countBusinessFloat() {

        try {
            let response = await fetch(apiHost + 'wallet/business/balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async countCommissionBalance() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'commission/balance/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async countSecurityDeposit(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/security/balance/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countLoanFine(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'fine/balance/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countReserve() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'reserve/balance/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getReserveStatistics() {

        let data = {
            "requester": "admin"
        }
        try {
            let response = await fetch(apiHost + 'reserve/statistics',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getGeneralBusinessFloatStatement(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'wallet/business/statement/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async getBusinessFloatTransactions(page, user_id) {
        let data = {
            "page": page,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'wallet/business/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async loanInfo(loan_id) {
        let data = {
            "loan_id": loan_id
        }
        try {
            let response = await fetch(apiHost + 'loan/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async searchLoan(hint, admin_id) {

        let data = {
            "hint": hint,
            "admin_id": admin_id
        }
        try {
            let response = await fetch(apiHost + 'loan/search',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanStatisticsUnpaid() {

        let data = {
            "user_id": 0
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/upaid',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanStatisticsTodoList() {

        let data = {
            "user_id": 0
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/todolist',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanStatisticsPaidToday() {

        let data = {
            "user_id": 0
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/recovery/today',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async getLoanStatisticsPaidWeekly() {

        let data = {
            "user_id": 0
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/recovery/weekly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getOutstandingLoans(page, user_id) {

        let data = {
            "page": page,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/unpaid',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getUserAccess(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/admin/access',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRecentLoans(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRecentCustomers(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRecentAgents(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/agent/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalDailyLoans(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/daily',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalMonthlyLoans(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/principal/monthly',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalPaidPrincipal(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/paid_principal',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async totalDefaultedPrincipal(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/defaulted_principal',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countLoanRecords(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countBusinessLoanRecords(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/business/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countPersonalLoanRecords(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistis/personal/count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countDefaultedLoanRecords(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/defaulted_count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countOutstandingLoanRecords(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/outstanding_count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async filterAllLoans(user_id, page) {

        let data = {
            "client_id": user_id,
            "page": page,
            "user_id": "0"//token data
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/filter_all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async filterBusinessLoans(user_id, page) {

        let data = {
            "client_id": user_id,
            "page": page,
            "user_id": "0"//token data
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/filter_business',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async filterPersonalLoans(user_id, page) {

        let data = {
            "client_id": user_id,
            "page": page,
            "user_id": "0"//token data
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/filter_personal',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async filterDefaultedLoans(user_id, page) {

        let data = {
            "client_id": user_id,
            "page": page,
            "user_id": "0"//token data
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/filter_defaulted',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async filterOutstandingLoans(user_id, page) {

        let data = {
            "client_id": user_id,
            "page": page,
            "user_id": "0"//token data
        }
        try {
            let response = await fetch(apiHost + 'loan/transactions/filter_outstanding',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalPrincipalLoans(user_id, page) {

        let data = {
            "user_id": user_id,
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/total_principle',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalSecurityDeposits(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/security/total_deposits',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalPaidFine(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'fine/total_paid',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countDailyCustomers(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/new/daily_count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countCustomersUnderAgent(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/under_agent_count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async topCustomerRanking(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/customer/top_ranking',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countDailyAgents(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/agent/new/daily_count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async topAgentRanking(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/agent/top_ranking',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cummWalletBalance(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'wallet/statistics/cumm_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cummBusinessFloatBalance(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'wallet/business/cumm_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cummCapitalFloatBalance(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'float/statistics/cumm_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cummCommissionBalance(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'commission/statistics/cumm_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async resetTransactionKey(user_id) {

        let data = {
            "requester": "admin",
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/pin/hard_reset',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateCustomerLimit(user_id, amount) {

        let data = {
            "amount": amount,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/customer/limit/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateAgentLimit(user_id, amount) {

        let data = {
            "amount": amount,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/agent/limit/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async withdrawReserve(amount) {

        let data = {
            "amount": amount
        }
        try {
            let response = await fetch(apiHost + 'reserve/withdraw',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async purchaseFloatForAgent(phone, amount) {

        let data = {
            "amount": amount,
            "phone": phone
        }
        try {
            let response = await fetch(apiHost + 'float/purchase/for_agent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async deleteLoanFine(fine_id) {

        let data = {
            "fine_id": fine_id
        }
        try {
            let response = await fetch(apiHost + 'fine/transaction/delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getCommissionRecord(trans_id) {

        let data = {
            "trans_id": trans_id
        }
        try {
            let response = await fetch(apiHost + 'commission/transaction/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getWalletRecord(trans_id) {

        let data = {
            "trans_id": trans_id
        }
        try {
            let response = await fetch(apiHost + 'wallet/transaction/info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async settleLoan(user_id, amount) {

        let data = {
            "user_id": user_id,
            "amount": amount
        }
        try {
            let response = await fetch(apiHost + 'loan/settle',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async securePassword(user_id, password) {

        let data = {
            "admin_id": user_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'user/admin/set_password',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async lisDeviceLogs(page) {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'notification/transactions/device_logs',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateCustomerProfile(
        user_id,
        first_name,
        last_name,
        dob,
        phone,
        email,
        documents,
        gender,
        NIN,
        recommender,
        nextOfKin,
        verified
    ) {

        let data = {
            "first_name": first_name,
            "last_name": last_name,
            "dob": dob,
            "phone": phone,
            "email": email,
            "documents": documents,
            "user_id": user_id,
            "gender": gender,
            "NIN": NIN,
            "recommender": recommender,
            "next_of_kin": nextOfKin,
            "verified": verified
        }

        try {
            let response = await fetch(apiHost + 'user/customer/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listSecurityDepositTransactions(page) {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'loan/security/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listRecentSecurityDepositTransactions(page) {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'loan/security/transactions/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listOngoingSecurityDepositRequests(page)//POST
    {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'loan/security/requests/ongoing',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cancelSecurityDepositRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'loan/security/request/cancel',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async approveSecurityDepositRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'loan/security/request/approve',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async processSecurityDepositRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'loan/security/request/process',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalOngoingSecurityDepositRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'loan/security/request/total_ongoing',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalUpcomingSecurityDepositRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'loan/security/request/total_upcoming',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async totalOngoingFloatRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'float/request/total_ongoing',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalDailyFloatDeposits()//GET
    {

        try {
            let response = await fetch(apiHost + 'float/deposits/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalDailyFloatWithdraws()//GET
    {

        try {
            let response = await fetch(apiHost + 'float/withdraws/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async recentFloatTransactions()//GET
    {

        try {
            let response = await fetch(apiHost + 'float/transactions/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listOngoingFloatRequests(page)//POST
    {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'float/request/transactions/ongoing',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cancelFloatRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'float/request/transactions/cancel',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async approveFloatRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'float/request/transactions/approve',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async processFloatRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'float/request/transactions/process',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getUserSecurityBalance(user_id) {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'loan/security/user_balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateLogoutButton(user_id, value, password)//POST
    {

        let data = {
            "user_id": user_id,
            "value": value,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'ucontrol/logout/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateDocumentVerification(user_id, value, password)//POST
    {

        let data = {
            "user_id": user_id,
            "value": value,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'ucontrol/require_documents/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async updateLoanActivity(user_id, value, password)//POST
    {

        let data = {
            "user_id": user_id,
            "value": value,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'ucontrol/loan_activity/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getUserLastLocation(user_id)//POST
    {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'ucontrol/last_location',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateWalletActivity(user_id, value, password)//POST
    {

        let data = {
            "user_id": user_id,
            "value": value,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'ucontrol/wallet_activity/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async countAdminUsers()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/admin/count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countPermissions()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/admin/permissions/count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listAdminUsers()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/admin/users',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listPermissions()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/admin/permissions/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listUserPermissions(user_id)//POST
    {

        let data = {
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/admin/permissions/list_user',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async grantAccess(user_id, code_list)//POST
    {

        let data = {
            "user_id": user_id,
            "access_code_list": code_list
        }
        try {
            let response = await fetch(apiHost + 'user/admin/permissions/grant',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async registerAdmin(username, first_name, last_name)//POST
    {

        let data = {
            "username": username,
            "first_name": first_name,
            "last_name": last_name
        }
        try {
            let response = await fetch(apiHost + 'user/admin/register',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async createAccessCode(access_code, description)//POST
    {

        let data = {
            "access_code": access_code,
            "description": description
        }
        try {
            let response = await fetch(apiHost + 'user/admin/permissions/create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async removeUserAccess(user_id, access_code)//POST
    {

        let data = {
            "user_id": user_id,
            "access_code": access_code
        }
        try {
            let response = await fetch(apiHost + 'user/admin/permissions/disgrant',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async searchPermission(hint)//POST
    {

        let data = {
            "hint": hint
        }
        try {
            let response = await fetch(apiHost + 'user/admin/permissions/search',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async requestReserve(amount, reason, receiver_phone, password)//POST
    {

        let data = {
            "amount": amount,
            "reason": reason,
            "receiver_phone": receiver_phone,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'reserve/request/new',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalOngoingReserveRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/request/total_ongoing',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countReserveIncomeToday()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/deposits/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async countReserveExpensesToday()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/withdraws/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getRecentReserveTransactions()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/transactions/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listOngoingReserveRequests(page)//POST
    {

        let data = {
            "page": page
        }
        try {
            let response = await fetch(apiHost + 'reserve/request/ongoing_transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async cancelReserveRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'reserve/request/cancel',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async approveReserveRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'reserve/request/approve',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCollectionAccounts()//GET
    {

        try {
            let response = await fetch(apiHost + 'collection_accounts/list',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async processReserveRequest(request_id, account_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password,
            "account_id": account_id
        }
        try {
            let response = await fetch(apiHost + 'reserve/request/process',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countUnapprovedReserveRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/request/unapproved_count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },
    async countUnprocessedReserveRequests()//GET
    {

        try {
            let response = await fetch(apiHost + 'reserve/request/unprocessed_count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateWalletWithdraws(user_id, value, password)//POST
    {

        let data = {
            "user_id": user_id,
            "value": value,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'ucontroll/wallet_withdraw/update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listTodoListLoans(page, agent_id)//POST
    {

        let data = {
            "page": page,
            "agent_id": agent_id
        }
        try {
            let response = await fetch(apiHost + 'loan/todolist',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLoanTypes()//GET
    {

        try {
            let response = await fetch(apiHost + 'loan/types',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async filterTodoListLoans(page, date_from, date_to, sticker_type_id, keyword)//POST
    {

        let data = {
            "page": page,
            "loan_type_id": sticker_type_id,
            "keyword": keyword,
            "date_from": date_from,
            "date_to": date_to
        }
        try {
            let response = await fetch(apiHost + 'loan/todolist/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getYoFunds()//GET
    {

        try {
            let response = await fetch(apiHost + 'banking/balances/yo',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countGroupedBadLoans()//GET
    {

        try {
            let response = await fetch(apiHost + 'loan/badloan/count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listGroupedBadLoans(page, group_id)//POST
    {

        let data = {
            "page": page,
            "group_id": group_id
        }
        try {
            let response = await fetch(apiHost + 'loan/badloan/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async filterBadLoans(page, group_id, date_from, date_to, sticker_type_id, keyword)//POST
    {

        let data = {
            "page": page,
            "loan_type_id": sticker_type_id,
            "keyword": keyword,
            "date_from": date_from,
            "date_to": date_to,
            "group_id": group_id
        }
        try {
            let response = await fetch(apiHost + 'loan/badloan/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async filterCustomers(page, date_from, date_to, customer_type, keyword)//POST
    {

        let data = {
            "page": page,
            "customer_type": customer_type,
            "keyword": keyword,
            "date_from": date_from,
            "date_to": date_to
        }
        try {
            let response = await fetch(apiHost + 'user/customer/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async analyseWeeklyTransactions()//GET
    {

        try {
            let response = await fetch(apiHost + 'analysis/wallet/weekly_transactions',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getRelworxBalance()//GET
    {

        try {
            let response = await fetch(apiHost + 'banking/balances/relworx',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },



    async countProductDailySales()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/sale/daily_count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countProductMonthlySales()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/sale/monthly_count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalProductsDailySales()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/sale/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalProductsMonthlySales()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/sale/monthly_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalMonthlyCost()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/cost/monthly_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalDailyCost()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/cost/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async totalDailyGrossProfit()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/profit/daily_gross',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async totalMonthlyGrossProfit()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/profit/monthly_gross',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async analyseWeeklyProductSales()//GET
    {

        try {
            let response = await fetch(apiHost + 'analysis/product/weekly_sale',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async analyseMonthlyProductSales()//GET
    {

        try {
            let response = await fetch(apiHost + 'analysis/product/monthly_sale',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async recentProductSales()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/sale/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async monthlyBillsEarnings()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/earnings/monthly_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async monthlyAirtimeEarnings()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/earnings/airtime/monthly_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async monthlyCyberEarnings()//GET
    {

        try {
            let response = await fetch(apiHost + 'product/earnings/cyber/monthly_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countSupervisors()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/supervisor/count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async listSupervisors(page)//POST
    {

        let data = {
            "page": page,
        }
        try {
            let response = await fetch(apiHost + 'user/supervisor/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listAllTopupRequests(page)//POST
    {

        let data = {
            "page": page,
        }
        try {
            let response = await fetch(apiHost + 'wallet/bankdeposit/transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async cancelTopupRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'wallet/bankdeposit/cancel',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async approveTopupRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'wallet/bankdeposit/approve',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async processTopupRequest(request_id, password)//POST
    {

        let data = {
            "request_id": request_id,
            "password": password
        }
        try {
            let response = await fetch(apiHost + 'wallet/bankdeposit/process',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async countPendingTopups()//GET
    {

        try {
            let response = await fetch(apiHost + 'wallet/bankdeposit/pending_count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getUsersAccountBalance()//GET
    {

        try {
            let response = await fetch(apiHost + 'walletbusiness/total_balances',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getTotalWalletDailyDeposits()//GET
    {

        try {
            let response = await fetch(apiHost + 'walletbusiness/deposits/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCummWalletDeposits()//GET
    {

        try {
            let response = await fetch(apiHost + 'walletbusiness/deposits/cumm_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getTotalWalletDailyWithdraws()//GET
    {

        try {
            let response = await fetch(apiHost + 'walletbusiness/withdraws/daily_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getCummWalletWithdraws()//GET
    {

        try {
            let response = await fetch(apiHost + 'walletbusiness/withdraws/cumm_total',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listMobileMoneyDeposits(page)//POST
    {

        let data = {
            "page": page,
        }
        try {
            let response = await fetch(apiHost + 'walletbusiness/deposits/mobilemoney_transactions',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async clientPeriodicalLoanStatistics(client_id)//POST
    {

        let data = {
            "client_id": client_id,
        }
        try {
            let response = await fetch(apiHost + 'loan/statistics/user/periodical',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async countEmailAuth()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/activity/auth/email.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countPhoneAuth()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/activity/auth/phone.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listRecentPhoneAuth()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/activity/auth/phone/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listRecentEmailAuth()//GET
    {

        try {
            let response = await fetch(apiHost + 'user/activity/auth/email/recent',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Source': 'Admin'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },






    //////////////////////suppert tickets////////////

    async listOpenTickets() {
        //GET
        try {
            let response = await fetch(apiHost + "list/tickets", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async listClosedTickets() {
        //GET
        try {
            let response = await fetch(apiHost + "list/closed/tickets", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async updateTicketStatus(ticket_id, status) {
        let data = {
            ticket_id: ticket_id,
            status: status,
        };
        try {
            let response = await fetch(apiHost + "update/ticket/status", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async deleteDepartment(department_id) {
        let data = {
            department_id: department_id,
        };
        try {
            let response = await fetch(apiHost + "delete/department", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async createDepartment(department) {
        let data = {
            department: department,
        };
        try {
            let response = await fetch(apiHost + "create/department", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async createTicket(user_id, department_id, title, log) {
        let data = {
            user_id: user_id,
            department_id: department_id,
            title: title,
            log: log,
        };
        try {
            let response = await fetch(apiHost + "create/ticket", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async viewTicketInfo(ticket_id) {
        let data = {
            ticket_id: ticket_id
        };
        try {
            let response = await fetch(apiHost + "view/ticket/info", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async addLog(user_id, ticket_id, log) {
        let data = {
            ticket_id: ticket_id,
            user_id: user_id,
            log: log
        };
        try {
            let response = await fetch(apiHost + "add/log", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
                body: JSON.stringify(data),
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async listDepartments() {

        try {
            let response = await fetch(apiHost + "list/departments", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },

    async ticketStatistics() {

        try {
            let response = await fetch(apiHost + "tickets/statistics", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + ACCESS_TOKEN,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Source: "Admin",
                },
            });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { status: "conn", details: ["Contacting server...."] };
            return connError;
        }
    },


    async countEmailAuth()//GET
    {

        try{
            let response= await fetch(apiHost + 'user/activity/auth/email.count',
            {
                method: 'GET',
                headers: {
                     'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                }
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async countPhoneAuth()//GET
    {

        try{
            let response= await fetch(apiHost + 'user/activity/auth/phone.count',
            {
                method: 'GET',
                headers: {
                     'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                }
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listRecentPhoneAuth()//GET
    {

        try{
            let response= await fetch(apiHost + 'user/activity/auth/phone/recent',
            {
                method: 'GET',
                headers: {
                     'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                }
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

    async listRecentEmailAuth()//GET
    {

        try{
            let response= await fetch(apiHost + 'user/activity/auth/email/recent',
            {
                method: 'GET',
                headers: {
                     'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Source': 'Admin'
                }
            });

                let result= await response.json();

                return result;
            }catch(error)
            {
                let connError={'status':'conn', 'details':['Contacting server....']}
                return connError;
            }

    },

}
import React from 'react'

class Footer extends React.Component{

    render(){
        return(
            <div class="main-footer text-center">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
                        <span>Designed and Developed by{" "}
                            <a >ARAKNERD</a>.
                            </span>
						</div>
					</div>
				</div>
			</div>
        )
    }

}

export default Footer
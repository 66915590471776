import React from 'react';
import ajax from '../../ajax';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';

class PopNewUser extends React.Component {

    state={
        userId:functions.sessionGuard(),
        username:'',
        firstName:'',
        lastName:'',
        info:'',
        loading:false
    }

   

    onChangeUsername=(event)=>
    {
        this.setState({username:event.target.value})
    }

    onChangeFirstName=(event)=>
    {
        this.setState({firstName:event.target.value})
    }

    onChangeLastName=(event)=>
    {
        this.setState({lastName:event.target.value})
    }

    onConfirm= (event)=>{
        event.preventDefault();
        this.setState({
            info:''
        }, ()=>{

            const {username, firstName, lastName}=this.state
            if(username.length>0 && firstName.length>0 && lastName.length>0)
            {
               
                this.setState({
                    loading:true
                }, async()=>{
                    const server_response=await ajax.registerAdmin(username, firstName, lastName)
                    this.setState({
                        loading:false
                    })

                    if(server_response.status==="OK")
                    {
                        this.setState({
                            info:<AlertSuccess message={server_response.details[0]}/>
                          }, ()=>{
                            this.setState({
                                username:'',
                                firstName:'',
                                lastName:''
                            })
                            this.props.onConfirm();
                          })
                    }else{
                        this.setState({
                            info:<AlertError message={server_response.details[0]}/>
                          })
                    }

                })
                
            }else{
                this.setState({
                    info:<AlertError message={dictionary._completeFields}/>
                  })
                }

        })
        
    }

   
  render(){
    
      return (
        <div className="modal" id="modal_create_admin">
        <div className="modal-dialog modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Create Admin</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
           
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                        
               

                    <TextInput 
                        label="Username"
                        onChange={()=>this.onChangeUsername}
                        value={this.state.username}
                        />

                    <TextInput 
                            label="First Name"
                            onChange={()=>this.onChangeFirstName}
                            value={this.state.firstName}
                            />

                    <TextInput 
                            label="Last Name"
                            onChange={()=>this.onChangeLastName}
                            value={this.state.lastName}
                            />

                </form>

                <p><i>Send default password to user. User will be required to setup a new password upon first login</i></p>

            
            </div>
           {!this.state.loading && <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Create Admin</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopNewUser;
import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import MdlLoanStatement from '../Components/MdlLoanStatement'
import $ from 'jquery';



class UserPerformanceProfile extends React.Component {

    state={
        userId:functions.sessionGuard(),
        agentInfo:false,
        agentId:false,
        statement:false,

        
      }

      componentDidMount()
      {
        if(!this.props.location.state)
		{
			this.props.history.push('/agent-performance');
		}else{
			this.setState({
				agentId:this.props.location.state.agentId
			}, ()=>{
                this.getAgentProfile();
            })
        }
      }


      getAgentProfile=async()=>{
        const server_response=await ajax.getAgentProfile(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
              agentInfo:server_response.details
           })

        }else{
          this.setState({
              agentInfo:"404"
          })
        }
    }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:record.user.user_id}
        })
      

      }
      

      onClickLoan=(record)=>{
        this.setState({
          statement:false
        }, ()=>{
          this.setState({
            statement:<MdlLoanStatement loan={record}/>
          }, ()=>{
            $("#modal_loan").modal('show');
          })
        })
      }


    
    render(){
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Performance Profile"
                     previousPageTitle="Loans"
                  />
                   {this.state.statement}
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">

{!this.state.agentInfo && <ActivityLoader/>}

{this.state.agentInfo && this.state.agentInfo!=="404" &&
<div className="card-body">
    <div>
        <h6 className="card-title mb-1">
            {this.state.agentInfo.user.first_name + ' ' + this.state.agentInfo.user.last_name}
        </h6>
        <p className="text-muted  card-sub-title">User Loan Performance</p>
    </div>
    <div aria-multiselectable="true" className="accordion accordion-indigo" id="accordion" role="tablist">
        <div className="card">
            <div className="card-header" id="headingOne" role="tab">
                <a aria-controls="collapseOne" className="collapsed text-uppercase small-caps" aria-expanded="false" data-toggle="collapse" href="#collapseOne">
                    Active Loans
                    <span style={{float:'right'}}>
                        {this.state.agentInfo.agent.performance.ACTIVE.total.percent + '% ('+this.state.agentInfo.agent.performance.ACTIVE.total.total_c + ')'}
                    </span>
                </a>
                
            </div>
            <div aria-labelledby="headingOne" className="collapse" data-parent="#accordion" id="collapseOne" role="tabpanel">
                <div className="card-body">

        <div className="table-responsive">
        <table className="table table-striped mg-b-0">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Loan ID</th>
                    <th>Loan Date</th>
                    <th>Deadline</th>
                    <th>Last Payment</th>
                    <th>Total Loan</th>
                    <th>Loan Balance</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.agentInfo.agent.performance.ACTIVE.list.map((item, key)=>
                        <tr>
                            <td>{key+1}</td>
                            <td>
                            <a 
                                className="link" 
                                to="#"
                                onClick={()=>this.onClickLoan(item)}
                                    >
                                {item.loan_code}
                            </a> 
                            </td>
                            <td>{item.loan_date.short_date}</td>
                            <td>{item.deadline.long_date}</td>
                            <td>{item.last_payment && item.last_payment.date_paid.long_date}</td>
                            <td>{item.total_loan.amount_c}</td>
                            <td>{item.loan_balance.amount_c}</td>
                        </tr>
                    )}
                
            </tbody>
        </table>
    
    </div>


                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingTwo" role="tab">
                <a aria-controls="collapseTwo" aria-expanded="false" className="collapsed text-uppercase small-caps" data-toggle="collapse" href="#collapseTwo">
                    Defaulted Loans
                    <span style={{float:'right'}}>
                        {this.state.agentInfo.agent.performance.DEFAULTED.total.percent + '% ('+ this.state.agentInfo.agent.performance.DEFAULTED.total.total_c + ')'}
                    </span>

                </a>
            </div>
            <div aria-labelledby="headingTwo" className="collapse" data-parent="#accordion" id="collapseTwo" role="tabpanel">
                <div className="card-body">
                        
            
        <div className="table-responsive">
          <table className="table table-striped mg-b-0">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Loan ID</th>
                    <th>Loan Date</th>
                    <th>Deadline</th>
                    <td>Last Payment</td>
                    <th>Total Loan</th>
                    <th>Loan Balance</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.agentInfo.agent.performance.DEFAULTED.list.map((item, key)=>
                        <tr>
                            <td>{key+1}</td>
                            <td>
                            <a 
                                className="link" 
                                to="#"
                                onClick={()=>this.onClickLoan(item)}
                                    >
                                {item.loan_code}
                            </a> 
                            </td>
                            <td>{item.loan_date.short_date}</td>
                            <td>{item.deadline.long_date}</td>
                            <td>{item.last_payment && item.last_payment.date_paid.long_date}</td>
                            <td>{item.total_loan.amount_c}</td>
                            <td>{item.loan_balance.amount_c}</td>
                        </tr>
                    )}
                
            </tbody>
        </table>
    
                        </div>

                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingThree" role="tab">
                <a aria-controls="collapseThree" aria-expanded="false" className="collapsed text-uppercase small-caps" data-toggle="collapse" href="#collapseThree">
                    Loans Paid Before Deadline
                    <span style={{float:'right'}}>
                        {this.state.agentInfo.agent.performance.PREDEADLINE.total.percent + '% ('+this.state.agentInfo.agent.performance.PREDEADLINE.total.total_c + ')'}
                    </span>
                    </a>
            </div>
            <div aria-labelledby="headingThree" className="collapse" data-parent="#accordion" id="collapseThree" role="tabpanel">
                <div className="card-body">
                <div className="table-responsive">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Loan ID</th>
                                    <th>Loan Date</th>
                                    <th>Deadline</th>
                                    <th>Last Payment</th>
                                    <th>Total Loan</th>
                                    <th>Loan Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.agentInfo.agent.performance.PREDEADLINE.list.map((item, key)=>
                                        <tr>
                                            <td>{key+1}</td>
                                            <td>
                                            <a 
                                                className="link" 
                                                to="#"
                                                onClick={()=>this.onClickLoan(item)}
                                                    >
                                                {item.loan_code}
                                            </a> 
                                            </td>
                                            <td>{item.loan_date.short_date}</td>
                                            <td>{item.deadline.long_date}</td>
                                            <td>{item.last_payment.date_paid.long_date}</td>
                                            <td>{item.total_loan.amount_c}</td>
                                            <td>{item.loan_balance.amount_c}</td>
                                        </tr>
                                    )}
                                
                            </tbody>
                        </table>
    
                </div>

                
                
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingfour" role="tab">
                <a aria-controls="collapsefour" aria-expanded="false" className="collapsed text-uppercase small-caps" data-toggle="collapse" href="#collapsefour">
                    Loans Paid On Deadline
                    <span style={{float:'right'}}>
                        {this.state.agentInfo.agent.performance.DEADLINE.total.percent + '% ('+ this.state.agentInfo.agent.performance.DEADLINE.total.total_c + ')'}
                    </span>

                </a>
            </div>
            <div aria-labelledby="headingfour" className="collapse" data-parent="#accordion" id="collapsefour" role="tabpanel">
                <div className="card-body">
                <div className="table-responsive">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Loan ID</th>
                                    <th>Loan Date</th>
                                    <th>Deadline</th>
                                    <th>Last Payment</th>
                                    <th>Total Loan</th>
                                    <th>Loan Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.agentInfo.agent.performance.DEADLINE.list.map((item, key)=>
                                        <tr>
                                            <td>{key+1}</td>
                                            <td>
                                            <a 
                                                className="link" 
                                                to="#"
                                                onClick={()=>this.onClickLoan(item)}
                                                    >
                                                {item.loan_code}
                                            </a> 
                                            </td>
                                            <td>{item.loan_date.short_date}</td>
                                            <td>{item.deadline.long_date}</td>
                                            <td>{item.last_payment.date_paid.long_date}</td>
                                            <td>{item.total_loan.amount_c}</td>
                                            <td>{item.loan_balance.amount_c}</td>
                                        </tr>
                                    )}
                                
                            </tbody>
                        </table>
    
                </div>

                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingfive" role="tab">
                    <a aria-controls="collapsefive" aria-expanded="false" className="collapsed text-uppercase small-caps" data-toggle="collapse" href="#collapsefive">
                        Loans Paid After Deadline
                        <span style={{float:'right'}}>
                            {this.state.agentInfo.agent.performance.POSTDEADLINE.total.percent + '% ('+this.state.agentInfo.agent.performance.POSTDEADLINE.total.total_c + ')'}
                        </span>

                    </a>
            </div>
            <div aria-labelledby="headingfive" className="collapse" data-parent="#accordion" id="collapsefive" role="tabpanel">
                <div className="card-body">
                <div className="table-responsive">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Loan ID</th>
                                    <th>Loan Date</th>
                                    <th>Deadline</th>
                                    <th>Last Payment</th>
                                    <th>Total Loan</th>
                                    <th>Loan Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.agentInfo.agent.performance.POSTDEADLINE.list.map((item, key)=>
                                        <tr>
                                            <td>{key+1}</td>
                                            <td>
                                            <a 
                                                className="link" 
                                                to="#"
                                                onClick={()=>this.onClickLoan(item)}
                                                    >
                                                {item.loan_code}
                                            </a> 
                                            </td>
                                            <td>{item.loan_date.short_date}</td>
                                            <td>{item.deadline.long_date}</td>
                                            <td>{item.last_payment.date_paid.long_date}</td>
                                            <td>{item.total_loan.amount_c}</td>
                                            <td>{item.loan_balance.amount_c}</td>
                                        </tr>
                                    )}
                                
                            </tbody>
                        </table>
    
                </div>

                </div>
            </div>
        </div>
        
    </div>
</div>
}


</div>


                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default UserPerformanceProfile;
  
import React from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: '2px dotted green',
      color: state.isSelected ? '#FFFFFF' : 'black',
    //backgroundColor: state.isSelected ? '#1c1c38' : 'white',
   // color: '#000000',
    textAlign:'left',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor:'#FFFFFF',
    color: '#000000',
   // marginTop: "5%",
  }),
  singleValue:(provided)=>({
    ...provided,
    color: '#000000'
  })
}


class SelectSearchWrap extends React.Component {
  

  render(){
    
      return ( 
        <div className="row row-xs align-items mg-b-20">
             <div className="col-md-12 text-left">
                <label className="mg-b-0">{this.props.label}<span className="text-danger">{this.props.required?"*":""}</span></label>
            </div>
            <div className="col-md-12 mg-t-0">
                <Select 
                    options={this.props.options}  
                    className="form-input" 
                    onChange={this.props.onChange()} 
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    styles = { customStyles }
                />
            </div>
        </div>
      );
    
 
  }
}

export default SelectSearchWrap;

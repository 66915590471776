import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class CommissionTransactions extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        page:1,
       
      }

      componentDidMount()
      {
        this.listTransactions();
      }


      
      static getDerivedStateFromProps(nextProps, prevState) {
        
        return {
          clientId: nextProps.location.state?nextProps.location.state.userId:0,
          clientInfo:nextProps.location.state?nextProps.location.state.userInfo:false,
        };
       }

       componentDidUpdate(nextProps, prevState) {
       
        if(nextProps.location.state!==undefined)
        {
         
          if(nextProps.location.state.userId!==this.state.clientId)
          {
            this.setState({
              page:1,
              transactionList:false,
              metaData:false
            }, ()=>{
              this.listTransactions();

            })

          }
        }
           
      }

      DocHeader=()=>{
        if(this.state.clientInfo)
        {
            if(this.state.clientInfo.role_id==="2")
            {
              return(
                <div>
                <h6 className="card-title mb-1">
                Commission - {this.state.clientInfo.first_name + ' ' + this.state.clientInfo.last_name}
                </h6>
                <p className="text-muted card-sub-title">All Commission transactions for agent.</p>
              </div>
              )
            }else{
              return(
              <div>
              <h6 className="card-title mb-1">
              Commission - {this.state.clientInfo.first_name + ' ' + this.state.clientInfo.last_name}
              </h6>
              <p className="text-muted card-sub-title">All Commission transactions for customer.</p>
            </div>)
            }

        }else{
          return(
            <div>
            <h6 className="card-title mb-1">Commission</h6>
            <p className="text-muted card-sub-title">All Commission transactions to agents and customers.</p>
          </div>
          )
        }
      }


      listTransactions=async()=>{
          const server_response=await ajax.getCommissionTransactions(this.state.page, this.state.clientId);
         // console.log(server_response.details)
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta
             })

          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            transactionList:false

          }, ()=>{
            this.listTransactions();
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            transactionList:false

          }, ()=>{
            this.listTransactions();
          })
        }

      }

    

    
    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Commission"
                     previousPageTitle="Transactions"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                          <this.DocHeader/>
                          <div className="table-responsive">
                            <table className="table table-bordered text-nowrap mb-0 table-ellipsis">
                              <thead>
                                <tr>
                                <th>Date</th>
                                <th>TransID</th>
                                <th>Client/User</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Flow</th>
                                </tr>
                              </thead>
                              <tbody>

                                  {list && list!=="404" && list.map((item, key)=>
                                    <tr key={key}>
                                    <td>{item.trans_date.long_date}</td>
                                    <td>{item.trans_id}</td>
                                    <td>
                                        {item.user_info.first_name + ' ' + item.user_info.last_name + '(' + item.user_info.phone + ')' }
                                    </td>
                                    <td>
                                      {item.description}
                                    </td>
                                      <td>{item.amount.amount_c}</td>
                                      <td>
                                                {item.cash_in.amount*1>0 && <i className="fa fa-arrow-down text-success mr-1"></i>}
                                                {item.cash_out.amount*1>0 && <i className="fa fa-arrow-up text-danger mr-1"></i>}
                                        </td>

                                  </tr>
                                    )}	
                                
                              </tbody>
                            </table>

                                {!this.state.transactionList && <ActivityLoader/>}
                                {metaData && <nav className="float-right">
                                    <ul className="pagination">
                                    <li className="page-item">
                                    <Link className="page-link" to="#" onClick={this.onClickPrevious}>Previous</Link>
                                    </li>
                                    <li className="page-item"><a className="page-link">{metaData.current_page}</a></li>
                                    <li className="page-item"><a className="page-link">of</a></li>
                                    <li className="page-item"><a className="page-link">{metaData.total_pages}</a></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={this.onClickNext}>Next</Link>
                                        </li>
                                    </ul>
                                </nav>}

                          </div>
                          </div>
                          </div>
                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>);  
   
    }
  }
  
  export default CommissionTransactions;
  
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from './../Common/ActivityLoader';
import MdlLoanStatement from './../MdlLoanStatement';
import $ from 'jquery';
import TableHeader from '../Common/TableHeader';


class RecentReserveTransactions extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        statement:false,
        selectedLoanId:''
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_response=await ajax.getRecentReserveTransactions();
         // console.log(server_response.details)
          if(server_response.status==="OK")
          {
             this.setState({
                list:server_response.details.list,
             })

          }else{
            this.setState({
                list:"404"
            })
          }
    }

    onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }

    onClickLoan=(record)=>{
        this.setState({
          statement:false
        }, ()=>{
          this.setState({
            statement:<MdlLoanStatement loan={record}/>
          }, ()=>{
            $("#modal_loan").modal('show');
          })
        })
      }


  
  render(){
      const list=this.state.list;
     
      return (
        <div className="card custom-card">
            {this.state.statement}
        <div className="card-body">
            <TableHeader
                title="Recent Transaction"
                subtitle="Recently deposited/withdrawn reserve cash"
                link="/reserve-transactions"
                //onClick={this.props.onClickViewAll}
            />
            <div className="table-responsive">
            <table className="table table-hover text-wrap mb-0">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>TransID</th>
                                    <th>Trans Ref</th>
                                    <th>Client/User</th>
                                    <th>Account</th>
                                    <th>Amount</th>
                                    <th>Flow</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {list && list!=="404" && list.map((item, key)=>
                                      <tr key={key}>
                                      <td>{item.trans_date.short_date}</td>
                                      <td>{item.trans_id}</td>
                                      <td>{item.ref_id}</td>
                                      <td>
                                          {item.user_info.phone}
                                      </td>
                                      <td>{item.account_name}</td>
                                        <td>{item.amount.amount_c}</td>
                                        <td>
                                                {item.cash_in*1>0 && <i className="fa fa-arrow-down text-success mr-1"></i>}
                                                {item.cash_out*1>0 && <i className="fa fa-arrow-up text-danger mr-1"></i>}
                                        </td>
                                                            
                                  </tr>
                                                              )}	
                                  
                                </tbody>
                              </table>

                {!list && <ActivityLoader/>}
               
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default RecentReserveTransactions;
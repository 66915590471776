import React from 'react';
import TextInput from './Common/TextInput';
import ajax from '../ajax';
import AlertSuccess from './Common/AlertSuccess';
import AlertError from './Common/AlertError';
import dictionary from '../dictionary';


class MdlEditLoanPackage extends React.Component {
  state={
      info:'',
      packageName:'',
      duration:'',
      interest:'',
      points:'',
      maxAmount:'',
      packageId:'',
      data:this.props.record
  }

  componentDidMount()
  {
      this.setState({
        packageName:this.state.data.package_name,
        duration:this.state.data.loan_duration,
        interest:this.state.data.interest,
        points:this.state.data.loan_points,
        maxAmount:this.state.data.max_amount.amount,
        packageId:this.state.data.package_id
      })
  }


  onChangePackageName = (event) => {
    this.setState({packageName: event.target.value});
  }

  onChangeDuration = (event) => {
    this.setState({duration: event.target.value});
  }

  onChangeInterest = (event) => {
    this.setState({interest: event.target.value});
  }

  onChangePoints = (event) => {
    this.setState({points: event.target.value});
  }

  onChangeAmount = (event) => {
    this.setState({maxAmount: event.target.value});
  }

  onSave=async(event)=>{
    
    event.preventDefault();

    const {packageId, packageName, duration, interest, points, maxAmount}=this.state
   
    if(packageName.length>0 && 
      duration.length>0 && 
      interest.length>0 &&
      points.length>0 &&
      maxAmount.length>0
      )
    {

        const server_response=await ajax.updateLoanPackage(
            packageId,
            packageName,
            duration,
            interest,
            points,
            maxAmount
        );
        if(server_response.status==="OK")
        {
           this.setState({
             info:<AlertSuccess message={server_response.details[0]}/>
           })
           this.props.onFinish()
        }else{
          this.setState({
            info:<AlertError message={server_response.details[0]}/>
          })
        }

    }else{
      this.setState({
        info:<AlertError message={dictionary._completeFields}/>
      })
    }
  }

  render(){
    
      return (
        <div className="modal" id={"mdl-edit-loan-package" + this.props.id}>
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Edit Loan Package</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onSave}>
										
                                       
                                          <TextInput 
                                            label="Package Name"
                                            type="text"
                                            onChange={()=>this.onChangePackageName}
                                            value={this.state.packageName}
                                            />
    
                                            <TextInput 
                                                label="Duration"
                                                type="number"
                                                onChange={()=>this.onChangeDuration}
                                                value={this.state.duration}
                                                />

                                            <TextInput 
                                                label="Interest"
                                                type="number"
                                                onChange={()=>this.onChangeInterest}
                                                value={this.state.interest}
                                                />

                                            <TextInput 
                                                label="Loan Points"
                                                type="number"
                                                onChange={()=>this.onChangePoints}
                                                value={this.state.points}
                                                />

                                            <TextInput 
                                                label="Max. Amount"
                                                type="number"
                                                onChange={()=>this.onChangeAmount}
                                                value={this.state.maxAmount}
                                                />
                                    </form>
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-primary" type="button" value="Save Changes" onClick={this.onSave}/>
                    <input className="btn ripple btn-secondary" data-dismiss="modal" type="button" value="Close"/>
                </div>
            </div>
        </div>
    </div>
  )}
      
}

export default MdlEditLoanPackage;
import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';


class FilterPersonalLoans extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    value:'...',
    title:'Personal Loans',
    filter:'PERSONAL'
  }
  async componentDidMount()
  {
    await this.requestRecord();
    this.timer = setTimeout(this.func=async()=> {
      await this.requestRecord();
      this.timer = setTimeout(this.func, 2000); // (*)
    }, 2000);
  }

  componentWillUnmount()
  {
    clearTimeout(this.timer)
  }

  requestRecord=async()=>{
    const server_response=await ajax.countPersonalLoanRecords(this.state.userId);
    if(server_response.status==="OK")
    {
      this.setState({
        value:server_response.details.total_c
      })
    }
  }

  render(){
      return (
        <button 
          type="button" 
          className="btn ripple btn-success btn-sm"
          onClick={()=>this.props.onClick(this.state.filter, this.state.title)}>
          {this.state.title} <span className="badge badge-danger ml-2">{this.state.value}</span>
        </button>
      );
    
 
  }
}

export default FilterPersonalLoans;
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';


class BadLoanCountCards extends React.Component {
  
    state={
        statistics:false
    }

    async componentDidMount()
    {
        await this.countBadLoans();
        this.timer= setTimeout(this.func=async()=> {
            await this.countBadLoans()
            this.timer = setTimeout(this.func, 5000); // (*)
          }, 5000);
    }

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }

    componentWillUnmount()
    {
       clearTimeout(this.timer)
    }

    countBadLoans=async()=>
    {
        const server_reponse=await ajax.countGroupedBadLoans();
        if(server_reponse.status==="OK")
        {
            this.setState({
               statistics:server_reponse.details
            })
        }
    }

   
  render(){

   // const accessInfo=this.state.accessInfo
   const list=this.state.statistics || []
    
      return (

        <div className="row">
         <div className="col-md-12">
           <div className="card custom-card">
                <div className="row">

                   {list.map((item, key)=>
                   
                    <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right" key={key}>
                        <div className="card-body text-center">
                                <h6 className="mb-0">{item.group_details.group_name}</h6>
                                <h2 className="mb-1 mt-2 number-font"><span className="counter">{item.count.total_c}</span></h2>
                                <p className="mb-0 text-muted">
                                    <Link 
                                        to={
                                            {
                                                pathname:"/loans/classification/" + item.group_details.group_name,
                                                state:{groupId:item.group_details.group_id}
                                            }
                                        }
                                    >
                                    <span className="mb-0 text-danger fs-13 ml-1">
                                        <i className="fe fe-arrow-down"></i>
                                    </span> {item.group_details.description}
                                    </Link>
                                </p>
                            </div>
                        </div>
                   
                   )}
                    
                  
                </div>
            </div>
        </div>
    </div>


       
      );
    
 
  }
}

export default BadLoanCountCards;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import SilentText from '../../Components/Common/SilentText';
import PageNav from '../../Components/Common/PageNav'


class Customers extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        page:1,
        agentId:0,
        signingAgent:false,
        selectedTransId:false

      }


      componentDidMount()
      {
        if(!this.props.location.state)
        {
          this.listTransactions();
        }else{
          this.setState({
            agentId:this.props.location.state.agent_id
          }, 
          ()=>{
                this.listTransactions();
                })
            }
        
      }


      listTransactions=async()=>{
          const server_response=await ajax.getCustomers(this.state.page, this.state.agentId);
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta,
                signingAgent:server_response.details.signing_agent
             }, ()=>this.paginate())

          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:record.user.user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


     
    docHeader=()=>{
      if(this.state.signingAgent)
      {
        return(
          <div>
            <h6 className="card-title mb-1">Agent's Customer Records</h6>
        <p className="text-muted card-sub-title">List of all Quickpost customers under {this.state.signingAgent.first_name + ' ' + this.state.signingAgent.last_name}</p>
          </div>
        )
      }else{
        return(
          <div>
              <h6 className="card-title mb-1">Customer Records</h6>
              <p className="text-muted card-sub-title">List of all Quickpost customers</p>
					</div>
        )
      }
    }


    //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================


    onClickRow=(selectedTransId)=>{
      this.setState({
        selectedTransId
      })
    }

    
    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Customer Records"
                     previousPageTitle="Customers"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <this.docHeader/>
                            <div className="table-responsive scroll-widget border-top overflow-auto">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>#No</th>
                                    <th>Name</th>
                                    <th>Gender</th>
                                    <th>Contact</th>
                                    <th>Username</th>
                                    <th>NIN</th>
                                    <th>Birthday</th>
                                    <th>Signing Agent</th>
                                    <th>Address</th>
                                    <th>Reg Date</th>
                                    <th>Has Device</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                    <tr onClick={()=>this.onClickRow(item.user.user_id)} key={key} className={`${this.state.selectedTransId==item.user.user_id?'flag-row':''} hot-tr`}>
                                    <td>{metaData.offset_count+key+1}</td>
                                    <td>

                                        <Link 
                                          className="orange" 
                                          to={
                                            {
                                                pathname:`/customer-profile/${item.user.user_id}`,
                                                state:{customerId:item.user.user_id}
                                            }
                                          } 
                                          >
                                          {item.user.last_name}
                                          </Link><br/>
                                          <SilentText text={item.user.first_name}/>
                                    </td>
                                    <td>{item.user.gender}</td>
                                  
                                    <td>
                                      {item.user.phone}
                                      {item.user.alt_phone?" / "+item.user.alt_phone:""}
                                      <br/>
                                          <SilentText text={item.user.email=="none"?"No Email":item.user.email }/>
                                      </td>
                                    <td>
                                        {item.user.username}
                                        <br/>
                                        <SilentText text={item.user.role_name}/>
                                    </td>
                                    <td>{item.user.NIN}</td>
                                    <td>{item.user.dob.db=="0000-00-00"?"Not Provided":item.user.dob.picker}</td>
                                    <td>
                                        {item.member.agent_id*1>0 && <>
                                            <Link 
                                                className="orange" 
                                                to={
                                                  {
                                                      pathname:`/agent-profile/${item.member.agent_id}`,
                                                      state:{agentId:item.member.agent_id}
                                                  }
                                              } 
                                              >{item.member.agent_info.phone}</Link>
                                            <br/>
                                              <SilentText text={item.member.agent_info.last_name + ' ' + item.member.agent_info.first_name}/>
                                            </>
                                        }
                                    </td>
                                    <td><SilentText text={item.user.address.address}/></td>
                                    <td>
                                      {item.user.since.short_date}<br/>
                                      <SilentText text={item.user.since.time}/>
                                      </td>
                                      <td>{item.user.device?"YES":"NO"}</td>
                                  </tr>
                                  )}	
                                  
                                </tbody>
                              </table>

                                  {!this.state.transactionList && <ActivityLoader/>}


                                  


                                
                            </div>
                          </div>
                        </div>
						
                        {this.state.metaData && this.state.pagination}
                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default Customers;
  
import React from 'react';
import ajax from '../../ajax';
import ActivityLoader from '../Common/ActivityLoader';


class PopWalletInfo extends React.Component {
  state={
      loading:false,
      transactionList:false,
      walletList:false,
      origin:this.props.origin,
      transactionID:this.props.transactionID
  }


  componentDidMount()
  {
        this.listTransactions()
  }


  listTransactions=async()=>{

    switch(this.state.origin)
    {
        case "COMMISSION":
            var server_response=await ajax.getCommissionRecord(this.state.transactionID);
            if(server_response.status==="OK")
              {
                this.setState({
                      transactionList:server_response.details.trans_ref_id*1>0?server_response.details.trans_ref:"404",
                })
        
              }else{
                this.setState({
                  transactionList:"404",
                })
              }
        break;

        case "RESERVE":

        break;

        default:

            var server_response=await ajax.getWalletRecord(this.state.transactionID);
            if(server_response.status==="OK")
              {
                this.setState({
                      transactionList:server_response.details.list,
                })
        
              }else{
                this.setState({
                  transactionList:"404",
                })
              }

        break;


    }
    
      
    }

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  render(){
    console.log(this.state.transactionList)
    const transactionList=this.state.transactionList;
      return (
        <div className="modal" id="mdl-wallet-info">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Transaction Info({this.state.transactionID})</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">



                            {transactionList && transactionList!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Transaction Ref</label> 
                                                <span className="tx-medium">{transactionList.info.trans_id}</span>
                                            </div>
                                            <div>
                                                <label>Transaction Date</label> 
                                                <span className="tx-medium">
                                                {transactionList.info.trans_date.long_date}
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Cash In</label> 
                                                <span className="tx-medium">{transactionList.info.cash_in.amount_c}</span>
                                            </div>
                                            <div>
                                                <label>Cash Out</label> 
                                                <span className="tx-medium">{transactionList.info.cash_out.amount_c}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Description</label> 
                                                <span className="tx-medium">{transactionList.info.description}</span>
                                            </div>
                                           
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Transactor Name</label> 
                                                <span className="tx-medium">{transactionList.info.user_info.first_name + ' ' + transactionList.info.user_info.last_name}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                        

                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Contact</label> 
                                                <span className="tx-medium">{transactionList.info.user_info.phone}</span>
                                            </div>

                                            <div>
                                                <label>Role</label> 
                                                <span className="tx-medium">{transactionList.info.user_info.role_name}</span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}






                        {!this.state.transactionList && <ActivityLoader/>}



                            
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-primary" type="button" value="Approve"/>
                    <input className="btn ripple btn-secondary" data-dismiss="modal" type="button" value="Close"/>
                </div>
            </div>
        </div>
    </div>
     )
    
 
  }
}

export default PopWalletInfo;
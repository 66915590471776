import React from 'react'
import HollowPie from '../Statistics/HollowPie';
import TableHeader from '../Common/TableHeader';
import functions from '../../functions';
import ajax from '../../ajax';
import Badge from '../Common/Badge';
import ActivityLoader from '../Common/ActivityLoader';


class AirtimeEarningStat extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.monthlyAirtimeEarnings();
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details,
            
            })
        }
    }
  
  
  render(){
        const list=this.state.list;
        var reserveValues=[0, 0];
        var commissionValues=[0, 0];
        if(this.state.list)
        {
            reserveValues=[list.net_percentage, 100-list.net_percentage];
            commissionValues=[list.commission_percentage, 100-list.commission_percentage];
        
        }
       
        return (

            // <div className="col-sm-12 col-xl-4 col-lg-4">
            <div className="card custom-card overflow-hidden">
                <div className="card-body">
                   
                    <TableHeader
                        title="Monthly Airtime Earnings"
                        subtitle="Contrast between agent commission & company profit."
                    // view="View All"
                    // onClick={this.props.onClickViewAll}
                    />

                   {list && <div className="row mt-3">

                        <div className="col-6 col-md-6 text-center">
                            <div className="mb-2">
                                <HollowPie color="#e95053" values={reserveValues}/>
                            </div>
                            <p className="mb-1 tx-inverse">Company Profit</p>
                            <h4 className="mb-1">
                                <Badge title="UGX" type={6}/>
                                {list.net_profit.amount_c}</h4>
                            <span className="text-muted fs-12"><i className="fas fa-caret-up mr-1 text-success"></i>{list.net_percentage}% of Ugx. {list.charges.amount_c}</span>
                        </div>
                        <div className="col-6 col-md-6 text-center">
                            <div className="mb-2">
                                <HollowPie color="#8760fb" values={commissionValues}/>
                            </div>
                            <p className="mb-1 tx-inverse">Agent Commission</p>
                            <h4 className="mb-1">
                            <Badge title="UGX" type={6}/>
                            {list.commission.amount_c}</h4>
                            <span className="text-muted fs-12"><i className="fas fa-caret-up mr-1 text-danger"></i>{list.commission_percentage}% of Ugx. {list.charges.amount_c}</span>
                        </div>


                    </div>}

                    {!this.state.list && <ActivityLoader/>}

                </div>
            </div>
            // </div>
        )
    }

}

export default AirtimeEarningStat

import React from 'react';
class TextInput extends React.Component {
  

  render(){

    const inputType=this.props.type || "text"
    
      return (

        <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-3 text-left">
                <label class="mg-b-0">{this.props.label}</label>
            </div>
            <div class="col-md-9 mg-t-5 mg-md-t-0">
            {inputType!=="textarea" &&
              <input 
                type={ inputType || "text" } 
                className="form-control" 
                placeholder={this.props.label}
                onChange={this.props.onChange()}
                value={this.props.value}
                autoFocus={this.props.autoFocus}
                readOnly={this.props.readonly || false}/>}

                {inputType==="textarea" &&
                  <textarea 
                    type={ inputType } 
                    className="form-control" 
                    placeholder={this.props.label}
                    onChange={this.props.onChange()}
                    value={this.props.value}
                    readOnly={this.props.readonly || false}/>}
            
            
            </div>
        </div>
      );
    
 
  }
}

export default TextInput;
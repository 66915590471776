import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import TextInput from "../Common/TextInput";

class Departments extends Component {
    state = {
        departments: false,
        loading: false,
        message: false,
        add: false,
        department: '',
        btn: "Add Department",
        btn2: "Add Department",
        style: 'btn-success'
    }

    componentDidMount() {
        this.listDepartments();
    }

    listDepartments = async () => {
        this.setState({
            loading: true
        })
        const server_response = await ajax.listDepartments();
        this.setState({
            loading: false
        })

        if (server_response.status == 'OK') {
            this.setState({
                departments: server_response.details
            })
        } else {
            this.setState({
                departments: '404',
                message: server_response.message
            })
        }
    }

    deleteDepartment = async (id) => {

        if (!window.confirm("Are you sure you want to delete this item")) return;

        const server_response = await ajax.deleteDepartment(id);
        // console.log(server_response)
        if (server_response.status == 'OK') {
            this.setState({
                departments: false
            }, () => {
                this.listDepartments();
            })
        }
    }

    onChangeDepartment = (e) => {
        this.setState({ department: e.target.value })
    }

    onAddDepartment = async () => {
        if (!this.state.department) {
            alert("pliz fill the department field");
            return;
        }

        this.setState({
            btn2: "Adding ....."
        })

        const server_response = await ajax.createDepartment(this.state.department);

        this.setState({
            btn2: "Add Department",
            department: ""
        })

        console.log(server_response)

        if (server_response.status == 'OK') {
            this.setState({
                departments: false,
                message: false
            }, () => {
                this.listDepartments();
            })
        }
    }



    render() {
        const { departments } = this.state;
        return (
            <div className="modal" id="modaldemo4">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">Departments</h6>
                            <button
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {this.state.loading && <ActivityLoader />}

                        <div className="container mt-3">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#No</th>
                                            <th>Department</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {departments &&
                                            departments != "404" &&
                                            departments.map((item, key) => (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{item.department}</td>
                                                    <td className="text-danger">
                                                        {" "}
                                                        <i
                                                            className="fas fa-trash fa-lg"
                                                            onClick={() => this.deleteDepartment(item.department_id)}
                                                        ></i>
                                                    </td>{" "}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <p className="text-center text-danger">
                                    {this.state.message && this.state.message}
                                </p>
                            </div>

                            {this.state.add && <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-md-8">
                                        <TextInput
                                            label="Enter Department"
                                            type="text"
                                            onChange={() => this.onChangeDepartment}
                                            value={this.state.department}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <button
                                            className="btn btn-success"
                                            onClick={this.onAddDepartment}
                                        >
                                            {this.state.btn2}
                                        </button>
                                    </div>
                                </div>

                            </div>}
                        </div>

                        <div className="modal-footer">
                            <button
                                className={"btn ripple " + this.state.style}
                                type="button"
                                onClick={() => {
                                    if (this.state.add) {
                                        this.setState({ add: false, btn: "Add Department", style: 'btn-success' })
                                        return;
                                    }

                                    this.setState({ add: true, btn: "Close Form", style: 'btn-warning' })

                                }}
                            >
                                {this.state.btn}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Departments;

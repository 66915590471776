import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class LoanProfile extends React.Component {

    state={
        userId:functions.sessionGuard(),
        loanId:false,
        loanInfo:false
       
      }

      componentDidMount()
      {
        if(!this.props.location.state)
		{
			this.props.history.push('/all-loans');
		}else{
			this.setState({
				loanId:this.props.location.state.loan_id
			}, ()=>{
				this.loanInfo();
            })
        }
      }


      loanInfo=async()=>{
          const server_response=await ajax.loanInfo(this.state.loanId);
         // console.log(server_response.details)
          if(server_response.status==="OK")
          {
             this.setState({
                loanInfo:server_response.details
             })

          }else{
            this.setState({
                loanInfo:"404"
            })
          }
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


     
    

    
    render(){
        const info=this.state.loanInfo
          return (
			<div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Loan Profile"
                     previousPageTitle="Loans"
                  />
                  
                    <div className="row">
					{info && info!=="404" && <div className="col-sm-12 col-xl-12 col-lg-12">
                     

					<div className="card custom-card">
								<div className="card-body">
									<div className="d-lg-flex">
                                        <address>
                                        <h2 className="card-title mb-1">#{info.loan_code}</h2>

                                            {info.package_info.package_name}<br/>
                                            {info.package_info.duration + ' ' + info.package_info.units}
                                        </address>
										<div className="ml-auto">
											<p className="mb-1">
                                            <span className="font-weight-bold">Loan Date :</span>{info.loan_date.long_date}</p>
                                            <p className="mb-0"><span className="font-weight-bold">Deadline :</span>{ info.deadline.long_date }</p>
										</div>
									</div>
									<hr className="mg-b-40"/>
									<div className="row">
										<div className="col-lg-6">
											<p className="h3">Agent:</p>
											<address>
												{info.agent_info.first_name + ' ' + info.agent_info.last_name}<br/>
												{info.agent_info.phone}<br/>
												
											</address>
										</div>
										<div className="col-lg-6 text-right">
											<p className="h3">Customer:</p>
											<address>
                                            {info.customer_info.first_name + ' ' + info.customer_info.last_name}<br/>
                                            {info.customer_info.phone}<br/>
											
											</address>
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table className="table table-invoice table-bordered">
											<thead>
												<tr>
													<th className="wd-20p">Date</th>
													<th className="wd-40p">Description</th>
													<th className="tx-center">Dr.</th>
													<th className="tx-right">Cr.</th>
													<th className="tx-right">Balance</th>
												</tr>
											</thead>
											<tbody>
                                            {info && info.statement.list &&
                                            info.statement.list.map((item, key)=>           
												<tr key={key}>
                                                    <td>{item.date.long_date}</td>
													<td className="tx-12">{item.description}</td>
													<td className="tx-center">{item.charge.amount*1>0 && item.charge.amount_c}</td>
													<td className="tx-right">{item.payment.amount*1>0 && item.payment.amount_c}</td>
													<td className="tx-right">{item.balance.amount_c}</td>
												</tr>)}
												

												<tr>
													<td className="valign-middle" colspan="2" rowspan="4">
														<div className="invoice-notes">
															<label className="main-content-label tx-13">Notes</label>
                                                            {
                                                               info && info!=="404" && info.statement.extension_log.map((item, key)=>
                                                                    <p key={key}>Paid {item.amount.amount_c}/= on {item.date.long_date} for loan extension </p>
                                                                )}
															<p>{info.statement.notes}</p>
														</div>
													</td>
													<td className="tx-right">Loan Points</td>
													<td className="tx-right" colspan="2">{info.package_info.package_points}</td>
												</tr>
												<tr>
													<td className="tx-right">Interest Rate</td>
													<td className="tx-right" colspan="2">
                                                      {info.package_info.interest_rate + '%'}
                                                    </td>
												</tr>
												<tr>
													<td className="tx-right">Total Paid Extension</td>
													<td className="tx-right" colspan="2">
                                                        {info.statement.summary.total_extension.amount_c}
                                                    </td>
												</tr>
												<tr>
													<td className="tx-right tx-uppercase tx-bold tx-inverse">Loan Balance</td>
													<td className="tx-right" colspan="2">
                                                        <h4 className="tx-bold">{info.loan_balance.amount_c}/=</h4>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
                                {/*
								<div className="card-footer text-right">
									<button type="button" className="btn ripple btn-primary mb-1"><i className="fe fe-credit-card mr-1"></i> Pay Invoice</button>
									<button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button>
									<button type="button" className="btn ripple btn-info mb-1" onclick="javascript:window.print();"><i className="fe fe-printer mr-1"></i> Print Invoice</button>
                                </div>
                                */}
							</div>
						

                          
                      </div>}
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default LoanProfile;
  
import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import functions from '../functions';
class MdlLoanStatement extends React.Component {
    state={
        loanRecord:this.props.loan,
        mdlRefId:this.props.id || 'modal_loan'
     }

     onClickManage=()=>{
       
            $("#" + this.state.mdlRefId).modal('hide');
     }
        

     render(){
        const info=this.state.loanRecord;
        const accessInfo=functions.readPermissions();
        return (
            <div className="modal" id={this.state.mdlRefId}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                
            <div className="modal-content modal-content-demo shadow">
                <div className="modal-header">
                    <h6 className="modal-title">Loan Statement</h6>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                   
                
                    <div className="row">
                            {info && <div className="col-lg-12 col-md-12">



                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="d-lg-flex">
                                        <address>
                                        <h2 className="card-title mb-1">#{info.loan_code}</h2>

                                            {info.package_info.package_name}<br/>
                                            {info.package_info.duration + ' ' + info.package_info.units}
                                        </address>
                                        <div className="ml-auto">
                                            <p className="mb-1">
                                            <span className="font-weight-bold">Loan Date :</span>{info.loan_date.long_date}</p>
                                            <p className="mb-0"><span className="font-weight-bold">Deadline :</span>{ info.deadline.long_date }</p>
                                        </div>
                                    </div>
                                    <hr className="mg-b-40"/>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className="h3">Agent:</p>
                                            <address>
                                                {info.agent_info.first_name + ' ' + info.agent_info.last_name}<br/>
                                                {info.agent_info.phone}<br/>
                                                
                                            </address>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <p className="h3">Customer:</p>
                                            <address>
                                            {info.customer_info.first_name + ' ' + info.customer_info.last_name}<br/>
                                            {info.customer_info.phone}<br/>
                                            
                                            </address>
                                        </div>
                                    </div>
                                    <div className="table-responsive mg-t-40">
                                        <table className="table table-invoice table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="wd-35p">Date</th>
                                                    <th className="wd-25p">Description</th>
                                                    <th className="tx-center">Dr.</th>
                                                    <th className="tx-right">Cr.</th>
                                                    <th className="tx-right">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {info && info.statement.list &&
                                            info.statement.list.map((item, key)=>           
                                                <tr key={key}>
                                                    <td>{item.date.long_date + ' ' + item.date.time}</td>
                                                    <td className="tx-12">{item.description}</td>
                                                    <td className="tx-center">{item.charge.amount*1>0 && item.charge.amount_c}</td>
                                                    <td className="tx-right">{item.payment.amount*1>0 && item.payment.amount_c}</td>
                                                    <td className="tx-right">{item.balance.amount_c}</td>
                                                </tr>)}
                                                

                                                <tr>
                                                    <td className="valign-middle" colspan="2" rowspan="4">
                                                        <div className="invoice-notes">
                                                            <label className="main-content-label tx-13">Notes</label>
                                                            {
                                                                info.package_info.loan_type.type_id=="2" && info.particulars &&
                                                                <span>
                                                                <p><b>Remittance: </b>{info.particulars.remittance.amount_c}</p>
                                                                <p><b>Next Pay Date: </b>{info.particulars.next_pay_day}</p>
                                                                <p><b>Pending Payment: </b>{info.particulars.must_amount.amount_c}</p>
                                                                <p><b>Weeks Covered: </b>{info.particulars.weeks_out.net}</p>

                                                                </span>
                                                            }

                                                            {
                                                            info && info!=="404" && info.statement.extension_log.map((item, key)=>
                                                                    <p key={key}>Paid {item.amount.amount_c}/= on {item.date_added.long_date} for loan extension </p>
                                                                )}
                                                            <p>{info.statement.notes}</p>
                                                        </div>
                                                    </td>
                                                    <td className="tx-right">Loan Points</td>
                                                    <td className="tx-right" colspan="2">{info.package_info.package_points}</td>
                                                </tr>
                                                <tr>
                                                    <td className="tx-right">Interest Rate</td>
                                                    <td className="tx-right" colspan="2">
                                                    {info.package_info.interest_rate + '%'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="tx-right">Total Paid Extension</td>
                                                    <td className="tx-right" colspan="2">
                                                        {info.statement.summary.total_extension.amount_c}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="tx-right tx-uppercase tx-bold tx-inverse">Loan Balance</td>
                                                    <td className="tx-right" colspan="2">
                                                        <h4 className="tx-bold">{info.loan_balance.amount_c}/=</h4>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <div className="card-footer text-right">
                                    {functions.findInObject(accessInfo, "LN-MANAGE-2") &&<Link 
                                        className="btn ripple btn-primary mb-1"
                                        onClick={this.onClickManage}
                                        to={
                                            {
                                                pathname:'/manage-loan',
                                                state:{loan_id:info.loan_id}
                                            }
                                          }>
                                        <i className="fe fe-credit-card mr-1"></i> Manage Loan
                                        </Link>}
                                </div>
                                
                            </div>
                        </div>}
                                    
                    </div>
        </div>
          
          </div>
  
  
          </div>
      </div>
       )
      
   
    }
      
}

export default MdlLoanStatement;
import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import MdlEditLoanPackage from '../Components/MdlEditLoanPackage'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class LoanPackages extends React.Component {

    state={
        userId:functions.sessionGuard(),
        packageList:false
       
      }

      componentDidMount()
      {
        this.listLoanPackages();
      }


      listLoanPackages=async()=>{
          const server_response=await ajax.getLoanPackages();
          if(server_response.status==="OK")
          {
             this.setState({
                packageList:server_response.details
             })

          }else{
            this.setState({
                packageList:"404"
            })
          }
      }
      

    
    render(){
        const list=this.state.packageList
        console.log(list)
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Loan Packages"
                     previousPageTitle="Loans"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     

                      <div className="card custom-card">
                        <div className="card-body">
                          <div>
                          <h6 className="card-title mb-1">Loan Packages</h6>
                          <p className="text-muted card-sub-title">List of all Quickpost loan packages</p>
                          </div>
                          <div className="table-responsive">
                          <table className="table table-bordered text-nowrap mb-0">
                              <thead>
                                  <tr>
                                      <th>#No</th>
                                      <th>Package Name</th>
                                      <th>Loan Type</th>
                                      <th>Duration</th>
                                      <th>Interest</th>
                                      <th>Points</th>
                                      <th>Max. Amount</th>
                                      <th>&nbsp;</th>
                                  </tr>
                              </thead>
                              <tbody>

                              {list && list!=="404" && list.map((item, key)=>
                                      <tr key={key}>
                                      <td>{key+1}</td>
                                      <td>{item.package_name}</td>
                                      <td>{item.loan_type.type_name}</td>
                                      <td>{item.loan_duration + ' ' + item.duration_units}</td>
                                      <td>{item.interest}%</td>
                                      <td>{item.loan_points}</td>
                                      <td>{item.max_amount.currency + ''+ item.max_amount.amount_c}</td>
                                      <td>
                                          <Link to="#"><i className="fa fa-edit" title="Edit" data-target={"#mdl-edit-loan-package"+item.package_id} data-toggle="modal"></i></Link>
                                          &nbsp; &nbsp;
                                      </td>
                                      <MdlEditLoanPackage id={item.package_id} record={item} onFinish={this.listLoanPackages}/>
                                  </tr>
                                  )}	
                                  
                              </tbody>
                          </table>

                          {!this.state.packageList && <ActivityLoader/>}

                            </div>
                        </div>
                    </div>

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default LoanPackages;
  
import React from 'react';
import ajax from '../../../ajax';
import functions from '../../../functions';
import DashCard from '../../Common/DashCard';


class DailyWithdrawsCard extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    value:'...'
  }
  async componentDidMount()
  {
    await this.requestRecord();
    this.timer = setTimeout(this.func=async()=> {
      await this.requestRecord();
      this.timer = setTimeout(this.func, 2000); // (*)
    }, 2000);
  }

  componentWillUnmount()
  {
    clearTimeout(this.timer)
  }

  requestRecord=async()=>{
    const server_response=await ajax.totalDailyFloatWithdraws();
    if(server_response.status==="OK")
    {
      this.setState({
        value:server_response.details.amount_c
      })
    }
  }

  render(){
    
      return (
        <DashCard 
            title="Daily Withdraws"
            value={this.state.value}
            caption="Today's Float Deductions"
            subValue="UGX"
            />

      );
    
 
  }
}

export default DailyWithdrawsCard;
import React from 'react'
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import $ from 'jquery';
import PopNewReserveRequest from '../Reserve/PopNewReserveRequest';

class TopBar extends React.Component{

    state={
        userId:functions.sessionGuard(),
        newRequisition:false
    }

    componentDidMount()
    {
        this.closeSideBar();
    }

   
    toggleSideBar=()=>{
        let element=document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            element[0].classList.toggle('main-sidebar-hide');
		} else {
            element[0].classList.toggle('main-sidebar-show');
		}
    }


    openDrawer=()=>{
        setTimeout(() => {
            document.getElementById('system_drawer').classList.add('sidebar-open')

        }, 100);
    }


    closeSideBar=()=>{
        let element=document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
           // element[0].classList.toggle('main-sidebar-hide');
           element[0].classList.add('main-sidebar-hide');
		} else {
            //element[0].classList.toggle('main-sidebar-show');
            element[0].classList.add('main-sidebar-show');
		}
    }

    toggleProfileAccess=()=>{
        let element=document.getElementById('profile-menu-access');
        element.classList.toggle('show');
	
    }

   

      logos=()=>{
          return(
            <Link className="main-logo d-lg-none" to="/">
            <img src={process.env.PUBLIC_URL + "../../images/qp_b.png"} className="header-brand-img desktop-logo" alt="logo"/>
            <img src={process.env.PUBLIC_URL +"../../images/qp_b.png"} className="header-brand-img icon-logo" alt="logo"/>
            <img src={process.env.PUBLIC_URL +"../../images/logo.png"} className="header-brand-img desktop-logo theme-logo" alt="logo"/>
            <img src={process.env.PUBLIC_URL +"../../images/qp_b.png"} className="header-brand-img icon-logo theme-logo" alt="logo"/>
            </Link>
          )
      }

      toggleDropdown=(idSelector)=>{
      
            //functions.removeClassFromElements('header-dropdown', 'show') 
            setTimeout(() => {
                document.getElementById(idSelector).classList.toggle('show');            

            }, 100); 
      }


      onClickSearch=()=>{
        $("#mdl-search").modal('show');
      }

      onLogout=()=>{
        //const cookies = new Cookies();
        //cookies.remove('quickpost@user');
        //window.sessionStorage.removeItem('quickpost@user');
        window.localStorage.removeItem('quickpost@user')
        window.location.replace('/');
      }
     


      onClickNewRequisition=()=>{
        this.setState({
          newRequisition:false
        }, ()=>{
          this.setState({
            newRequisition:<PopNewReserveRequest/>
          }, ()=>{
              functions.openPopup('modal_new_reserve_request')
          })
        })
      }
  
     

    render(){
        const accessInfo=functions.readPermissions();
        const userInfo=functions.readUser();
        return(
            <>
            {this.state.newRequisition}
            <div className="main-header side-header sticky-pin">
            <div className="container-fluid">
                <div className="main-header-left">
                    <this.logos/>
                    <a className="main-header-menu-icon link" 
                        to="#" onClick={this.toggleSideBar} 
                        id="mainSidebarToggle"><span></span>
                    </a>
                </div>
                <div className="main-header-right">
                    <div className="dropdown d-md-flex header-search">
                        <a className="nav-link icon header-search">
                            <i className="fe fe-search"  onClick={this.onClickSearch}></i>
                        </a>
                       
                    </div>

                    {/* <div className="dropdown d-md-flex">
                        <Link className="nav-link icon" title="Back"  onClick={this.props.back}>
                            <i className="fas fa-angle-double-left "></i>
                        </Link>
                    </div> */}
                   
                    <div className="dropdown main-header-notification header-dropdown" id="notification_menu">
                        <a className="nav-link icon" to="#" onClick={()=>this.toggleDropdown('notification_menu')}>
                            <i className="fe fe-bell"></i>
                            <span className="pulse bg-danger"></span>
                        </a>
                        {/*
                        <div className="dropdown-menu">
                            <div className="header-navheading">
                                <p className="main-notification-text">You have 1 unread notification<span className="badge badge-pill badge-primary ml-3">View all</span></p>
                            </div>
                            <div className="main-notification-list">
                                <div className="media new">
                                    <div className="main-img-user online">
                                        <img alt="avatar" src="assets/img/users/5.jpg" /></div>
                                    <div className="media-body">
                                        <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="main-img-user"><img alt="avatar" src="assets/img/users/2.jpg" /></div>
                                    <div className="media-body">
                                        <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="main-img-user online"><img alt="avatar" src="assets/img/users/3.jpg" /></div>
                                    <div className="media-body">
                                        <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-footer">
                                <a href="#">View All Notifications</a>
                            </div>
                        </div>
                        */}
                    </div>
                    <div className="dropdown main-profile-menu header-dropdown" id="profile_menu">
                        <a className="main-img-user" to="#" onClick={()=>this.toggleDropdown('profile_menu')}>
                            {/* <img alt="avatar" src={this.state.photo}/> */}
                            <i className="fas fa-user-circle" style={{fontSize:28, color:"#FFFFFF"}}></i>
                        </a>
                        <div className="dropdown-menu">
                           
                           {accessInfo && <div className="header-navheading">
                                <h6 className="main-notification-title">{userInfo.first_name + ' ' + userInfo.last_name}</h6>
                                <p className="main-notification-text">{userInfo.username}</p>
                            </div>}
                            <a className="dropdown-item border-top" href="#">
                                <i className="fe fe-user"></i> My Account
                        </a>
                            <a className="dropdown-item" href="#" onClick={this.onClickNewRequisition}>
                                <i className="fe fe-edit"></i> New Requisition
                        </a>
                        {functions.findInObject(accessInfo, "CONFIG-SETTINGS-0001") &&
                            <Link className="dropdown-item" to="/settings">
                                <i className="fe fe-settings"></i> System Settings
                            </Link>}
                            {functions.findInObject(accessInfo, "CONFIG-USERS-0001") &&
                            <Link to="/users/user_management" className="dropdown-item">
                                <i className="fa fa-users"></i> User Management
                            </Link>}
                            {functions.findInObject(accessInfo, "DEVICE-ACTIVITY-0001") &&
                            <Link to="/activity" className="dropdown-item">
                                <i className="fe fe-compass"></i> Activity
                            </Link>}
                        <Link className="dropdown-item" to="#" onClick={this.onLogout}>
                                <i className="fe fe-power"></i> Sign Out
                            </Link>
                        </div>
                    </div>
                    <div className="dropdown d-md-flex header-settings">
                        <a to="#" className="nav-link icon" data-toggle="sidebar-right" data-target=".sidebar-right" onClick={this.openDrawer}>
                            <i className="fe fe-align-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
       </>
       )
    }

}

export default TopBar
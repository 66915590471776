import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class LoansOutstanding extends React.Component {

    state={
        userId:functions.sessionGuard(),
        loanList:false,
        metaData:false,
        page:1,
       
      }

      componentDidMount()
      {
        this.listLoans();
      }


      listLoans=async()=>{
          const server_response=await ajax.getOutstandingLoans(this.state.page, 0);
          if(server_response.status==="OK")
          {
             this.setState({
                loanList:server_response.details.list,
                metaData:server_response.details.meta
             })

          }else{
            this.setState({
                loanList:"404"
            })
          }
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            loanList:false

          }, ()=>{
            this.listLoans();
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            loanList:false

          }, ()=>{
            this.listLoans();
          })
        }

      }

    
      refresh=()=>{
        this.setState({
          page:1,
          loanList:false

        }, ()=>{
          this.listLoans();
        })
      

      }
    
    render(){
        const list=this.state.loanList
        const metaData=this.state.metaData;
        //console.log(list);
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Outstanding Loans"
                     previousPageTitle="Loans"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     

                      <div className="card custom-card">
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-1">Outstanding Loans</h6>
                                <p className="text-muted card-sub-title">All quickpost outstanding loan records</p>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>#No</th>
                                      <th>Loan ID</th>
                                      <th>Name</th>
                                      <th>Phone</th>
                                      <th>Loan Date</th>
                                      <th>Deadline</th>
                                      <th>Princ(UGX)</th>
                                      <th>Interest(UGX)</th>
                                      <th>Balance(UGX)</th>
                                      <th>Expiry(Days)</th>
                                      <th>Agent Contact</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                  {list && list!=="404" && list.map((item, key)=>
                                  <tr key={key} className={item.timer_in_days>=0 && `flag-row`}>
                                  <td>{metaData.offset_count + key+1}</td>
                                  <td>
                                      <Link 
                                        className="orange" 
                                        to={
                                          {
                                              pathname:'/loan-profile',
                                              state:{loan_id:item.loan_id}
                                          }
                                        }
                                        >
                                      {item.loan_code}
                                      </Link>
                      
                                  </td>
                                  <td>
                                      {item.customer_info.first_name + " " + item.customer_info.last_name}
                                  </td>
                                  <td>
                                      <Link className="orange" to="#" onClick={()=>this.onClickProfile(item.customer_info.user_id)}>{item.customer_info.phone}</Link>
                                  </td>
                                <td>{item.loan_date.short_date}</td>
                                <td>{item.deadline.long_date}</td>
                                <td>{item.loan_principal.amount_c}</td>
                                <td>{item.loan_interest.amount_c}</td>
                                <td>{item.loan_balance.amount_c}</td>
                                <td>{item.timer_in_days}</td>
                                  <td>
                                      <Link className="orange" to="#" title={item.agent_info.first_name + ' ' + item.agent_info.last_name}
                                        onClick={()=>this.onClickAgentProfile(item.agent_info.user_id)}>
                                          {item.agent_info.phone}
                                        </Link>
                                  </td>
                              </tr>
                              )}	
                                    
                                  </tbody>
                                </table>

                                  {!this.state.loanList && <ActivityLoader/>}

                                  {metaData &&  <nav className="float-right">
                                    <ul className="pagination">
                                    <li className="page-item">
                                    <Link className="page-link" to="#" onClick={this.onClickPrevious}>Previous</Link>
                                    </li>
                                    <li className="page-item"><a className="page-link">{metaData.current_page}</a></li>
                                    <li className="page-item"><a className="page-link">of</a></li>
                                    <li className="page-item"><a className="page-link">{metaData.total_pages}</a></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={this.onClickNext}>Next</Link>
                                        </li>
                                    </ul>
                                </nav>}

                              </div>
                            </div>
                          </div>
					
          
                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
          );  
   
    }
  }
  
  export default LoansOutstanding;
  
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import PillTag from '../Common/PillTag';
import RequestAlerts from '../Reserve/RequestAlerts';


class Reserve extends React.Component {

    state={
        userId:functions.sessionGuard(),
        reserveBalance:'.',
        reserveThisWeek:'',
        reserveLastWeek:'',
        reserveThisMonth:'',
        reserveLastMonth:'',
        reserveThisYear:'',
        reserveToday:'',
        reservePercentThisWeek:0,
        reservePercentLastWeek:0,
        cummBalance:'',
        totalUnapproved:0,
        totalUnprocessed:0,
        availableFunds:'..',
        relworxFunds:'..'
    }

    componentDidMount()
    {
        this.timerReserve = setTimeout(this.func9=async()=> {
            await this.countReserve();
            this.timerReserve = setTimeout(this.func9, 2000); // (*)
          }, 2000);
    
          this.timerReserveStat = setTimeout(this.func10=async()=> {
            await this.getReserveStatistics();
            this.timerReserveStat = setTimeout(this.func10, 2000); // (*)
          }, 2000);

          this.timerYo = setTimeout(this.func11=async()=> {
            await this.getYoFunds();
            this.timerYo = setTimeout(this.func11, 2000); // (*)
          }, 2000);


          this.timerRel = setTimeout(this.func12=async()=> {
            await this.getRelworxFunds();
            this.timerRel = setTimeout(this.func12, 2000); // (*)
          }, 2000);
         
         
    }


    componentWillUnmount()
    {
        clearTimeout(this.timerReserve);
        clearTimeout(this.timerReserveStat);
    }


    countReserve=async()=>
    {
        const server_reponse=await ajax.countReserve();
        if(server_reponse.status==="OK")
        {
            this.setState({
                reserveBalance:server_reponse.details.amount_c
            })
        }
    }

    getReserveStatistics=async()=>
    {
        const server_reponse=await ajax.getReserveStatistics();
        if(server_reponse.status==="OK")
        {
            this.setState({
                reserveLastWeek:server_reponse.details.last_week.amount_c,
                reserveThisWeek:server_reponse.details.this_week.amount_c,
                reserveThisMonth:server_reponse.details.this_month.amount_c,
                reserveLastMonth:server_reponse.details.last_month.amount_c,
                reserveThisYear:server_reponse.details.this_year.amount_c,
                reserveToday:server_reponse.details.today.amount_c,
                cummBalance:server_reponse.details.cumm_balance.amount_c
            }, ()=>{
                var total=(server_reponse.details.last_week.amount*1+server_reponse.details.this_week.amount*1);
                this.setState({
                    reservePercentLastWeek:Math.round((server_reponse.details.last_week.amount*1/total)*100),
                    reservePercentThisWeek:Math.round((server_reponse.details.this_week.amount*1/total)*100)

                })
            })
        }
    }

    getYoFunds=async()=>
    {
        const server_reponse=await ajax.getYoFunds();
        if(server_reponse.status==="OK")
        {
            this.setState({
                availableFunds:server_reponse.details.amount_c
            })
        }
    }


    getRelworxFunds=async()=>
    {
        const server_reponse=await ajax.getRelworxBalance();
        if(server_reponse.status==="OK")
        {
            this.setState({
                relworxFunds:server_reponse.details.amount_c
            })
        }
    }
  

  render(){
    
      return (

        <div className="card custom-card">
        <div className="card-body">
            <div>
                <h6 className="card-title mb-1">
                    <Link to="/finance/reserve">Company Reserve</Link>
                </h6>
                <p className="text-muted mb-0 card-sub-title">Company gross earnings</p>
               
               <RequestAlerts/>
            </div>
          
            
        </div>
        <div className="country-sales scroll-widget bd-t overflow-auto h-50">
            <div className="list-group">
                <div className="list-group-item d-flex border-right-0 border-left-0 rounded-0">
                    <i className="fas fa-chart-line text-secondary"></i>
                    <p className="ml-3 mb-0">Actual Balance</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveBalance}</span>
                </div>

                <div className="list-group-item d-flex border-right-0 border-left-0 rounded-0">
                    <i className="fas fa-chart-line text-primary"></i>
                    <p className="ml-3 mb-0">YO Balance</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.availableFunds}</span>
                </div>

                <div className="list-group-item d-flex border-right-0 border-left-0 rounded-0">
                    <i className="fas fa-chart-line text-primary"></i>
                    <p className="ml-3 mb-0">Relworx Balance</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.relworxFunds}</span>
                </div>

                <div className="list-group-item  d-flex border-right-0 border-left-0 border-top-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">Today</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveToday}</span>
                </div>
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">This Week</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveThisWeek}</span>
                </div>
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">Last Week</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveLastWeek}</span>
                </div>
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">This Month</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveThisMonth}</span>
                </div>
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">Last Month</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveLastMonth}</span>
                </div>
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-success"></i>
                    <p className="ml-3 mb-0">This Year</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.reserveThisYear}</span>
                </div>
               
                <div className="list-group-item d-flex border-right-0 border-left-0">
                    <i className="fas fa-chart-line text-primary"></i>
                    <p className="ml-3 mb-0">Cumm. Balance</p>
                    <span className="ml-auto font-weight-bold">UGX.{this.state.cummBalance}</span>
                </div>
             
            </div>
        </div>
    </div>


      )
    
 
  }
}

export default Reserve;
import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import FilterAllLoans from '../Components/Filters/FilterAllLoans'
import FilterBusinessLoans from '../Components/Filters/FilterBusinessLoans'
import FilterPersonalLoans from '../Components/Filters/FilterPersonalLoans'
import FilterDefaultedLoans from '../Components/Filters/FilterDefaultedLoans'
import FilterOutstandingLoans from '../Components/Filters/FilterOutstandingLoans'
import MdlLoanStatement from '../Components/MdlLoanStatement'
import $ from 'jquery';
import PageNav from '../Components/Common/PageNav'
import SilentText from '../Components/Common/SilentText'

class LoansAll extends React.Component {

    state={
        userId:functions.sessionGuard(),
        transactionList:false,
        metaData:false,
        filter:"ALL",
        page:1,
        title:"All Loans",
        loanRecord:'',
        selectedLoanId:'',
        statement:false,
        pagination:false
      }

      componentDidMount()
      {
        this.listTransactions();
      }


      listTransactions=async()=>{
        switch(this.state.filter){
          case "ALL":
            var server_response=await ajax.filterAllLoans(0, this.state.page);
          break;
          case "BUSINESS":
            var server_response=await ajax.filterBusinessLoans(0, this.state.page);
          break;
          case "PERSONAL":
            var server_response=await ajax.filterPersonalLoans(0, this.state.page);
          break;
          case "DEFAULTED":
            var server_response=await ajax.filterDefaultedLoans(0, this.state.page);
          break;
          case "OUTSTANDING":
            var server_response=await ajax.filterOutstandingLoans(0, this.state.page);
          break;

        }
         // console.log(server_response.details)
          if(server_response.status==="OK")
          {
             this.setState({
                transactionList:server_response.details.list,
                metaData:server_response.details.meta
             }, ()=>this.paginate())

          }else{
            this.setState({
                transactionList:"404"
            })
          }
      }


      onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }

      onSelectRecord=(selectedLoanId)=>{
        this.setState({
            selectedLoanId})
        }


      onClickFilter=(filter, title)=>{
        this.setState({
          filter:filter,
          transactionList:false,
          metaData:false,
          pagination:false,
          page:1,
          title:title
        }, ()=>this.listTransactions())
      }

      onClickLoan=(record)=>{
        this.setState({
          statement:false
        }, ()=>{
          this.setState({
            statement:<MdlLoanStatement loan={record}/>
          }, ()=>{
            $("#modal_loan").modal('show');
          })
        })
      }


      onClickRecoverLoan=()=>{
        
      }

      

//==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================

    
    render(){
        const list=this.state.transactionList
        const metaData=this.state.metaData;
       
          return (
            <div className="page">
              {this.state.statement}
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Loan History"
                     previousPageTitle="Loans"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <div>
                              <h6 className="card-title mb-1">{this.state.title}</h6>
                              <p className="text-muted card-sub-title">QuickPost loan records - in order of the most current</p>
                            </div>


                              
                        <div className="text-wrap">
                            <div className="">
                                <div className="btn-list">
                                   <FilterAllLoans onClick={this.onClickFilter}/>
                                   <FilterBusinessLoans onClick={this.onClickFilter}/>
                                    <FilterPersonalLoans onClick={this.onClickFilter}/>
                                    <FilterDefaultedLoans onClick={this.onClickFilter}/>
                                    <FilterOutstandingLoans onClick={this.onClickFilter}/>

                                </div>
                            </div>
                            </div>


                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                      <th>#No</th>
                                      <th>Loan ID</th>
                                      <th>Customer</th>
                                      <th>Agent</th>
                                      <th>Loan Date</th>
                                      <th>Deadline/Next Pay Day</th>
                                      <th>Princ(UGX)</th>
                                      <th>Interest(UGX)</th>
                                      <th>Balance(UGX)</th>
                                      <th>Paid(Ugx)</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                    <tr 
                                      key={key}
                                      className={`pointer ${this.state.selectedLoanId==item.loan_id?'flag-row':''}`}
                                      onClick={()=>this.onSelectRecord(item.loan_id)}>
                                    <td>{metaData.offset_count + key+1}</td>
                                    <td>
                                        <Link 
                                          className="orange" 
                                          to="#"
                                          onClick={()=>this.onClickLoan(item)}
                                          >
                                        {item.loan_code}
                                        </Link><br/>
                                        <SilentText text={item.package_info.loan_type.type_name}/>
                                    </td>
                                    <td>
                                        <Link className="orange" 
                                          to={
                                              {
                                                pathname:`/customer-profile/${item.customer_info.user_id}`,
                                                state:{customerId:item.customer_info.user_id}
                                              }
                                            }>
                                        {item.customer_info.first_name + " " + item.customer_info.last_name}</Link><br/>
                                        <SilentText text={item.customer_info.phone}/>
                                    </td>
  
                                    <td>
                                        <Link className="orange"
                                          to={
                                            {
                                              pathname:`/agent-profile/${item.agent_info.user_id}`,
                                              state:{agentId:item.agent_info.user_id}
                                            }}
                                          >
                                            {item.agent_info.first_name + ' ' + item.agent_info.last_name}
                                          </Link>
                                          <br/><SilentText text={item.agent_info.phone}/>
  
                                    </td>
                                    
                                  <td>
                                    {item.loan_date.short_date}
                                    <br/><SilentText text={item.loan_date.time}/>
  
                                  </td>
                                  <td>
                                    {item.package_info.loan_type.type_id=="2"?item.particulars.next_payment_date.short_date:item.deadline.short_date}
                                    <br/><SilentText text={item.package_info.loan_type.type_id=="2"?"Next Pay Day at " + item.particulars.next_payment_date.time:"Actual Deadline at "+ item.deadline.time}/>
                                    </td>
                                  <td>
                                    {item.loan_principal.amount_c}
                                    <br/><SilentText text={item.package_info.package_name}/>
                                  </td>
                                  <td>
                                      {item.loan_interest.amount_c}
                                      <br/><SilentText text={item.package_info.interest_rate + '% - ' + item.package_info.duration + ' ' + item.package_info.units}/>
                                    </td>
                                  <td>
                                    {item.loan_balance.amount_c}
                                    <br/><SilentText text={item.package_info.loan_type.type_id=="2"?"Remit: " + item.particulars.remittance.amount_c :'Unpaid'}/>
                                  </td>
                                  <td>
                                    {item.total_paid.amount_c}
                                    <br/><SilentText text={"LN Fine: " + item.loan_fine.amount_c}/>
                                  </td>
                                    
                                </tr>
                                )}	
                                  
                                </tbody>
                              </table>

                           

                            {this.state.pagination && this.state.pagination}
                            {!this.state.transactionList && <ActivityLoader/>}


                              

                            </div>
                          </div>
                        </div>
					

                          
                      </div>


                      
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default LoansAll;
  
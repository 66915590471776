import React from 'react';
import {Doughnut} from 'react-chartjs-2';

class HollowPie extends React.Component {

    state={
        chartData:{
            labels: this.props.labels || ['Sticker', 'Other'],
            datasets: [
              {
                //label: 'Rainfall',
                backgroundColor: [
                  this.props.color,
                  '#e1e6f1'
                ],
                hoverBackgroundColor: [
                 this.props.color,
                '#e1e6f1'
                ],
                data: this.props.values || [65, 59]
              }
            ]
            
          }

        
        
    }
  
  render(){
    
      return (
            
            <Doughnut
                data={this.state.chartData}
                options={{
                    title:{
                    display:false,
                   // text:'Chart title',
                    fontSize:5
                    },
                    legend:{
                    display:false,
                    position:'right'
                    },
                    tooltips: {enabled: false},
                    hover: {mode: null},
                }}
                
                />

              
      );
    
 
  }
}

export default HollowPie;
import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PopVerifyAction from '../Common/PopVerifyAction';


class PopTransactionDetails extends React.Component {
  state={
      loading:false,
      transactionRecord:this.props.record,
  }


 

onClickClose=()=>{
    
      functions.closePopup('mdl-transaction-details');

}


  render(){
    const item=this.state.transactionRecord;
      return (
      <>
        <div className="modal" id="mdl-transaction-details">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">Transaction Details</h6><button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">



                            {item && item!=="404" &&
                            <div className="">
                                    <div className="main-content-body main-content-body-contacts">
                                    
                                    <div className="main-contact-info-body">
                                        
                                        <div className="media-list pb-lg-0">
                                       
                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Product</label> 
                                                <span className="tx-medium">{item.product.name}</span>
                                            </div>
                                            <div>
                                                <label>Category</label> 
                                                <span className="tx-medium">{item.product.local_category}</span>
                                            </div>
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            <div>
                                                <label>Amount</label> 
                                                <span className="tx-medium">{"UGX. " + item.amount.amount_c}</span>
                                            </div>

                                            <div>
                                                <label>Local Charges</label> 
                                                <span className="tx-medium">{"UGX. " + item.local_charges.amount_c}</span>
                                            </div>
                                           
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            
                                            <div>
                                                <label>Status</label> 
                                                <span className="tx-medium">{item.status}</span>
                                            </div>

                                            <div>
                                                <label>Provider Charges</label> 
                                                <span className="tx-medium">{"UGX. " + item.provider_charges.amount_c}</span>
                                            </div>
                                    
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">
                                            
                                            <div>
                                                <label>Transactor Details</label> 
                                                <span className="tx-medium">{item.user_info.first_name + ' ' + item.user_info.last_name + '(' + item.user_info.username +')' + ' - ' + item.user_info.role_name}</span>
                                            </div>

            
                                    
                                            </div>
                                        </div>
                                        

                                        <div className="media">
                                            <div className="media-body">

                                            <div>
                                                <label>Beneficiary</label> 
                                                <span className="tx-medium">{item.beneficiary}</span>
                                            </div>
                                            <div>
                                                <label>Created On</label> 
                                                <span className="tx-medium"> 
                                                {item.trans_date.when}
                                                </span>
                                            </div>
                                    
                                            </div>
                                        </div>


                                        <div className="media">
                                            <div className="media-body">

                                            <div>
                                                <label>Depositor Contact</label> 
                                                <span className="tx-medium">{item.depositor_contact}</span>
                                            </div>
                                            <div>
                                                <label>Remote Token</label> 
                                                <span className="tx-medium"> 
                                                {item.remote_token}
                                                </span>
                                            </div>
                                    
                                            </div>
                                        </div>
                                      
               
                                        </div>
                                    </div>
                                    </div>
                                </div>}
                            
                                
                </div>
                <div className="modal-footer">
                    <input className="btn ripple btn-danger" type="button" value="Close" onClick={this.onClickClose}/>
                   
                </div>
            </div>
        </div>
    </div>
    </>
     )
    
 
  }
}

export default PopTransactionDetails;
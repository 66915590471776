import React from 'react';
import ajax from '../../ajax';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import TextInput from '../Common/TextInput';

class PopNewPermission extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessCode:'',
        description:'',
        info:'',
        loading:false
    }

   

    onChangeAccessCode=(event)=>
    {
        this.setState({accessCode:event.target.value})
    }

    onChangeDescription=(event)=>
    {
        this.setState({description:event.target.value})
    }

  
    onConfirm= (event)=>{
        event.preventDefault();
        this.setState({
            info:''
        }, ()=>{

            const {accessCode, description}=this.state
            if(accessCode.length>0 && description.length>0)
            {
               
                this.setState({
                    loading:true
                }, async()=>{
                    const server_response=await ajax.createAccessCode(accessCode, description)
                    this.setState({
                        loading:false
                    })

                    if(server_response.status==="OK")
                    {
                        this.setState({
                            info:<AlertSuccess message={server_response.details[0]}/>
                          }, ()=>{
                            this.setState({
                                accessCode:'',
                                description:''
                            })
                            this.props.onConfirm();
                          })
                    }else{
                        this.setState({
                            info:<AlertError message={server_response.details[0]}/>
                          })
                    }

                })
                
            }else{
                this.setState({
                    info:<AlertError message={dictionary._completeFields}/>
                  })
                }

        })
        
    }

   
  render(){
    
      return (
        <div className="modal" id="modal_new_access_code">
        <div className="modal-dialog modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Create Permission</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
           
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                        
               

                    <TextInput 
                        label="Access Code"
                        onChange={()=>this.onChangeAccessCode}
                        value={this.state.accessCode}
                        />

                    <TextInput 
                            label="Description"
                            type="textarea"
                            onChange={()=>this.onChangeDescription}
                            value={this.state.description}
                            />

                </form>

                <p><i></i></p>

            
            </div>
           {!this.state.loading && <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Create Permission</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopNewPermission;
import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import Footer from '../Components/Common/Footer'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import functions from '../functions'
import RightSideBar from '../Components/Common/RightSideBar'
import TotalAgentsAll from '../Components/Cards/TotalAgentsAll'
import TotalAgentsToday from '../Components/Cards/TotalAgentsToday'
import RecentAgents from '../Components/User/RecentAgents'
import AgentRanking from '../Components/Cards/AgentRanking'
import SigningAgents from '../Components/Cards/SigningAgents'
import TotalSupervisors from '../Components/Cards/TotalSupervisors'
import { Link } from 'react-router-dom'

class DashboardAgents extends React.Component {

    state={
      userId:functions.sessionGuard(),
    }

   
    buttons = () => {
      return (
          <>
           <Link className="btn ripple btn-light" to="/agents/supervisors">
                  <i className="fas fa-dove"></i> Agent Supervisors
              </Link>
              <button className="btn ripple btn-primary">
                  <i className="fab fa-amazon-pay"></i> Bills Performance
              </button>
              <button className="btn ripple btn-success">
                  <i className="fas fa-hand-holding"></i> Loan Performance
              </button>
             
          </>
      )
  }


    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Agents"
                    previousPageTitle="Dashboard"
                    buttons={<this.buttons/>}
                  />
                  
                    <div className="row">
                      <TotalAgentsAll/>
                      <TotalAgentsToday/>
                      <SigningAgents/>
                      <TotalSupervisors/>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                        <RecentAgents/>
                          
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                         <AgentRanking/>
                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardAgents
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import SecurityRequestCard from '../../Components/Cards/SecurityDeposit/SecurityRequestCard'
import SecurityBalanceCard from '../../Components/Cards/SecurityDeposit/SecurityBalanceCard'
import CummSecurityBalanceCard from '../../Components/Cards/SecurityDeposit/CummSecurityBalanceCard'
import UpcomingRequestCard from '../../Components/Cards/SecurityDeposit/UpcomingRequestCard'
import RecentSecurityDeposits from '../../Components/SecurityDeposit/RecentSecurityDeposits'
import OngoingSecurityDepositRequests from '../../Components/SecurityDeposit/OngoingSecurityDepositRequests'

class DashboardSecurityDeposit extends React.Component {

    state={
      userId:functions.sessionGuard(),
    }

 
    buttons=()=>{
      return(
          <>
              <button className="btn ripple btn-success">
                  <i className="fas fa-hands-helping"></i> Business Loans
              </button>
              <button className="btn ripple btn-primary">
                  <i className="fas fa-hands-helping"></i> Personal Loans
              </button>
              
            </>
          )
    }

    customHeaderOptions=()=>{
      return(
          <>
           <a className="dropdown-item">
                  <i className="fas fa-unlock-alt mr-2"></i>Security Deposits
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Principal
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Fines
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Paid Extension
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Interest Earnings
              </a>
             
            </>
          )
    }

   

    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Security Deposit"
                    previousPageTitle="Loans"
                    back="/dashboard"

                    // options={{
                    //   "customOptions":<this.customHeaderOptions/>
                    // }}
                    // buttons={<this.buttons/>}
                  />
                  
                    <div className="row">
                      
                      <SecurityRequestCard/>
                      <SecurityBalanceCard/>
                      <CummSecurityBalanceCard/>
                      <UpcomingRequestCard/>

                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                          <RecentSecurityDeposits/>
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                         <OngoingSecurityDepositRequests/>

                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardSecurityDeposit
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from './../Common/ActivityLoader';
import MdlLoanStatement from './../MdlLoanStatement';
import $ from 'jquery';
import TableHeader from '../Common/TableHeader';
import PopUserPermission from './PopUserPermission';


class ListUsers extends React.Component {

    state={
        userId:functions.sessionGuard(),
        list:false,
        selectedUser:false,
        selectedRecordId:''
    }

    componentDidMount()
    {
        this.fetchData();
       
    }


    fetchData=async()=>
    {
        const server_reponse=await ajax.listAdminUsers()
        if(server_reponse.status==="OK")
        {
            this.setState({
                list:server_reponse.details
               
            })
        }else{
            this.setState({
                list:"404"
               
            })
        }
    }

    onSelectRecord=(selectedRecordId)=>{
        this.setState({
            selectedRecordId})
        }

    onClickUser=(record)=>{
        this.setState({
          selectedUser:false
        }, ()=>{
          this.setState({
            selectedUser:<PopUserPermission user={record}/>
          }, ()=>{
            $("#modal_user_perms").modal('show');
          })
        })
      }


  
  render(){
      const list=this.state.list;
     
      return (
        <div className="card custom-card">
            {this.state.selectedUser}
        <div className="card-body">
            <TableHeader
                title="System Users"
                subtitle="List of all admins with dashboard access"
                //view="View All"
                //onClick={this.props.onClickViewAll}
            />
            <div className="">
            <div className="table-responsive">
                <table className="table table-hover text-nowrap mg-b-0">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>User Details</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list!=="404" && 
                         list.map((item, key)=>
                         <tr 
                            key={key}
                            className={`${this.state.selectedRecordId==item.user_id?'flag-row':''}`}
                            onClick={()=>this.onSelectRecord(item.user_id, item)}
                            >

                            <td>
                                {item.username}
                            </td>

                            <td>
                                {item.first_name}<br/>
                                <small className="tx-12 tx-gray-500">{item.last_name}</small>
                            </td>
                            
                            <td>
                                {item.created_at.short_date}<br/>
                                <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                            </td>

                            <td>
                                {item.updated_at.short_date}<br/>
                                <small className="tx-12 tx-gray-500">{item.updated_at.time}</small>
                            </td>
                            <td>
                                <a class="main-contact-star" href="#">
                                    <i class="fe fe-edit-2 mr-1"></i>
                                    <i class="fe fe-trash-2 mr-1 text-warning   "></i>
                                    
                                    <i class="fe fe-more-vertical" onClick={()=>this.onClickUser(item)}></i>
                                </a>
                            </td>
                         
                         </tr>
                         )}
                           
                  
                    </tbody>
                </table>

                {!list && <ActivityLoader/>}
               
            </div>
            </div>
        </div>
    </div>

      )
    
 
  }
}

export default ListUsers;
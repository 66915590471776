import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import DimHeader from '../Common/DimHeader';

class RecentMobileMoneyDeposits extends React.Component {

    state={
        userId:functions.sessionGuard(),
        accessInfo:false,
        transactionList:false,
        popApproval:false
       
    }

  async componentDidMount()
  {
    await this.requestRecord();
    this.timer = setTimeout(this.func=async()=> {
      await this.requestRecord();
      this.timer = setTimeout(this.func, 2000); // (*)
    }, 10000);
  }

  componentWillUnmount()
  {
    clearTimeout(this.timer)
  }


   

    readUser=async()=>
    {
        const server_reponse=await ajax.getUserAccess(this.state.userId);
        if(server_reponse.status==="OK")
        {
            this.setState({
               accessInfo:server_reponse.details.list,
            })
        }
    }


  requestRecord=async()=>{
    const server_response=await ajax.listMobileMoneyDeposits(1);
    if(server_response.status==="OK")
    {
      this.setState({
        transactionList:server_response.details.list
      })
    }else{
        this.setState({
            transactionList:'404'
          })
    }
  }


  onClickRecord=(record)=>
  {
       
  }
   
  render(){

    const list=this.state.transactionList;
    
      return (
        <>
        {this.state.popApproval}
        <div className="card custom-card">
            <div className="card-body">
                <div>
                    <h6 className="card-title mb-1">Mobile Money Recharge</h6>
                    <p className="text-muted mb-0 card-sub-title">Most Recent deposits via mobile money</p>
                </div>
            </div>
            <div className="user-manager scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                    <table className="table mg-b-0">
                        <tbody>
                           {list && list!=="404" && list.map((item, key)=>
                            <tr key={key} 
                                className="hot-tr"
                                onClick={()=>this.onClickRecord(item)}>
                                <td className="bd-t-0">
                                <div className="main-img-user">
                                        <img alt="avatar" src={item.user.photo}/>
                                    </div>
                                </td>
                                <td className="bd-t-0">
                                    <h6 className="mg-b-0">{item.user.first_name + ' ' + item.user.last_name}</h6>
                                    <small className="tx-11 tx-gray-500">M.M. Number: {item.phone_number}</small><br/>
                                    <small className="tx-11 tx-gray-500">Request Date: {item.created_at.when}</small>

                                </td>
                                <td className="bd-t-0">
                                    <h6 className="mg-b-0 font-weight-bold">{item.amount.amount_c}<small className="tx-11 tx-gray-500">
                                        {" Ugx"}
                                    </small></h6>
                                    <small className="tx-11 tx-gray-500">
                                        {item.status}
                                    </small>
                                </td>
                            </tr>)}
                           
                        </tbody>
                    </table>
                
                {list==="404" && <DimHeader title="No Mobile Money deposits found"/>}
                
                {!this.state.transactionList && <ActivityLoader/>}
                
                </div>
            </div>
        </div>
        </>
      )
    
 
  }
}

export default RecentMobileMoneyDeposits;
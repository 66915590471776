import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';


class ProductSales extends React.Component {
  

  state={
    userId:functions.sessionGuard(),
    accessInfo:false,
    daily:'...',
    monthly:'...'
   
  }


    async componentDidMount()
    {
      await this.getDailySales();
      await this.getMonthlySales();
      this.timerActual = setTimeout(this.func1=async()=> {
        await this.getDailySales();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }, 2000);

      this.timerCumm = setTimeout(this.func2=async()=> {
        await this.getMonthlySales();
        this.timerCumm = setTimeout(this.func2, 2000); // (*)
      }, 2000);
    }

    componentWillUnmount()
    {
      clearTimeout(this.timerActual);
      clearTimeout(this.timerCumm);
    }

    getDailySales=async()=>{
      const server_response=await ajax.countProductDailySales();
      if(server_response.status==="OK")
      {
        this.setState({
          daily:server_response.details.total_c
        })
      }
    }


    getMonthlySales=async()=>{
        const server_response=await ajax.countProductMonthlySales();
        if(server_response.status==="OK")
        {
          this.setState({
            monthly:server_response.details.total_c
          })
        }
      }





  render(){
      return (
        <DashCard 
            title="Number Of Daily Sales"
            value={this.state.daily}
            caption={"This month: " + this.state.monthly}
            icon={"fas fa-chart-line text-secondary"}
            progressbar="bg-secondary"
            />

      );
    
 
  }
}

export default ProductSales;
import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import TotalUsersCard from '../../Components/Cards/Access/TotalUsersCard'
import TotalPermissionsCard from '../../Components/Cards/Access/TotalPermissionsCard'
import ListUsers from '../../Components/Access/ListUsers'
import ListPermissions from '../../Components/Access/ListPermissions'
import PopNewUser from '../../Components/Access/PopNewUser'
import PopNewPermission from '../../Components/Access/PopNewPermission'
import AccessCodes from '../../Components/Access/AccessCodes'

class UserManagement extends React.Component {

    state={
      userId:functions.sessionGuard(),
      newAdmin:false,
      usersList:true,
      permissionList:true,
      newPermission:false
    }

    componentDidMount()
    {
      const accessInfo=functions.readPermissions();
      if(!functions.findInObject(accessInfo, "CONFIG-USERS-0001"))
      {
        window.location.replace('/dashboard');
      }
    }

    onClickCreateAdmin=()=>{
      this.setState({
        newAdmin:false
      }, ()=>{
        this.setState({
          newAdmin:<PopNewUser onConfirm={this.refreshUser}/>
        }, ()=>{
          functions.openPopup('modal_create_admin')
        })

      })
      
    }

    refreshUser=()=>{
      this.setState({
        usersList:false
      }, ()=>{
        this.setState({
          usersList:true
        })
      })
    }

    refreshPermissions=()=>{
      this.setState({
        permissionList:false
      }, ()=>{
        this.setState({
          permissionList:true
        })
      })
    }

 
    buttons=()=>{
      return(
          <>
              <button className="btn ripple btn-success" onClick={this.onClickCreateAdmin}>
                  <i className="fas fa-user-plus mr-2"></i>Create Admin
              </button>
              <button className="btn ripple btn-primary" onClick={this.onClickCreatePermission}>
                  <i className="fas fa-plus-circle mr-2"></i>Create Permission
              </button>
              
            </>
          )
    }

    customHeaderOptions=()=>{
      return(
          <>
           <a className="dropdown-item">
                  <i className="fas fa-unlock-alt mr-2"></i>Security Deposits
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Principal
              </a>
              <a className="dropdown-item">
                  <i className="fas fa-credit-card mr-2"></i>Paid Fines
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Paid Extension
              </a>
              <a className="dropdown-item">
                  <i className="fe fe-plus-circle mr-2"></i>Interest Earnings
              </a>
             
            </>
          )
    }



    onClickCreatePermission=()=>{
      this.setState({
        newPermission:false
      }, ()=>{
        this.setState({
          newPermission:<PopNewPermission onConfirm={this.refreshPermissions}/>
        }, ()=>{
          functions.openPopup('modal_new_access_code')
        })

      })
      
    }
   

    
    render(){
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="User Management"
                    previousPageTitle="Dashboard"
                    //back="/dashboard"

                    // options={{
                    //   "customOptions":<this.customHeaderOptions/>
                    // }}
                    buttons={<this.buttons/>}
                  />
                  {this.state.newAdmin}
                  {this.state.newPermission}
                  
                    <div className="row">
                      
                    {this.state.usersList && <TotalUsersCard/>}
                     {this.state.permissionList && <TotalPermissionsCard/>}

                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                         {this.state.usersList && <ListUsers/>}
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                      {this.state.permissionList && <ListPermissions/>}
                      {this.state.permissionList && <AccessCodes/>}

                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default UserManagement
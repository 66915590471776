import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import TopBar from "../../Components/Common/TopBar";
import PageHeader from "../../Components/Common/PageHeader";
import functions from "../../functions";
import RightSideBar from "../../Components/Common/RightSideBar";
import ChartArea from "../../Components/tickets/ChartArea";

class TicketInfo extends React.Component {
  state = {
    ticket_id: false,

  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.push('/tickets');
    } else {
      this.setState({
        ticket_id: this.props.location.state.ticket_id
      })
    }
  }


  render() {
    const { ticket_id } = this.state;
    return (
      <div className="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Ticket Info"
              previousPageTitle="Dashboard"
            //   buttons={<this.buttons />}
            />


            <ChartArea ticket_id={ticket_id} />

          </div>
        </div>
        <RightSideBar />
        <Footer />
      </div>
    );
  }
}

export default TicketInfo;

import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import TopBar from "../../Components/Common/TopBar";
import PageHeader from "../../Components/Common/PageHeader";
import functions from "../../functions";
import RightSideBar from "../../Components/Common/RightSideBar";
import OpenTicketsTable from "../../Components/tickets/OpenTicketsTable";
import ClosedTicketsTable from "../../Components/tickets/ClosedTicketsTable";
import Departments from "../../Components/tickets/Departments";
import TotalTickets from "../../Components/tickets/TotalTickets";
import { Link } from "react-router-dom";
import CreateSupportTicket from "../../Components/tickets/CreateSupportTicket";

class TicketsDashboard extends React.Component {
  buttons = () => {
    return (
      <>
        <Link to="#"
          className="btn ripple btn-success"
          data-target="#modaldemo3"
          data-toggle="modal"
        >

          <i className="fa fa-ticket"></i> Create Support Ticket
        </Link>

        <Link to="/closed/tickets" className="btn ripple btn-warning">

          <i className="fa fa-ticket"></i> Closed Tickets
        </Link>

        <Link
          className="btn ripple btn-primary"
          data-target="#modaldemo4"
          data-toggle="modal"
        >
          Departments
        </Link>


      </>
    )
  }
  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Tickets"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />

            <TotalTickets />

            <div className="row">
              <OpenTicketsTable />
              {/* <ClosedTicketsTable /> */}
            </div>
          </div>
        </div>
        <RightSideBar />
        <Footer />
        <Departments />
        <CreateSupportTicket />
      </div>
    );
  }
}

export default TicketsDashboard;

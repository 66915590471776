import React from 'react';
import ajax from '../../../ajax';
import functions from '../../../functions';
import DashCard from '../../Common/DashCard';


class TotalDailyWithdraws extends React.Component {
  

  state={
    userId:functions.sessionGuard(),
    accessInfo:false,
    balance:'...',
    total:'...'
   
  }


    async componentDidMount()
    {
      await this.getActualBalance();
      await this.getCummBalance();
      this.timerActual = setTimeout(this.func1=async()=> {
        await this.getActualBalance();
        this.timerActual = setTimeout(this.func1, 2000); // (*)
      }, 2000);

      this.timerCumm = setTimeout(this.func2=async()=> {
        await this.getCummBalance();
        this.timerCumm = setTimeout(this.func2, 2000); // (*)
      }, 2000);
    }

    componentWillUnmount()
    {
      clearTimeout(this.timerActual);
      clearTimeout(this.timerCumm);
    }

    getActualBalance=async()=>{
      const server_response=await ajax.getTotalWalletDailyWithdraws();
      if(server_response.status==="OK")
      {
        this.setState({
          balance:server_response.details.amount_c
        })
      }
    }


    getCummBalance=async()=>{
        const server_response=await ajax.getCummWalletWithdraws();
        if(server_response.status==="OK")
        {
          this.setState({
            total:server_response.details.amount_c
          })
        }
      }





  render(){
      return (
        <DashCard 
            title="Daily Withdraws"
            value={this.state.balance}
            caption={"Cumm. Ugx: " + this.state.total}
            icon={"fas fa-arrow-alt-circle-up  text-danger"}
            progressbar={"bg-danger"}
            />

      );
    
 
  }
}

export default TotalDailyWithdraws;
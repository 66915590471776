import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import TextInputWrap from '../../Components/Common/TextInputWrap'
import AlertError from '../../Components/Common/AlertError'
import AlertSuccess from '../../Components/Common/AlertSuccess'

class EditCustomer extends React.Component {

    state={
        userId:functions.sessionGuard(),
        customerInfo:false,
        customerId:'',
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        documents:[],
        documentId:false,
        new:true,
        alertmess:false,
        gender:''
        
      }

      componentDidMount()
      {
        if(!this.props.location.state)
		{
			this.props.history.push('/customer-records');
		}else{
			this.setState({
				customerId:this.props.location.state.customerId
			}, ()=>{
				this.getProfile()
			})


		
        }
      }


      setDocumentId=(id)=>{
          this.setState({
              documentId:id
          })
      }

      getProfile=async()=>{
        const server_response=await ajax.getCustomerProfile(this.state.customerId);
        if(server_response.status==="OK")
        {
           this.setState({
              customerInfo:server_response.details
           }, ()=>{
               const data=this.state.customerInfo.user;
               this.setState({
                   firstName:data.first_name,
                   lastName:data.last_name,
                   phone:data.phone,
                   email:data.email,
                   gender:data.gender

               })
           })

        }else{
          this.setState({
              customerInfo:"404"
          })
        }
    }

    onChangeFirstName = (event) => {
        this.setState({firstName: event.target.value});
      }
    onChangeLastName = (event) => {
    this.setState({lastName: event.target.value});
    }

    onChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    onChangePhone = (event) => {
        this.setState({phone: event.target.value});
    }
    

    onChangeDocument = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
            const newDocs=this.state.documents;
            const documentId=this.state.documentId;
            const newItem={file:  e.target.result, document_id:documentId}

            newDocs.map((item, key)=>{
                if(item.document_id==this.state.documentId)
                {
                    newDocs[newDocs.indexOf(item)]=newItem;
                    this.setState({
                        new:false
                    })
                }
            })

          
            this.state.new && newDocs.push(newItem);
             this.setState({
                documents:newDocs,
                new:true
             })
         }
         
       }

       onSave=async(e)=>{
            e.preventDefault();
            const {phone, email, firstName, lastName, documents}=this.state;
            if(phone.length>0 && firstName.length>0 && lastName.length)
            {
                const server_response=await ajax.updateCustomerProfile(
                    this.state.customerId,
                    firstName,
                    lastName,
                    phone,
                    email,
                    documents
                );
                console.log(server_response)
                if(server_response.status==="OK")
                {
                    this.setState({
                        alertmess:<AlertSuccess message={server_response.details[0]}/>
                    })
                }else{
                    this.setState({
                        alertmess:<AlertError message={server_response.details[0]}/>
                    })
                }

            }else{
                this.setState({
                    alertmess:<AlertError message="First Name and Last Name are required"/>
                })
            }
       }
     

    
    render(){
        const data=this.state.customerInfo;

          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Edit Customer"
                     previousPageTitle="Profile"
                     back={{
                        pathname:'/customer-profile',
                        state:{customerId:this.state.customerId}
                    }}
                  />
                  

                  <div className="row">
						<div className="col-lg-12 col-md-12">
							<div className="card custom-card">
								<div className="card-body">
									<div>
										<h6 className="card-title mb-1">Customer Profile Data</h6>
										<p className="text-muted card-sub-title">Details must match National ID data</p>
                                        {this.state.alertmess}
                                    </div>
									{data && <form method="post" onSubmit={this.onSave} className="row">
                                        
										<div className="col-md-6">
                                            <TextInputWrap 
                                                label="First Name"  
                                                onChange={()=>this.onChangeFirstName}
                                                value={this.state.firstName}
                                                />

                                            <TextInputWrap 
                                                label="Last Name"  
                                                onChange={()=>this.onChangeLastName}
                                                value={this.state.lastName}
                                                />

                                            
                                            <TextInputWrap 
                                                label="Mobile Phone (USSD Phone)"  
                                                onChange={()=>this.onChangePhone}
                                                value={this.state.phone}
                                                />


                                            <TextInputWrap 
                                                label="Email"  
                                                onChange={()=>this.onChangeEmail}
                                                value={this.state.email}
                                                />


                                           
										</div>
										<div className="col-md-6">
                                      
                                        {/**gender */}

                                        {this.state.customerInfo.user.documents.map((item, key)=>
                                         <TextInputWrap 
                                            onClick={()=>this.setDocumentId(item.document_id)}
                                            key={key}
                                            type="file"
                                            label={item.document_name}  
                                            onChange={()=>this.onChangeDocument }
                                            />
                                                            
                                        )}

                                       
											
										</div>
										<div className="col-md-12 ">
											<div className="form-group mb-0">
                                                <input className="btn ripple btn-primary" type="submit" value="Save Changes"/>
                                            </div>
										</div>
									</form>}
								</div>


                                {!this.state.customerInfo && <ActivityLoader/>}

							</div>
						</div>
					</div>


                
               
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default EditCustomer;
  
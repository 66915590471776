import React from 'react';


class InitialAvatar extends React.Component {
  

  render(){
    
      return (
        <div className="avatar avatar-xxl d-none d-sm-flex bg-dark">
            {this.props.value}
		</div>
      );
    
 
  }
}

export default InitialAvatar;
import React, { Component } from 'react';
import ajaxCashRound from '../../remote/ajaxCashRound';
import DashTile from '../Common/DashTile';

class CountPendInvitations extends Component {

    state={
        total:"..."
    }

    componentDidMount() {
        this.getRecord();
    }
    

    getRecord=async()=>{
        const server_response = await ajaxCashRound.countCashroundMembers();
        if(server_response.status=="OK")
        {
            this.setState({
                total:server_response.details.total_c
            })
        }
    }

    render() {
        return (
            <DashTile 
                title="Pending Invitation" 
                icon="fe fe-arrow-up" 
                value={this.state.total} 
                percentage="0.27%" 
                color="mb-0 text-success fs-13 ml-1" 
                caption="This month" />
        );
    }
}

export default CountPendInvitations;
import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../ajax';
import functions from '../../functions';
import MdlLoanStatement from '../MdlLoanStatement';
import ActivityLoader from './ActivityLoader';
import $ from 'jquery';
import Toast from './Toast';


class MdlSystemSearch extends React.Component {
  state={
      userId:functions.sessionGuard(),
      info:'',
      hint:'',
      userResults:false,
      loanResults:false,
      loadingLoans:false,
      loadingUsers:false,
      loading:false,
      loanStatement:false,
      accessInfo:functions.readPermissions(),
      toast:false
  }

  onSearch=(event)=>{
    event.preventDefault();
    this.setState({
        userResults:false,
        loanResults:false,
        loading:true
    }, ()=>{
       
        this.findUser();
        this.findLoan();
    })
    
  }

  onChangeHint = (event) => {
    this.setState({hint: event.target.value}, ()=>{
        this.findUser();
        this.findLoan();
    });
  }

  toggleLoader=()=>{
      this.setState({
          loading:(this.state.loadingLoans*this.state.loadingUsers)
      })
  }

  findUser=async()=>{
    if(this.state.hint.length>0 )
    {
        const server_response=await ajax.searchUser(this.state.hint, this.state.userId);
        if(server_response.status==="OK")
        {
            this.setState({
                userResults:server_response.details,
                loadingUsers:false,
                toast:<Toast 
						message={"Search hint \"" + this.state.hint  +"\" matched user"} 
                        onClose={()=>this.resetToast()}/>
            }, ()=>this.toggleLoader())
        }else if(server_response.status==="Fail"){

            this.setState({
                userResults:false,
                loadingUsers:false,
                toast:<Toast 
                message={server_response.details[0]} 
                type={3} 
                onClose={()=>this.resetToast()}/>
            }, ()=>this.toggleLoader())
        }

    }else{
        this.setState({
            userResults:false,
            loadingUsers:false
        }, ()=>this.toggleLoader())
    }
  }

  findLoan=async()=>{
    if(this.state.hint.length>0 && functions.findInObject(this.state.accessInfo, "LOAN0000-2"))
    {
        const server_response=await ajax.searchLoan(this.state.hint, this.state.userId);
        if(server_response.status==="OK")
        {
            this.setState({
                loanResults:server_response.details,
                loadingLoans:false
            }, ()=>this.toggleLoader())
        }else if(server_response.status==="Fail"){
            this.setState({
                loanResults:false,
                loadingLoans:false,
            }, ()=>this.toggleLoader())
        }

    }else{
        this.setState({
            loanResults:false,
            loadingLoans:false
        }, ()=>this.toggleLoader())
    }
  }



  onClickProfile=(user_id)=>{
    this.props.history.push({
      pathname:'/customer-profile',
      state:{customerId:user_id}
    })
  
  }
  

  onClickAgentProfile=(id)=>{
    this.props.history.push({
      pathname:'/agent-profile',
      state:{agentId:id}
    })
  
  }


  onClickLoan=(info)=>{
      this.setState({
          loanStatement:false
      }, ()=>{
          this.setState({
              loanStatement:<MdlLoanStatement loan={info} id="search_loan_preview"/>
          }, ()=>{
            $("#mdl-search").modal('hide');
            $("#search_loan_preview").modal('show');
          })
      })
  }


  
  resetToast=()=>{
    this.setState({
        toast:false
    })
  }

 
  render(){
    
      return (
        <>
        {this.state.loanStatement}
        <div className="modal " id="mdl-search">
        <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo">
                <div className="modal-header">
                    <h6 className="modal-title">QuickPost Search</h6>
                    {this.state.toast}

                </div>
                <div className="modal-body">
                            {this.state.info}

										
                    <div className="form-group">
                    <form method="post" onSubmit={this.onSearch}>
                    <input 
                        type="text" 
                        className="form-control input-lg" 
                        placeholder="Enter keywords...."
                        value={this.state.hint}
                        onFocus={functions.handleFocus}
                        onChange={this.onChangeHint}
                       />
                    </form>
                </div>


               <div className="table-responsive">
                        <table className="table mg-b-0">
                            <tbody>
                               
                               {this.state.userResults && this.state.userResults.map((item, key)=>
                                    <>
                                     {/***CUSTOMER RESULTS */}
                                    {functions.findInObject(this.state.accessInfo, "CUST000-2") && item.role_id==="3" &&
                                    <tr key={key}>
                                            <td className="bd-t-0">
                                                <div className="main-img-user">
                                                    <img alt="avatar" src={item.photo}/>
                                                </div>
                                                <small className="tx-11 tx-gray-700">{item.role_name +  "-" + item.user_id}</small><br></br>
                                            </td>
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0">About</h6>
                                                <small className="tx-14 tx-gray-900">Name: {item.first_name + ' ' + item.last_name}</small><br></br>
                                                <small className="tx-14 tx-gray-900">Phone: {item.phone}</small><br></br>
                                            
                                                {item.role_id==="2" && 
                                                    <Link to={
                                                            {
                                                                pathname:`/agent-profile/${item.user_id}`,
                                                                state:{agentId:item.user_id}
                                                            }
                                                        } 
                                                    className="tx-14 orange" replace>View Profile</Link>}
                                                
                                                {item.role_id==="3" && 
                                                    <Link to={
                                                        {
                                                            pathname:`/customer-profile/${item.user_id}`,
                                                            state:{customerId:item.user_id}
                                                        }
                                                    } 
                                                    className="tx-14 orange" replace>View Profile</Link>}
                                            </td>
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0 tx-gray-500">Transactions</h6>
                                                {item.role_id==="2" && 
                                                    <Link replace to={
                                                        {
                                                            pathname:'/business-float-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                        className="tx-14 orange">
                                                        Business Float<br/>
                                                    </Link>
                                                }
                                                {item.role_id==="2" && 
                                                <Link replace to={
                                                        {
                                                        pathname:'/float-transactions',
                                                        state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                className="tx-14 orange">Capital Float<br/></Link>}
                                                
                                                {item.role_id==="3" && <Link to={
                                                        {
                                                            pathname:'/wallet-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                className="tx-14 orange" replace={true}>Wallet<br/></Link>}
                                                    <Link to={
                                                        {
                                                            pathname:'/commission-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }   
                                                    className="tx-14 orange" replace>Commission</Link><br></br>

                                            </td> 
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0 tx-gray-500">Statements</h6>
                                                {item.role_id==="2" && 
                                                <Link to={
                                                        {
                                                            pathname:'/business-float-statement',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }      
                                                    className="tx-14 orange" replace>Business Float<br/></Link>
                                                }

                                                {item.role_id==="2" && <Link  to={
                                                        {
                                                            pathname:'/float-statement',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }    
                                                    className="tx-14 orange" replace>Capital Float<br/></Link>}

                                                {item.role_id==="3" && 
                                                    <Link  to={
                                                            {
                                                                pathname:'/wallet-statement',
                                                                state:{userId:item.user_id, userInfo:item}
                                                            }
                                                        }     
                                                        className="tx-14 orange" replace>Wallet<br/></Link>}

                                                
                                                <Link to={
                                                            {
                                                                pathname:'/commission-statement',
                                                                state:{userId:item.user_id, userInfo:item}
                                                            }
                                                        }      
                                                    className="tx-14 orange" replace>Commission</Link><br></br>

                                            </td>
                                            </tr>}





                                    {/***AGENTS RESULTS */}
                                    {functions.findInObject(this.state.accessInfo, "AGENT000-2") &&  item.role_id==="2" &&
                                    <tr key={key}>
                                            <td className="bd-t-0">
                                                <div className="main-img-user">
                                                    <img alt="avatar" src={item.photo}/>
                                                </div>
                                                <small className="tx-11 tx-gray-700">{item.role_name +  "-" + item.user_id}</small><br></br>
                                            </td>
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0">About</h6>
                                                <small className="tx-14 tx-gray-900">Name: {item.first_name + ' ' + item.last_name}</small><br></br>
                                                <small className="tx-14 tx-gray-900">Phone: {item.phone}</small><br></br>
                                            
                                                {item.role_id==="2" && 
                                                    <Link to={
                                                            {
                                                                pathname:`/agent-profile/${item.user_id}`,
                                                                state:{agentId:item.user_id}
                                                            }
                                                        } 
                                                    className="tx-14 orange" replace>View Profile</Link>}
                                                
                                                {item.role_id==="3" && 
                                                    <Link to={
                                                        {
                                                            pathname:`/customer-profile/${item.user_id}`,
                                                            state:{customerId:item.user_id}
                                                        }
                                                    } 
                                                    className="tx-14 orange" replace>View Profile</Link>}
                                            </td>
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0 tx-gray-500">Transactions</h6>
                                                {item.role_id==="2" && 
                                                    <Link replace to={
                                                        {
                                                            pathname:'/business-float-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                        className="tx-14 orange">
                                                        Business Float<br/>
                                                    </Link>
                                                }
                                                {item.role_id==="2" && 
                                                <Link replace to={
                                                        {
                                                        pathname:'/float-transactions',
                                                        state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                className="tx-14 orange">Capital Float<br/></Link>}
                                                
                                                {item.role_id==="3" && <Link to={
                                                        {
                                                            pathname:'/wallet-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }  
                                                className="tx-14 orange" replace={true}>Wallet<br/></Link>}
                                                    <Link to={
                                                        {
                                                            pathname:'/commission-transactions',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }   
                                                    className="tx-14 orange" replace>Commission</Link><br></br>

                                            </td> 
                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0 tx-gray-500">Statements</h6>
                                                {item.role_id==="2" && 
                                                <Link to={
                                                        {
                                                            pathname:'/business-float-statement',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }      
                                                    className="tx-14 orange" replace>Business Float<br/></Link>
                                                }

                                                {item.role_id==="2" && <Link  to={
                                                        {
                                                            pathname:'/float-statement',
                                                            state:{userId:item.user_id, userInfo:item}
                                                        }
                                                    }    
                                                    className="tx-14 orange" replace>Capital Float<br/></Link>}

                                                {item.role_id==="3" && 
                                                    <Link  to={
                                                            {
                                                                pathname:'/wallet-statement',
                                                                state:{userId:item.user_id, userInfo:item}
                                                            }
                                                        }     
                                                        className="tx-14 orange" replace>Wallet<br/></Link>}

                                                
                                                <Link to={
                                                            {
                                                                pathname:'/commission-statement',
                                                                state:{userId:item.user_id, userInfo:item}
                                                            }
                                                        }      
                                                    className="tx-14 orange" replace>Commission</Link><br></br>

                                            </td>
                                            </tr>}

                                            </>
                                            )}
                                        
                                         {/***LOAN RESULTS */}
                                        {this.state.loanResults && this.state.loanResults.map((item, key)=>
                                        <tr key={key}> 
                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0 tx-gray-500">{item.loan_code}</h6>
                                                    <small className="tx-11 tx-gray-700">
                                                        Total Loan : {item.total_loan.amount_c}
                                                    </small>
                                                    <br/>
                                                    <small className="tx-11 tx-gray-700">
                                                        Loan Balance : {item.loan_balance.amount_c}
                                                    </small>
                                                    <br/>
                                                    <small className="tx-11 tx-gray-700">
                                                        {item.timer_in_days}d
                                                    </small>
                                                    <br/>
                                                </td>
                                                <td className="bd-t-0">
                                                <h6 className="mg-b-0">Loan Info</h6>
                                                <small className="tx-14 tx-gray-900">Since: {item.loan_date.long_date}</small><br></br>
                                                <small className="tx-14 tx-gray-900">Exp: {item.deadline.long_date}</small><br></br>
                                            
                                            
                                                    <Link to="#"
                                                    onClick={()=>this.onClickLoan(item)}
                                                    className="tx-14 orange" 
                                                    replace={false}>
                                                        View Statement
                                                    </Link>
                                                
                                            </td>


                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0">Customer Info</h6>
                                                <small className="tx-14 tx-gray-900">Name: {item.customer_info.first_name + ' ' + item.customer_info.last_name}</small><br></br>
                                                <small className="tx-14 tx-gray-900">Phone: {item.customer_info.phone}</small><br></br>
                                            
                                                <Link to={
                                                    {
                                                        pathname:`/customer-profile/${item.customer_info.user_id}`,
                                                        state:{customerId:item.customer_info.user_id}
                                                    }
                                                } 
                                                className="tx-14 orange" replace>View Profile</Link>
                                            </td>
                                        

                                            <td className="bd-t-0">
                                                <h6 className="mg-b-0">Agent Info</h6>
                                                <small className="tx-14 tx-gray-900">Name: {item.agent_info.first_name + ' ' + item.customer_info.last_name}</small><br></br>
                                                <small className="tx-14 tx-gray-900">Phone: {item.agent_info.phone}</small><br></br>
                                            
                                                <Link to={
                                                        {
                                                            pathname:`/agent-profile/${item.agent_info.user_id}`,
                                                            state:{agentId:item.agent_info.user_id}
                                                        }
                                                    } 
                                                className="tx-14 orange" replace>View Profile</Link>
                                            
                                            </td>

                                        

                                        </tr>
                                        
                                        
                                        
                                        )}



                            </tbody>
                        </table>
                        {(this.state.loadingLoans || this.state.loadingUsers) && <ActivityLoader/>}
                    </div>
                
                </div>
              
            </div>
        </div>
    </div>
    </>
  )}
      
}

export default MdlSystemSearch;